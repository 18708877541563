import React from 'react';
import { FieldMain } from '../../../components/Field/Fields';
import { Material } from '../../../entities/material';
import { Tag } from '../../../components/Tag/Tag';

interface MaterialListItemProps {
    material: Material
}
const MaterialListItem = (props: MaterialListItemProps) => {
    return (
        <FieldMain className="detail-list-item" >
            <div className='detail-content-info'>
                <h1>{props.material.assortment?.name}</h1>
                <h3>{props.material.assortment?.gost}</h3>
                <hr></hr>
                <h3>Материал: {props.material.material_type?.name}</h3>
                <h3>Размер: {props.material.assortment_size?.size}</h3>
                <h3>Количество: {props.material.amount}</h3>
                <h3>Поставщик: {props.material.supplier}</h3>
                <div className='detail-list-items-status'>
                    <h3>Статус: </h3>
                    <Tag>{props.material.status}</Tag>
                </div>
            </div>
        </FieldMain>
    )}

export default MaterialListItem;