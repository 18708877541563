// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/fonts/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/fonts/Roboto-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Roboto Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  font-family: 'Roboto Black';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

h1{
  font-size: 24px !important;
}

h2{
  font-size: 20px !important
}

h3{
  font-size: 16px !important
}

h4{
  font-size: 14px !important
}

h5{
  font-size: 12px !important
}

h6{
  font-size: 8px !important
}

p{
  font-size: 14px !important
}

label{
  
}`, "",{"version":3,"sources":["webpack://./src/app/App.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,4CAA+C;AACjD;AACA;EACE,2BAA2B;EAC3B,4CAA6C;AAC/C;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;;AAEA","sourcesContent":["@font-face {\n  font-family: 'Roboto Regular';\n  src: url(../../public/fonts/Roboto-Regular.ttf);\n}\n@font-face {\n  font-family: 'Roboto Black';\n  src: url(../../public/fonts/Roboto-Black.ttf);\n}\n\nh1{\n  font-size: 24px !important;\n}\n\nh2{\n  font-size: 20px !important\n}\n\nh3{\n  font-size: 16px !important\n}\n\nh4{\n  font-size: 14px !important\n}\n\nh5{\n  font-size: 12px !important\n}\n\nh6{\n  font-size: 8px !important\n}\n\np{\n  font-size: 14px !important\n}\n\nlabel{\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
