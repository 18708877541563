import { Assortment } from "./assortment";
import { AssortmentSize } from "./assortment_size";
import { MaterialType } from "./material_type";
import { Task } from "./task";

export interface Material{
    ID: number;
    number: string | null
    mark: string | null
    assortment: Assortment | null
    assortment_size: AssortmentSize | null
    amount: number | null
    supplier: string | null
    acceptance_date: string | null
    storage_address: string | null
    storage: Storage;
    status: MaterialStatus;
    check_date: string | null,
    check_number: string | null,
    material_type_id: number | null,
    material_type: MaterialType | null,
    task_id: number | null,
    task: Task | null,
}

export enum MaterialStatus {
    Ordered = "Заказан",
    Delivered = "Доставлен",
    InStock = "На складе", 
} 