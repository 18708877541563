// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-type {
    margin-top: 40px;
    margin-left: 80px;
    margin-right: 80px;
}

.detail-type-header-buttons {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    margin-bottom: 20px;
}

.detail-type-title {
    display: flex;
    justify-content: space-between;
}

.detail-type-title h1 {
    color: var(--primary-text-color);    
}

.detail-type-title h2 {
    color: var(--base-grey-color)
}

.detail-type-title p {
    color: var(--primary-text-color)
}

.detail-type-storage {
    width: 50%;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 10px; 
    display: flex;
}

.detail-type-storage-image {
    margin-right: 20px; 
}

.detail-type-operations {
    margin-top: 20px;
}

.detail-type-operations-list {
    width: 66%;
    display: flex;
    flex-wrap: wrap;
}

.detail-type-operations-list-card {
    padding: 10px;
    width: 32%;
    margin-right: 10px;
    margin-bottom: 10px;
}


.detail-type-operations-list-card h3 {
    color: var(--primary-text-color);
}

.detail-type-operations-list-card h6 {
    color: var(--base-grey-color);
}

.detail-type-draft {
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/DetailTypePage/DetailTypePage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;IACpB,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,kBAAkB;IAClB,mBAAmB;AACvB;;;AAGA;IACI,gCAAgC;AACpC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".detail-type {\n    margin-top: 40px;\n    margin-left: 80px;\n    margin-right: 80px;\n}\n\n.detail-type-header-buttons {\n    display: flex;\n    justify-content: space-between; \n    align-items: center; \n    margin-bottom: 20px;\n}\n\n.detail-type-title {\n    display: flex;\n    justify-content: space-between;\n}\n\n.detail-type-title h1 {\n    color: var(--primary-text-color);    \n}\n\n.detail-type-title h2 {\n    color: var(--base-grey-color)\n}\n\n.detail-type-title p {\n    color: var(--primary-text-color)\n}\n\n.detail-type-storage {\n    width: 50%;\n    padding-left: 20px;\n    padding-top: 20px;\n    padding-bottom: 10px; \n    display: flex;\n}\n\n.detail-type-storage-image {\n    margin-right: 20px; \n}\n\n.detail-type-operations {\n    margin-top: 20px;\n}\n\n.detail-type-operations-list {\n    width: 66%;\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.detail-type-operations-list-card {\n    padding: 10px;\n    width: 32%;\n    margin-right: 10px;\n    margin-bottom: 10px;\n}\n\n\n.detail-type-operations-list-card h3 {\n    color: var(--primary-text-color);\n}\n\n.detail-type-operations-list-card h6 {\n    color: var(--base-grey-color);\n}\n\n.detail-type-draft {\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
