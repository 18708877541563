import React from 'react';
import {Task, TaskStatus, TaskType} from "../../../entities/task";
import './ChildTasksInfo.css'
interface ChildTasksInfoProps {
    childTasks: Task[]
}

const ChildTasksInfo = (props: ChildTasksInfoProps) => {
    let isStoring = false;
    let counter = 0
    
    return (
        <div className='tasks-timeline'>
        {props.childTasks.map(task => {
            if (task.type == TaskType.Operation) {
                counter++
                return (
                    <div>
                        <div className={`tasks-timeline-item ${(task.status == TaskStatus.TaskStatusFinished) ? 'tasks-timeline-item-ready':''}`}>
                            <h3>{task.name.split(":")[1]}</h3>
                        </div>
                        <h4>Этап {counter}</h4>
                    </div>
            )}})}
        {props.childTasks.map(task => {
            if (task.type == TaskType.QualityControl) {
                counter++
                return (
                    <div>
                        <div className={`tasks-timeline-item ${(task.status === TaskStatus.TaskStatusFinished) ? 'tasks-timeline-item-ready':''}`}>
                            <h3>Контроль качества</h3>
                        </div>
                        <h4>Этап {counter}</h4>
                    </div>
            )}})}
        {props.childTasks.map(task => {
            if (task.type == TaskType.Storing) {
                counter++
                isStoring = true
                return (
                    <div>
                        <div className={`tasks-timeline-item ${(task.status) ? 'tasks-timeline-item-ready':''}`}>
                            <h3>Cкладирование</h3>
                        </div>
                        <h4>Этап {counter}</h4>
                    </div>
            )}
            
            })}
        {(!isStoring) ?
            <div>
                <div className='tasks-timeline-item'>
                    <h3>Cкладирование</h3>
                </div> 
                <h4>Этап {++counter}</h4>
            </div>: <></> 
        }
        </div>
    )
}

export default ChildTasksInfo;