import React, {useEffect, useState} from "react";
import {ShortStorage} from "../entities/storage";
import {instance} from "../api/config";
import StorageList from "../components/StorageList/StorageList";
import {useNavigate} from "react-router-dom";

const StorageListPage = () => {

    const navigate = useNavigate();

    const [storageList, setStorageList] = useState<ShortStorage[]>([]);

    useEffect(() => {
        const getStorages = async () => {
            const resp = await instance.get("/api/storage/all")
            setStorageList(resp.data)
        }
        getStorages()
    }, []);

    const handleNewStorage = async (e: React.FormEvent) => {
        e.preventDefault()
        navigate("/storages/new")
    }


    return (
        <div className="m-2">
            <button type="button" className="mb-2 btn btn-primary new-department-button" onClick={handleNewStorage}>Новый
                склад
            </button>
            <StorageList storages={storageList}/>
        </div>
    )
}

export default StorageListPage;