// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-task-button {
    background-color: #F65727;
    border-color: #F65727;
    color: #212529
}

.add-task-button:hover {
    background-color: #F65727;
    color: #1d2124 !important;
    border: solid 1px #F65727 !important;
    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727 !important;
}

.add-task-button:active {
    background-color: #212529;
    color: #F65727
}`, "",{"version":3,"sources":["webpack://./src/components/NewTaskForm/NewTaskForm.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,qBAAqB;IACrB;AACJ;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,oCAAoC;IACpC,8DAA8D;AAClE;;AAEA;IACI,yBAAyB;IACzB;AACJ","sourcesContent":[".add-task-button {\n    background-color: #F65727;\n    border-color: #F65727;\n    color: #212529\n}\n\n.add-task-button:hover {\n    background-color: #F65727;\n    color: #1d2124 !important;\n    border: solid 1px #F65727 !important;\n    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727 !important;\n}\n\n.add-task-button:active {\n    background-color: #212529;\n    color: #F65727\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
