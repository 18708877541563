// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.department {
    border: 1px solid ghostwhite;
    border-radius: 3px;
    margin: 10px;
}

.department-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #353535;
}

.new-project-button {
    margin: 5px;
}

.department-name-label {
    margin-left: 10px;
    font-size: x-large;
    color: white;
}

.department-list {
    margin-bottom: 5px;
}

hr {
    margin-top: 0;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid ghostwhite;
}

.new-project-button {
    background-color: #F65727;
    color: #212529;
    border: solid 1px #F65727 !important;

}
.new-project-button:hover {
    background-color: #F65727;
    color: #1d2124 !important;
    border: solid 1px #F65727 !important;
    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727 !important;
}

.new-project-button:active {
    background-color: #212529;
    color: #F65727;
}`, "",{"version":3,"sources":["webpack://./src/components/DepartmentsList/ProjectsListItem.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,oCAAoC;;AAExC;AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,oCAAoC;IACpC,8DAA8D;AAClE;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".department {\n    border: 1px solid ghostwhite;\n    border-radius: 3px;\n    margin: 10px;\n}\n\n.department-header {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    background-color: #353535;\n}\n\n.new-project-button {\n    margin: 5px;\n}\n\n.department-name-label {\n    margin-left: 10px;\n    font-size: x-large;\n    color: white;\n}\n\n.department-list {\n    margin-bottom: 5px;\n}\n\nhr {\n    margin-top: 0;\n    margin-bottom: 5px;\n    border: 0;\n    border-top: 1px solid ghostwhite;\n}\n\n.new-project-button {\n    background-color: #F65727;\n    color: #212529;\n    border: solid 1px #F65727 !important;\n\n}\n.new-project-button:hover {\n    background-color: #F65727;\n    color: #1d2124 !important;\n    border: solid 1px #F65727 !important;\n    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727 !important;\n}\n\n.new-project-button:active {\n    background-color: #212529;\n    color: #F65727;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
