export interface Operation {
    ID: number | null,
    type: string,
    address: string,
    number: string,
    status: boolean
}

export enum OperationType {
    Blanking = "Заготовительная",
    Turning       = "Токарная",
    Milling       = "Фрезерная",
    Bending       = "Гибочная",
    Welding       = "Сварочная",
    Drilling      = "Сверление",
    Benching      = "Слесарная",
    Requirements  = "Требования к детали",
}

