import React, { useState } from 'react';
import { Input } from '../../../components/Input/Input';
import { Button } from '../../../components/Button/Button';
import { instance } from '../../../api/config';
import './NewAssortmentForm.css'
import toast from 'react-hot-toast';

interface NewAssortmentFormProps {
    assortment_type_id: number,
    setAAdd: (v: boolean) => void
}
const NewAssortmentForm = (props: NewAssortmentFormProps) => {

    const [name, setName] = useState('');
    const [gost, setGost] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const resp = await instance.post('/api/assortments', {
            "assortment_type_id": props.assortment_type_id,
            "name": name,
            "gost": gost,
        })
        props.setAAdd(false);
        toast("Сортамент успешно добавлен")
    }
    return (
        <div>
            <div className='assortment-form-input assortment-form-input-first'>
                <Input
                    label="Название"
                    placeholder="Введите название сортамента"
                    onChange={(e) => setName(e.target.value)}
                />
                </div>
            <div className='assortment-form-input'>
                <Input
                    label="ГОСТ"
                    placeholder="Введите ГОСТ"
                    onChange={(e) => setGost(e.target.value)}
                />
            </div>
            <Button
                buttonType='white-shadowed'
                onClick={handleSubmit}
            >
                Сохранить
            </Button>
        </div>
    );
}

export default NewAssortmentForm;