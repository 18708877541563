import React, { useState } from 'react';
import { User } from '../../../entities/user';
import { FieldMain } from '../../../components/Field/Fields';
import { Username } from '../../../components/Username/Username';
import { Roles } from '../../../schemas/Roles';
import { Tag } from '../../../components/Tag/Tag';
import { UseIcons } from '../../../hooks/UseIcons';
import RegistrationForm from '../../Forms/RegistrationForm/RegistrationForm';
import { Dialog } from 'primereact/dialog';

interface UserListItemProps {
    user: User;
}

const UsersListItem = (props: UserListItemProps) => {
    const [isChange, setIsChange] = useState(false)
    const icons = UseIcons()
    return (
        <>
            <FieldMain className='detail-list-item' style={{ display: "flex", justifyContent: "space-between", alignContent: "top"}}>
                <div>
                    <h5>{props.user.username}</h5>
                    <h1>{Username(props.user)}</h1>
                    <h3>{Roles[props.user.role || 0]}</h3>
                    {(props.user.current_work_place_id) ?
                    <Tag>{props.user.workplaces?.filter(w => w.ID == props.user.current_work_place_id)[0].address || ""}</Tag> : <></> }
                </div>
                <img onClick={() => setIsChange(true)} src={icons.GEAR} width="15" height="15"/>
            </FieldMain>
            {(isChange) ?
            <Dialog header="Изменение сотрудника" visible={true} className="admin-page-form" onHide={() => {
                    if (!setIsChange) return;
                        setIsChange(false); }}>
                    <RegistrationForm user={props.user} setIsAdd={setIsChange}/>
            </Dialog >
            : <></>}
        </> 
    )
}

export default UsersListItem;