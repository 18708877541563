import { useTheme } from "./UseTheme";

export const UseIcons = () => {
    let theme = "light"
    const icons = {
        "ACCEPT_MEDIA_FILE_ICON": `/icons/${theme}/ACCEPT_MEDIA_FILE_ICON.svg`,
        "ADD_DOCUMENT_ICON": `/icons/${theme}/ADD_DOCUMENT_ICON.svg`,
        "ADD_FOLDER_ICON": `/icons/${theme}/ADD_FOLDER_ICON.svg`,
        "APPLICATION_ICON": `/icons/${theme}/APPLICATION_ICON.svg`,
        "APPLICATIONS_2_ICON": `/icons/${theme}/APPLICATIONS_2_ICON.svg`,
        "APPS_ICON": `/icons/${theme}/APPS_ICON.svg`,
        "ASK_A_QUESTION_ICON": `/icons/${theme}/ASK_A_QUESTION_ICON.svg`,
        "BOOKMARKS_ICON": `/icons/${theme}/BOOKMARKS_ICON.svg`,
        "BRAIN_ICON": `/icons/${theme}/BRAIN_ICON.svg`,
        "CALENDAR_ICON": `/icons/${theme}/CALENDAR_ICON.svg`,
        "CALLING_ICON": `/icons/${theme}/CALLING_ICON.svg`,
        "CANCELLATION_1_ICON": `/icons/${theme}/CANCELLATION_1_ICON.svg`,
        "CHEK_ICON": `/icons/${theme}/CHEK_ICON.svg`,
        "CHEK_LINK_ICON": `/icons/${theme}/CHEK_LINK_ICON.svg`,
        "CLIP_ICON": `/icons/${theme}/CLIP_ICON.svg`,
        "CLUE_ICON": `/icons/${theme}/CLUE_ICON.svg`,
        "COLOR_ICON": `/icons/${theme}/COLOR_ICON.svg`,
        "DELIVERY_ICON": `/icons/${theme}/DELIVERY_ICON.svg`,
        "DIAGONAL_1_ICON": `/icons/${theme}/DIAGONAL_1_ICON.svg`,
        "DIAGONAL_2_ICON": `/icons/${theme}/DIAGONAL_2_ICON.svg`,
        "DOCUMENT_ICON": `/icons/${theme}/DOCUMENT_ICON.svg`,
        "DOWN_1_ICON": `/icons/${theme}/DOWN_1_ICON.svg`,
        "DOWN_2_ICON": `/icons/${theme}/DOWN_2_ICON.svg`,
        "DOWN_3_ICON": `/icons/${theme}/DOWN_3_ICON.svg`,
        "DOWNLOAD_ICON": `/icons/${theme}/DOWNLOAD_ICON.svg`,
        "EDIT_DOCUMENT_ICON": `/icons/${theme}/EDIT_DOCUMENT_ICON.svg`,
        "EMPLOYEE_ICON": `/icons/${theme}/EMPLOYEE_ICON.svg`,
        "EMPLOYEE_QUESTION_ICON": `/icons/${theme}/EMPLOYEE_QUESTION_ICON.svg`,
        "ENTRANCE_ICON": `/icons/${theme}/ENTRANCE_ICON.svg`,
        "ENTRY_ICON": `/icons/${theme}/ENTRY_ICON.svg`,
        "EXIT_ICON": `/icons/${theme}/EXIT_ICON.svg`,
        "FONT_ICON": `/icons/${theme}/FONT_ICON.svg`,
        "FOOTNOTE_ICON": `/icons/${theme}/FOOTNOTE_ICON.svg`,
        "INFO_ICON": `/icons/${theme}/INFO_ICON.svg`,
        "INFO_SECOND_ICON": `/icons/${theme}/INFO_SECOND_ICON.svg`,
        "INTERFACE_ICON": `/icons/${theme}/INTERFACE_ICON.svg`,
        "INTERNET_ICON": `/icons/${theme}/INTERNET_ICON.svg`,
        "LEFT_1_ICON": `/icons/${theme}/LEFT_1_ICON.svg`,
        "LEFT_2_ICON": `/icons/${theme}/LEFT_2_ICON.svg`,
        "LEFT_3_ICON": `/icons/${theme}/LEFT_3_ICON.svg`,
        "LOCATION_ICON": `/icons/${theme}/LOCATION_ICON.svg`,
        "LOGO_ICON": `/icons/${theme}/LOGO_ICON.svg`,
        "LOSS_OF_CONNECTION_ICON": `/icons/${theme}/LOSS_OF_CONNECTION_ICON.svg`,
        "MAIL_ICON": `/icons/${theme}/MAIL_ICON.svg`,
        "MENU_ICON": `/icons/${theme}/MENU_ICON.svg`,
        "MESSAGE_ICON": `/icons/${theme}/MESSAGE_ICON.svg`,
        "MOON_ICON": `/icons/${theme}/MOON_ICON.svg`,
        "MULTIMEDIA_ICON": `/icons/${theme}/MULTIMEDIA_ICON.svg`,
        "NEXT_ICON": `/icons/${theme}/NEXT_ICON.svg`,
        "NOTIFICATION_ICON": `/icons/${theme}/NOTIFICATION_ICON.svg`,
        "OPEN_FOLDER_ICON": `/icons/${theme}/OPEN_FOLDER_ICON.svg`,
        "OPEN_SECTION_ICON": `/icons/${theme}/OPEN_SECTION_ICON.svg`,
        "PEN_ICON": `/icons/${theme}/PEN_ICON.svg`,
        "PERSON_ICON": `/icons/${theme}/PERSON_ICON.svg`,
        "PERSONAL_ACCOUNT_ICON": `/icons/${theme}/PERSONAL_ACCOUNT_ICON.svg`,
        "PIN_ICON": `/icons/${theme}/PIN_ICON.svg`,
        "PIN_MASSAGE_ICON": `/icons/${theme}/PIN_MASSAGE_ICON.svg`,
        "RIGHT_1_ICON": `/icons/${theme}/RIGHT_1_ICON.svg`,
        "RIGHT_2_ICON": `/icons/${theme}/RIGHT_2_ICON.svg`,
        "RIGHT_3_ICON": `/icons/${theme}/RIGHT_3_ICON.svg`,
        "SCALE_ICON": `/icons/${theme}/SCALE_ICON.svg`,
        "SEARCH_INSIDE_A_FILE_ICON": `/icons/${theme}/SEARCH_INSIDE_A_FILE_ICON.svg`,
        "SEARCH_MAIN_ICON": `/icons/${theme}/SEARCH_MAIN_ICON.svg`,
        "SEARCH_WITHIN_A_SUB-SECTION_ICON": `/icons/${theme}/SEARCH_WITHIN_A_SUB-SECTION_ICON.svg`,
        "SEND_2_ICON": `/icons/${theme}/SEND_2_ICON.svg`,
        "SEND_ICON": `/icons/${theme}/SEND_ICON.svg`,
        "SEND_MEDIA_FILE_ICON": `/icons/${theme}/SEND_MEDIA_FILE_ICON.svg`,
        "SHARE_YOUR_LOCATION_ICON": `/icons/${theme}/SHARE_YOUR_LOCATION_ICON.svg`,
        "SIZE_ICON": `/icons/${theme}/SIZE_ICON.svg`,
        "SPANNER_ICON": `/icons/${theme}/SPANNER_ICON.svg`,
        "SRTTINGS_ICON": `/icons/${theme}/SRTTINGS_ICON.svg`,
        "STOP_ICON": `/icons/${theme}/STOP_ICON.svg`,
        "SUN_FOLDER_ICON": `/icons/${theme}/SUN_FOLDER_ICON.svg`,
        "SUSPICIOUS_FILE_WARNING_ICON": `/icons/${theme}/SUSPICIOUS_FILE_WARNING_ICON.svg`,
        "TAKE_PHOTO_ICON": `/icons/${theme}/TAKE_PHOTO_ICON.svg`,
        "TECHNICAL_WORKS_ICON": `/icons/${theme}/TECHNICAL_WORKS_ICON.svg`,
        "THE_EMPLOYEE_DOESN'T_HAVE_TIME_ICON": `/icons/${theme}/THE_EMPLOYEE_DOESN'T_HAVE_TIME_ICON.svg`,
        "THE_INTERLOCUTOR_IS_TYPING_ICON": `/icons/${theme}/THE_INTERLOCUTOR_IS_TYPING_ICON.svg`,
        "THE_TASK_WILL_BE_CLOSED_ON_TIME_ICON": `/icons/${theme}/THE_TASK_WILL_BE_CLOSED_ON_TIME_ICON.svg`,
        "THREE_POINTS_ICON": `/icons/${theme}/THREE_POINTS_ICON.svg`,
        "TIMER_ICON": `/icons/${theme}/TIMER_ICON.svg`,
        "TRASH_ICON": `/icons/${theme}/TRASH_ICON.svg`,
        "UP_1_ICON": `/icons/${theme}/UP_1_ICON.svg`,
        "UP_2_ICON": `/icons/${theme}/UP_2_ICON.svg`,
        "UP_3_ICON": `/icons/${theme}/UP_3_ICON.svg`,
        "WARNING_ABOUT_PARTNER_VISIT_ICON": `/icons/${theme}/WARNING_ABOUT_PARTNER_VISIT_ICON.svg`,
        "WARNING_ICON": `/icons/${theme}/WARNING_ICON.svg`,
        "WI-FI_ICON": `/icons/${theme}/WI-FI_ICON.svg`,
        "ADD": `/icons/${theme}/ADD.svg`,
        "CANCEL": `/icons/${theme}/CANCEL.svg`,
        "REMOVE": `/icons/${theme}/REMOVE.svg`,
        "GEAR": `/icons/${theme}/GEAR.svg`,
        "GEAR_ACTIVE": `/icons/${theme}/GEAR_ACTIVE.svg`,
        "SAVE": `/icons/${theme}/SAVE.svg`,
        "DELETE": `/icons/${theme}/DELETE.svg`,
        "USER": `/icons/${theme}/USER.svg`,
    }
    return icons;
}