import { Assortment } from "./assortment"

export interface AssortmentType {
    ID: number
    amount_type: string
    name: string
    assortments: Assortment[]
}

export const AmountTypes = [
     "м",
	 "шт",
	 "мм",
	 "м2",
]