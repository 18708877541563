import React, {useEffect, useState} from 'react';

import '../css/Tasks.css'
import {instance} from "../api/config";
import {FullTaskResponse, Task} from "../entities/task";
import {useNavigate, useParams} from "react-router-dom";
import TaskComponent from "../components/Task/Task";

const TasksPage = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [ task, setTask ] = useState<FullTaskResponse>()

    useEffect(() => {
        const getTask = async () => {
            const resp = await instance.get("/api/tasks", {
                params: {"ID": id}
            })
            if (resp.data.type) {
                resp.data.task = resp.data
            }
            setTask(resp.data)
        }
        getTask()
    }, []);
    if (task === undefined) {
        return (
            <> Ошибка, обратитесь к администратору</>
        )
    }
    return (
        <>
            <TaskComponent task={task}></TaskComponent>
        </>
    );
};

export default TasksPage