// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail {
    margin-bottom: 5px;
    background-color: #F65727;
    border-radius: 5px;
    color: #212529;
}

.detail-in-progress {
    background-color: #212529;
    border-radius: 5px;
    border: white 1px solid;
    margin: 10px;
    color: white;
}
.detail-name {
    font-weight: 700;
    margin: 10px;
    text-decoration: underline;
}
.detail-info {
    display: inline-block;
    float: right;
    margin-top: 5px;
}

.detail-label {
    font-weight: 500;
    margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/DetailCountList/DetailCountListItem/DetailCountListItem.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,0BAA0B;AAC9B;AACA;IACI,qBAAqB;IACrB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".detail {\n    margin-bottom: 5px;\n    background-color: #F65727;\n    border-radius: 5px;\n    color: #212529;\n}\n\n.detail-in-progress {\n    background-color: #212529;\n    border-radius: 5px;\n    border: white 1px solid;\n    margin: 10px;\n    color: white;\n}\n.detail-name {\n    font-weight: 700;\n    margin: 10px;\n    text-decoration: underline;\n}\n.detail-info {\n    display: inline-block;\n    float: right;\n    margin-top: 5px;\n}\n\n.detail-label {\n    font-weight: 500;\n    margin-right: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
