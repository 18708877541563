import React from 'react';
import TaskComponent from './TaskListItem';
import '../css/Tasks.css'
import {getTasksByProject, Task} from '../entities/task'
import {useNavigate} from "react-router-dom";
import TaskListItem from "./TaskListItem";
import {useAuth} from "../context/AuthProvider";
interface TasksListProps {
    tasks: Task[] | null
}

const TasksList: React.FC<TasksListProps> = (props) => {
    const { user } = useAuth()
    const navigate = useNavigate();
    const handleNewTask = async () => {
        navigate("/tasks/new")
    }

    if (!props.tasks || props.tasks.length === 0) {
        return (
            <div className="m-3">
                { (user?.role == 1)?
                <button
                    className='nav-link active task-box'
                    data-bs-toggle="tab"
                    data-bs-target={`#Новая-задача-tab-pane`}
                    type="button"
                    role="tab"
                    aria-controls={`Новая-задача-tab-pane`}
                    aria-selected='true'
                    onClick={handleNewTask}
                >
                    Новая-задача
                </button> : <></>
                }
                <div className="tasks"><br></br><h1 style={{color: "#F65727"}}> На данный момент задач
                    нет. </h1></div>
            </div>
        )
    }

    const tasks = getTasksByProject(props.tasks)
    return (
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab">
                <div className="tasks-body">
                    { (user?.role == 1)?
                    <button
                        className='nav-link mb-3 active task-box'
                        data-bs-toggle="tab"
                        data-bs-target={`#Новая-задача-tab-pane`}
                        type="button"
                        role="tab"
                        aria-controls={`Новая-задача-tab-pane`}
                        aria-selected='true'
                        onClick={handleNewTask}
                    >
                        Новая-задача
                    </button> : <></>
                    }
                    <div className="btn-group btn-group-sm">
                        <button className="day-button btn btn-secondary">День</button>
                        <button className="week-button btn btn-secondary">Неделя</button>
                        <button className="month-button btn btn-secondary">Месяц</button>
                    </div>
                    {Object.entries(tasks).map(([projectName, tasks]) => (
                        <div className="task-box" key={projectName} >
                            <label className="header">{projectName}</label>
                            {tasks.map(task => {
                                if (task.visible) {
                                    return (
                                    <TaskListItem
                                        ID={task.ID}
                                        key={task.ID}
                                        type={task.type}
                                        name={task.name}
                                        // detail={task.detail}
                                        // quantity={task.quantity}
                                        date={task.finish_date}
                                        progress={task.progress}
                                    />)
                                    }
                                return <></>
                                })}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TasksList;