import React, { useEffect, useState } from 'react'
import {ShortStorage} from "../../entities/storage";
import StorageListItem from "./StorageListItem/StorageListItem";
import {Box, MantineProvider} from "@mantine/core";
import {useNavigate} from "react-router-dom";

interface StorageListProps {
    storages: ShortStorage[]
}

const StorageList = (props: StorageListProps) => {

    const navigate = useNavigate();

    return (
        <MantineProvider>
            <Box>
            {props.storages.map((storage: ShortStorage) => (
                <div onClick={(e) => {
                    e.preventDefault();
                    navigate("/storages/" + storage.ID)
                }}>
                    <StorageListItem storage={storage} />
                </div>
            ))}
            </Box>
        </MantineProvider>
    )
}

export default StorageList