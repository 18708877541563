import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { DetailType } from '../../../entities/detail_type';
import { FieldMain } from '../../../components/Field/Fields';
import './DetailTypeListItem.css'

interface DetailProps {
    detailType: DetailType
}

const DetailTypeListItem: React.FC<DetailProps> = (props: DetailProps) => {
    const navigate = useNavigate()

    return (
            <FieldMain className='detail-type-list-item' >
                <div className='detail-type-list-item-content' onClick={e => {
            e.preventDefault();
            navigate(`/details/${props.detailType.ID}`);
        }}>
                    <div className='detail-type-list-item-info' >
                        <h1>{props.detailType.name}</h1>
                        <hr></hr>
                        <h3>Обозначение: {props.detailType.number}</h3>
                    </div>
                </div>
            </FieldMain>
    )

}

export default DetailTypeListItem