import React from 'react';
import ProjectComponent from "./ProjectItem/ProjectItem";
import {Project} from "../../entities/project";
import './ProjectsListItem.css'

interface DepartmentProps {
    ID: number
    name: string
    projects: Project[] | null
}

const ProjectsListComponent: React.FC<DepartmentProps> = (props) => {
    if (props.projects === null || props.projects.length === 0) {
        return (
            <div style={{color: "white", textAlign: "center", marginBottom: "5px"}}>На данный момент проектов нет.</div>
        );
    } else {
        return (
            <div>
                {Object.values(props.projects).map((project) => (
                    <ProjectComponent key={project.ID} project={project} departmentID={props.ID} departmentName={props.name}/>
                ))}
            </div>
        );
    }
};

export default ProjectsListComponent;