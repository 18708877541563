// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-page {
    margin-left: 80px;
    margin-right: 80px;
    
}

.admin-page-header {
    display: flex; 
    justify-content: space-between;
    align-items: top;
    margin-top: 40px;
    margin-bottom: 40px;
}

.admin-page-title {
    color: var(--primary-text-color);
    margin-right: 20px !important;
}

.admin-page-search {
    width: 50%;
}

.admin-page-search-form {
    border-radius: 8px;
    height: 40px;
}

.admin-page-search-form:focus {
    border: 2px solid #F65727;
    box-shadow: 0 0 0 0 
}
.admin-page-button {
    height: 40px !important;
    width: 100%;
}

.admin-page-switch-left {
    border-radius: 0px 0px 0px 8px !important;
    height: 40px !important;
    align-items: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 100%;
}

.admin-page-switch-right {
    border-radius: 0px 8px 0px 0px !important;
    height: 40px !important;
    align-items: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 100%;
    white-space: nowrap;
}

.admin-page-button-content {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-width: 250px !important;
}

.admin-form {
    width: 30vw;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminPage/AdminPage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;;AAEtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;IAChC,6BAA6B;AACjC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB;AACJ;AACA;IACI,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,yCAAyC;IACzC,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,4BAA4B;IAC5B,WAAW;AACf;;AAEA;IACI,yCAAyC;IACzC,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,4BAA4B;IAC5B,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,uBAAuB;IACvB,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;AACf","sourcesContent":[".admin-page {\n    margin-left: 80px;\n    margin-right: 80px;\n    \n}\n\n.admin-page-header {\n    display: flex; \n    justify-content: space-between;\n    align-items: top;\n    margin-top: 40px;\n    margin-bottom: 40px;\n}\n\n.admin-page-title {\n    color: var(--primary-text-color);\n    margin-right: 20px !important;\n}\n\n.admin-page-search {\n    width: 50%;\n}\n\n.admin-page-search-form {\n    border-radius: 8px;\n    height: 40px;\n}\n\n.admin-page-search-form:focus {\n    border: 2px solid #F65727;\n    box-shadow: 0 0 0 0 \n}\n.admin-page-button {\n    height: 40px !important;\n    width: 100%;\n}\n\n.admin-page-switch-left {\n    border-radius: 0px 0px 0px 8px !important;\n    height: 40px !important;\n    align-items: center;\n    padding-top: 0 !important;\n    padding-bottom: 0 !important;\n    width: 100%;\n}\n\n.admin-page-switch-right {\n    border-radius: 0px 8px 0px 0px !important;\n    height: 40px !important;\n    align-items: center;\n    padding-top: 0 !important;\n    padding-bottom: 0 !important;\n    width: 100%;\n    white-space: nowrap;\n}\n\n.admin-page-button-content {\n    display: flex;\n    align-content: center;\n    justify-content: center;\n    align-items: center;\n    min-width: 250px !important;\n}\n\n.admin-form {\n    width: 30vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
