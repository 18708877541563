import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import "./index.css";
import {BrowserRouter} from "react-router-dom";
import {UserProvider} from "./context/AuthProvider";


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
            <BrowserRouter>
                <UserProvider>
                    <App/>
                </UserProvider>
            </BrowserRouter>
    </React.StrictMode>
);