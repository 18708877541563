// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/fonts/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/fonts/Roboto-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Roboto Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('ttf');
}
@font-face {
    font-family: 'Roboto Black';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}



body {
    font-family: "Roboto Regular";
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--base-general-color);
    transition: background-color 0ms linear;
}

[data-theme='dark'] {
    --primary-text-color: #ffffff;
    --base-grey-color: #ffffff;

    --base-root-color: #1b1a1a;
    --base-main-color: #595959;
    --base-general-color: #908e8e;

    --base-button-color: #F65727;
    --base-button-color-active: #944127;
  
    --background-color: #100f12;
}
  
[data-theme='light'] {
    --all-button-shadow-box-color: #F65727;

    --primary-text-color: #000000;
    --base-grey-color: #757575;

    --base-main-color: #FCEDE8;
    --base-root-color: #ECECEC;
    --base-general-color: #FFFFFF;

    --base-button-color: #FCEDE8;
    --base-border-color: #DED3D9;
    --base-button-color-active: #FFB39B;
    --base-button-color-del: #eb0b0b;

    --nav-background-color: #F5F5F5;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,0DAA0D;AAC9D;AACA;IACI,2BAA2B;IAC3B,4CAA0C;AAC9C;;;;AAIA;IACI,6BAA6B;IAC7B,SAAS;IACT,mCAAmC;IACnC,kCAAkC;IAClC,2CAA2C;IAC3C,uCAAuC;AAC3C;;AAEA;IACI,6BAA6B;IAC7B,0BAA0B;;IAE1B,0BAA0B;IAC1B,0BAA0B;IAC1B,6BAA6B;;IAE7B,4BAA4B;IAC5B,mCAAmC;;IAEnC,2BAA2B;AAC/B;;AAEA;IACI,sCAAsC;;IAEtC,6BAA6B;IAC7B,0BAA0B;;IAE1B,0BAA0B;IAC1B,0BAA0B;IAC1B,6BAA6B;;IAE7B,4BAA4B;IAC5B,4BAA4B;IAC5B,mCAAmC;IACnC,gCAAgC;;IAEhC,+BAA+B;AACnC","sourcesContent":["@font-face {\n    font-family: 'Roboto Regular';\n    src: url(../public/fonts/Roboto-Regular.ttf) format('ttf');\n}\n@font-face {\n    font-family: 'Roboto Black';\n    src: url(../public/fonts/Roboto-Black.ttf);\n}\n\n\n\nbody {\n    font-family: \"Roboto Regular\";\n    margin: 0;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    background-color: var(--base-general-color);\n    transition: background-color 0ms linear;\n}\n\n[data-theme='dark'] {\n    --primary-text-color: #ffffff;\n    --base-grey-color: #ffffff;\n\n    --base-root-color: #1b1a1a;\n    --base-main-color: #595959;\n    --base-general-color: #908e8e;\n\n    --base-button-color: #F65727;\n    --base-button-color-active: #944127;\n  \n    --background-color: #100f12;\n}\n  \n[data-theme='light'] {\n    --all-button-shadow-box-color: #F65727;\n\n    --primary-text-color: #000000;\n    --base-grey-color: #757575;\n\n    --base-main-color: #FCEDE8;\n    --base-root-color: #ECECEC;\n    --base-general-color: #FFFFFF;\n\n    --base-button-color: #FCEDE8;\n    --base-border-color: #DED3D9;\n    --base-button-color-active: #FFB39B;\n    --base-button-color-del: #eb0b0b;\n\n    --nav-background-color: #F5F5F5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
