// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manufacturing-form{
    margin-bottom: 0px !important;
}

.manufacturing-form:last-child {
    margin-right: 0;
}
.manufacturing-form p {
    margin-right: 10px;
    margin-left: 10px;
    color: var(--primary-text-color)
}

.add-mf-task-input-form {
    border: 2px solid #F65727;
    border-radius: 8px;
    margin-bottom: 5px;
}

.add-mf-task-input-form:focus {
    border: 2px solid #F65727;
    border-radius: 8px;
    box-shadow: 0 0 0 0;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Forms/NewTaskForm/NewManufacturingForm/NewManufacturingForm.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB;AACJ;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".manufacturing-form{\n    margin-bottom: 0px !important;\n}\n\n.manufacturing-form:last-child {\n    margin-right: 0;\n}\n.manufacturing-form p {\n    margin-right: 10px;\n    margin-left: 10px;\n    color: var(--primary-text-color)\n}\n\n.add-mf-task-input-form {\n    border: 2px solid #F65727;\n    border-radius: 8px;\n    margin-bottom: 5px;\n}\n\n.add-mf-task-input-form:focus {\n    border: 2px solid #F65727;\n    border-radius: 8px;\n    box-shadow: 0 0 0 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
