import React, {useEffect, useState} from "react";
import {instance} from "../../api/config";
import '../../css/Tasks.css';
import {useNavigate} from "react-router-dom";
import {AddTaskRequest, TaskType} from "../../entities/task";
import {Operation} from "../../entities/operation";
import {getProjectByID, NewProject, Project, ProjectByID} from "../../entities/project";
import {getUserByID, NewUser, User, UserByID} from "../../entities/user";
import Form from 'react-bootstrap/Form';
import {NewManufacturing} from "./NewManufacturingForm/NewManufacturingForm";
import {Detail, NewDetail} from "../../entities/detail";
import {convertYYYYMMDDToDate} from "../../common/date";
import './NewTaskForm.css'
import {toast} from "react-hot-toast";
import {DetailType, NewDetailType} from "../../entities/detail_type";
import NewPurchaseTaskForm from "./NewPurchaseTask/NewPurchaseTask";


const NewTaskForm = () => {
    const navigate = useNavigate();

    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [type, setType] = useState(''); // Пример типов задач, можно заменить на ваши типы
    const [finishDate, setFinishDate] = useState('');
    const [operations, setOperations] = useState<Operation[]>([]);
    const [detailType, setDetailType] = useState<DetailType>(NewDetailType());
    const [quantity, setQuantity] = useState(0);

    const [isParentTask, setIsParentTask] = useState(false)
    const [parentTaskID, setParentTaskID] = useState<number | null>(null)

    const [chosenProject, setChosenProject] = useState<Project>(NewProject());
    const [projects, setProjects] = useState<ProjectByID>({});

    const [chosenUser, setChosenUser] = useState<User>(NewUser());
    const [users, setUsers] = useState<UserByID>({});

    const [material, setMaterial] = useState('')
    const [gost, setGost] = useState('')
    const [size, setSize] = useState('')
    const [isInBudget, setIsInBudget] = useState(false)
    const [predictedPrice, setPredictedPrice] = useState(0)
    const [supplyProvider, setSupplyProvider] = useState('')
    const [supplyWeight, setSupplyWeight] = useState(0)
    const [supplyTimeAfterPaymentFrom, setSupplyTimeAfterPaymentFrom] = useState(0)
    const [supplyTimeAfterPaymentTo, setSupplyTimeAfterPaymentTo] = useState(0)
    const [predictedSupplyDate, setPredictedSupplyDate] = useState('')
    const [plannedSupplyDate, setPlannedSupplyDate] = useState('')
    const [supplyDate, setSupplyDate] = useState('')

    useEffect(() => {
        const getProjects = async () => {
            const resp = await instance.get("/api/projects/all")
            setProjects(getProjectByID(resp.data))
        }
        getProjects()
    }, []);

    const onProjectChange = async (project: Project) => {
        const resp = await instance.get("/api/projects", { params: {
            "ID": project.ID
            }})
        setChosenProject(resp.data)
        if (project.users === null) {
            setUsers({})
            return
        }
        setUsers(getUserByID(project.users))
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (type === '') {
            return
        }
        const newTask: AddTaskRequest = {
            project_id: chosenProject?.ID || 0,
            type: type,
            name: name,
            description: description,
            responsible_id: chosenUser?.ID || 0,
            progress: 0,
            operations: operations,
            parent_task_id: parentTaskID,
            start_date:  convertYYYYMMDDToDate(finishDate),
            finish_date: convertYYYYMMDDToDate(finishDate),
            visible: true,
            status: false,
            prev_task_id: null,
            next_task_id: null,

        };

        if (type === "Заготовительная") {
            newTask.name = `Изготовить: ${detailType.name}`;
            newTask.detail_type_id = detailType?.ID || 0
            newTask.quantity = quantity
        }
        if (type === "Закупка") {
            newTask.name = `Закупить: ${material}`;
            newTask.material = material
            newTask.gost = gost
            newTask.size = size
            newTask.quantity = quantity
            newTask.is_in_budget = isInBudget
            newTask.predicted_price = predictedPrice
            newTask.supply_provider = supplyProvider
            newTask.supply_weight = supplyWeight
            newTask.supply_time_after_payment_from = supplyTimeAfterPaymentFrom
            newTask.supply_time_after_payment_to = supplyTimeAfterPaymentTo
            newTask.predicted_supply_date = convertYYYYMMDDToDate(predictedSupplyDate)
            newTask.planned_supply_date = convertYYYYMMDDToDate(plannedSupplyDate)
            newTask.supply_date = convertYYYYMMDDToDate(finishDate)
        }
        try {
            const response = await instance.post('/api/tasks', newTask);
            setType('')
            setFinishDate('');
            navigate("/tasks")


        } catch (error) {
            console.error('Error creating task:', error);
        }
        toast("Задача успешно создана!")

    };

    return (
        <div style={{margin: "10px"}}>
            <button className="add-task-button w-30 mb-2 btn btn-lg btn-primary" onClick={() => { navigate('/tasks')}}>Назад к задачам</button>
            <form onSubmit={handleSubmit} className="form-floating" style={{width: "100%"}}>
                <div style={{ display: "flex", justifyContent: "space-between" , width: "100%" }} >
                    <div style={{width: "49%"}}>
                        <div className="mb-3">
                            <p style={{color: "white"}}>Тип задачи</p>
                            <Form.Select aria-label="Task Type"
                                         id="taskType"
                                         className="form-control"
                                         value={type}
                                         onChange={(e) => setType(e.target.value as TaskType)}
                                         required
                            >
                                <option value="" disabled>Выберите тип задачи</option>
                                {Object.values(TaskType).map((taskType) => (
                                    <option key={taskType} value={taskType}>{taskType}</option>
                                ))}
                            </Form.Select>
                        </div>
                        {(type === 'Общая') ?
                            <div>
                                <div className="mb-3">
                                    <p style={{color: "white"}}>Название задачи</p>
                                    <Form.Control aria-label="Finish Date"
                                                  id="taskFinishDate"
                                                  className="form-control"
                                                  value={name || ""}
                                                  onChange={(e) => setName(e.target.value)}
                                                  required
                                                  placeholder="Название задачи"
                                    />
                                </div>
                            </div> : <></>
                        }
                        <div className="mb-3">
                            <p style={{color: "white"}}>Дата НЗИ</p>
                            <Form.Control aria-label="Finish Date"
                                          id="taskFinishDate"
                                          className="form-control"
                                          type="date"
                                          value={finishDate}
                                          onChange={(e) => setFinishDate(e.target.value)}
                                          required
                            />
                        </div>
                        <div className="mb-3">
                            <p style={{color: "white"}}>Проект</p>
                            <Form.Select aria-label="Project"
                                         id="taskProject"
                                         className="form-control"
                                         value={chosenProject?.ID || ""}
                                         onChange={(e) => {
                                             setChosenProject(projects[e.target.value])
                                             onProjectChange(projects[e.target.value])
                                         }
                                         }
                                         required
                            >
                                <option value="" disabled>Выберите проект</option>
                                {Object.values(projects).map((project) => (
                                    <option key={project.ID} value={project?.ID || 0}>
                                        {project.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className="mb-3">
                            <p style={{color: "white"}}>Ответственный</p>
                            <Form.Select aria-label="User"
                                         id="taskUser"
                                         className="form-control"
                                         value={chosenUser?.ID || ""}
                                         onChange={(e) => {
                                             setChosenUser(users[e.target.value])
                                         }}
                                         required
                            >
                                <option value="" disabled>Выберите пользователя</option>
                                {Object.values(users).map((user) => (
                                    <option key={user.ID} value={user?.ID || 0}>
                                        {user.username}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div>
                            <p style={{color: "white"}}>Описание</p>
                            <textarea placeholder="Введите описание задачи" name="NewProjectDescription"
                                      rows={6}

                                      value={description || ""}
                                      style={{resize: "none", width: "100%"}}
                                      onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                        </div>
                        <div>
                            <p style={{color: "white"}}>Имеется задача предок</p>
                            <Form.Check // prettier-ignore
                                width="30px"
                                height="30px"
                                id={`default-${type}`}
                                checked={isParentTask}
                                onChange={(e) => setIsParentTask(e.target.checked)}
                            />
                        </div>
                        {(isParentTask)?
                        <div className="mb-3">
                            <p style={{color: "white"}}>Задача предок</p>
                            <Form.Select aria-label="User"
                                         id="taskUser"
                                         className="form-control"
                                         value={parentTaskID || 0}
                                         onChange={(e) => {
                                             setParentTaskID(parseInt(e.target.value))
                                         }}
                                         required
                            >
                                <option value="" disabled>Выберите задачу</option>
                                {Object.values(chosenProject.tasks || []).map((task) => (
                                    <option key={task.ID} value={task.ID}>
                                        {task.name} {(task.responsible.name && task.responsible.surname)? `${task.responsible.name} ${task.responsible.surname}` : task.responsible.username } {task.finish_date.slice(0,10)}
                                    </option>
                                ))}
                            </Form.Select>
                        </div> : <></>
                        }
                    </div>
                    <div style={{width: "49%"}}>
                        {(type === 'Заготовительная') ?
                            <NewManufacturing
                                detailType={detailType}
                                setDetailType={setDetailType}
                                quantity={quantity}
                                setQuantity={setQuantity}
                            /> : <></>
                        }
                        {(type === 'Закупка') ?
                            <NewPurchaseTaskForm
                                material={material}
                                setMaterial={setMaterial}
                                gost={gost}
                                setGost={setGost}
                                quantity={quantity}
                                setQuantity={setQuantity}
                                size={size}
                                setSize={setSize}
                                isInBudget={isInBudget}
                                setIsInBudget={setIsInBudget}
                                predictedPrice={predictedPrice}
                            setPredictedPrice={setPredictedPrice}
                            supplyProvider={supplyProvider}
                            setSupplyProvider={setSupplyProvider}
                            supplyWeight={supplyWeight}
                            setSupplyWeight={setSupplyWeight}
                            supplyTimeAfterPaymentFrom={supplyTimeAfterPaymentFrom}
                            setSupplyTimeAfterPaymentFrom={setSupplyTimeAfterPaymentFrom}
                            supplyTimeAfterPaymentTo={supplyTimeAfterPaymentTo}
                            setSupplyTimeAfterPaymentTo={setSupplyTimeAfterPaymentTo}
                            predictedSupplyDate={predictedSupplyDate}
                            setPredictedSupplyDate={setPredictedSupplyDate}
                            plannedSupplyDate={plannedSupplyDate}
                            setPlannedSupplyDate={setPlannedSupplyDate}
                            supplyDate={supplyDate}
                            setSupplyDate={setSupplyDate}
                        /> : <></>
                    }
                    </div>
                </div>
                <button className="add-task-button w-100 btn btn-lg btn-primary" type="submit">Создать задачу</button>
            </form>
        </div>
    );
}

export default NewTaskForm;