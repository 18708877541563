import React, { useEffect, useRef, useState } from "react";
import {useParams} from "react-router-dom";
import {DetailType, NewDetailType} from "../../entities/detail_type";
import {instance} from "../../api/config";
import './DetailTypePage.css'
import { FieldMain } from "../../components/Field/Fields";
import { Button } from "../../components/Button/Button";
import { S3_URL } from "../../entities/file";
import { UseIcons } from "../../hooks/UseIcons";
const DetailTypePage = () => {

    const { id } = useParams();
    const icons = UseIcons()
    const [ detailType, setDetailType] = useState<DetailType>(NewDetailType());
    const [draft, setDraft] = useState<string>("");

    const fileInputRef = useRef<HTMLInputElement>(null);
    
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
          const loadAvatar = async () => {
            const data = new FormData()
            data.append('draft', file);
            data.append('metadata',`{ "detail_type_id": ${detailType?.ID}}`)
            await instance.post('/api/detailTypes/upload', data)
          }
          await loadAvatar();
          const reader = new FileReader();
          reader.onloadend = () => {
            setDraft(reader.result as string);
          };
          reader.readAsDataURL(file);
        }
      };

    useEffect(() => {
        const getDetailType = async () => {
            const resp = await instance.get("/api/detailTypes", { params: {
                "ID": id
                }});
            setDetailType(resp.data)
            setDraft((resp.data?.draft?.path) ? `${S3_URL}${resp.data?.draft?.path}` : "")
        }
        getDetailType()
    }, []);

    return (
        <div className="detail-type">
            <div className="detail-type-header-buttons">
                <Button buttonType="white-shadowed"
                    onClick={
                        (e) => {
                            e.preventDefault();
                            window.history.back();
                        }
                    }
                >
                    Вернуться в деталям
                </Button>
                <Button buttonType="white-shadowed">
                    <label htmlFor="avatar-upload">
                        Загрузить чертеж
                    </label>
                    <input
                            ref={fileInputRef}
                            id="avatar-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: "none"}}
                            className="hidden"
                    />
                </Button>
            </div>
            <div className="detail-type-title">
                    <div>
                        <h1>{detailType.name}</h1>
                        <h2>Обозначение: {detailType.number}</h2>
                    </div>
                    <FieldMain className="detail-type-storage" >
                        <div className="detail-type-storage-image">
                            <img src={icons.INFO_ICON} width="30" height="30" ></img>
                        </div>
                        <div>
                            <p style={{ marginBottom: "0px"}}><b>Склад:</b> {detailType.route_list.storage.name}</p>
                            <p><b>Адрес:</b> {detailType.route_list.storage.address}</p>
                        </div>
                    </FieldMain>
            </div>
            <div  className="detail-type-operations">
                <div className="operations-info-item">
                    <h3>Список операций: </h3>
                    <div className="detail-type-operations-list">
                        {detailType.route_list.operations?.map((operation, index) => (
                            <FieldMain  className="detail-type-operations-list-card">
                                <div>
                                    <h3 style={{ color: "var(--base-grey-color)"}}>{ index + 1 }</h3>
                                    <h3>Тип: <br></br>{operation.type}</h3>
                                    <h4 >Номер: {operation.number} </h4>
                                </div>
                            </FieldMain>
                        ))}
                    </div>
                </div>
            </div>
            { (detailType.draft?.path) ?
            <div className="detail-type-draft">
                <h3>Чертеж: </h3>
                <img style={{ borderRadius: "8px" }} src={draft} width="100%" height="100%"></img>
            </div>
            : <></>}
        </div>
    )
}

export default DetailTypePage;