import styled from 'styled-components'

export const Tag = styled.p`
    box-shadow: 0 0.1em 0.30em  #757575;
    background-color: var(--all-button-shadow-box-color);
    color: var(--base-main-color);
    border-radius: 8px;
    width: fit-content;
    padding: 1px 12px 1px 12px
    
`