export interface NavItem {
    type: 'link' | 'button' | 'dropdown';
    label?: string;
    href?: string;
    imgSrc?: string;
    imgAlt?: string;
    width?: number;
    height?: number;
    items?: DropdownItem[];
    active?: boolean;
    onClick?: () => void;
}

interface DropdownItem {
    label: string;
    href?: string;
    route?: string;
}

interface NavigationItemByRole {
 [role: number]: NavItem[]
}

export const navItems : NavigationItemByRole = {
    0: [
    {
        type: 'link',
        href: 'https://www.imzrb.ru/',
        imgSrc: '/brand.svg',
        imgAlt: 'IMZboxlogo',
        width: 50,
        height: 50,
    },
    {
        type: 'button',
        label: 'Мои задачи',
        active: true,
    },
    {
        type: 'button',
        label: 'Блокнот',
        active: false,
    },
],
    1:[
        {
            type: 'link',
            href: 'https://www.imzrb.ru/',
            imgSrc: '/brand.svg',
            imgAlt: 'IMZboxlogo',
            width: 50,
            height: 50,
        },
        {
            type: 'button',
            label: 'Мои задачи',
            active: true,
        },
        {
            type: 'dropdown',
            label: 'Единые окна',
            items: [
                { label: 'Проекты', route: '/departments' },
                { label: 'Склады', route: '/storages' },
                { label: '[excel] ПГ КО', href: 'https://docs.google.com/spreadsheets/d/18A2JZYFLXa7N9fsapHaCcYvnCcwInOQZ/edit?usp=sharing&ouid=106468575321118252103&rtpof=true&sd=true' },
                { label: '[excel] Причины', href: 'https://docs.google.com/spreadsheets/d/1KTWMDtcTazLhLW7-vnZpfWHrzErcHCNwZ6vLCrj9_00/edit#gid=2014116917' },
                { label: '[excel] Суточный ПФ', href: 'https://docs.google.com/spreadsheets/d/1rkywXQYLGt2M5cByCgx9YVqiribrnllZ/edit?usp=drive_link' },
                { label: '[excel] ПФ2П', href: ' https://docs.google.com/spreadsheets/d/1LJhWqbAAB5ZV-LqUZXSQ7kc9PHbg7HpQLIo9Jw1KrwM/edit#gid=856264193' },
            ],
        },
        {
            type: 'button',
            label: 'Блокнот',
            active: false,
        },
    ],
    2:[
        {
            type: 'link',
            href: 'https://www.imzrb.ru/',
            imgSrc: '/brand.svg',
            imgAlt: 'IMZboxlogo',
            width: 50,
            height: 50,
        },
        {
            type: 'button',
            label: 'Мои задачи',
            active: true,
        },
        {
            type: 'dropdown',
            label: 'Единые окна',
            items: [
                { label: 'Проекты', route: '/departments' },
                { label: 'Склады', route: '/storages' },
                // { label: '1. Проекты', route: RoutesMap.DEPARTMENTS },
                // { label: '1. Проекты', route: RoutesMap.DEPARTMENTS },
                { label: 'ПГ КО', route: 'https://docs.google.com/spreadsheets/d/18A2JZYFLXa7N9fsapHaCcYvnCcwInOQZ/edit?usp=sharing&ouid=106468575321118252103&rtpof=true&sd=true' },
                // { label: '3. Суточное НЗИ', href: 'https://docs.google.com/spreadsheets/d/1ClgLqgu3qB45bqbCZGmShVBh5IPnbrzM/edit#gid=1221409714' },
                { label: 'Причины', route: 'https://docs.google.com/spreadsheets/d/1KTWMDtcTazLhLW7-vnZpfWHrzErcHCNwZ6vLCrj9_00/edit#gid=2014116917' },
                { label: 'Суточный ПФ', route: 'https://docs.google.com/spreadsheets/d/1rkywXQYLGt2M5cByCgx9YVqiribrnllZ/edit?usp=drive_link' },
                { label: 'ПФ2П', route: ' https://docs.google.com/spreadsheets/d/1LJhWqbAAB5ZV-LqUZXSQ7kc9PHbg7HpQLIo9Jw1KrwM/edit#gid=856264193' },
            ],
        },
        {
            type: 'button',
            label: 'Блокнот',
            active: false,
        },
    ],
    3:[
        {
            type: 'link',
            href: 'https://www.imzrb.ru/',
            imgSrc: '/brand.svg',
            imgAlt: 'IMZboxlogo',
            width: 50,
            height: 50,
        },
        {
            type: 'button',
            label: 'Мои задачи',
            active: true,
        },
        {
            type: 'dropdown',
            label: 'Единые окна',
            items: [
                { label: 'Проекты', route: '/departments' },
                { label: 'Склады', route: '/storages' },
                // { label: '1. Проекты', route: RoutesMap.DEPARTMENTS },
                // { label: '1. Проекты', route: RoutesMap.DEPARTMENTS },
                // { label: '2. ПГ КО', href: 'https://docs.google.com/spreadsheets/d/18A2JZYFLXa7N9fsapHaCcYvnCcwInOQZ/edit?usp=sharing&ouid=106468575321118252103&rtpof=true&sd=true' },
                // { label: '3. Суточное НЗИ', href: 'https://docs.google.com/spreadsheets/d/1ClgLqgu3qB45bqbCZGmShVBh5IPnbrzM/edit#gid=1221409714' },
                // { label: '4. Причины', href: 'https://docs.google.com/spreadsheets/d/1KTWMDtcTazLhLW7-vnZpfWHrzErcHCNwZ6vLCrj9_00/edit#gid=2014116917' },
                // { label: '5. Суточный ПФ', href: 'https://docs.google.com/spreadsheets/d/1rkywXQYLGt2M5cByCgx9YVqiribrnllZ/edit?usp=drive_link' },
                // { label: '6. ПФ2П', href: ' https://docs.google.com/spreadsheets/d/1LJhWqbAAB5ZV-LqUZXSQ7kc9PHbg7HpQLIo9Jw1KrwM/edit#gid=856264193' },
            ],
        },
        {
            type: 'button',
            label: 'Блокнот',
            active: false,
        },
    ],
    4:[
        {
            type: 'link',
            href: 'https://www.imzrb.ru/',
            imgSrc: '/brand.svg',
            imgAlt: 'IMZboxlogo',
            width: 50,
            height: 50,
        },
        {
            type: 'button',
            label: 'Мои задачи',
            active: true,
        },
        {
            type: 'dropdown',
            label: 'Единые окна',
            items: [
                { label: 'Проекты', route: '/departments' },
                { label: 'Склады', route: '/storages' },
                // { label: '1. Проекты', route: RoutesMap.DEPARTMENTS },
                // { label: '1. Проекты', route: RoutesMap.DEPARTMENTS },
                // { label: '2. ПГ КО', href: 'https://docs.google.com/spreadsheets/d/18A2JZYFLXa7N9fsapHaCcYvnCcwInOQZ/edit?usp=sharing&ouid=106468575321118252103&rtpof=true&sd=true' },
                // { label: '3. Суточное НЗИ', href: 'https://docs.google.com/spreadsheets/d/1ClgLqgu3qB45bqbCZGmShVBh5IPnbrzM/edit#gid=1221409714' },
                // { label: '4. Причины', href: 'https://docs.google.com/spreadsheets/d/1KTWMDtcTazLhLW7-vnZpfWHrzErcHCNwZ6vLCrj9_00/edit#gid=2014116917' },
                // { label: '5. Суточный ПФ', href: 'https://docs.google.com/spreadsheets/d/1rkywXQYLGt2M5cByCgx9YVqiribrnllZ/edit?usp=drive_link' },
                // { label: '6. ПФ2П', href: ' https://docs.google.com/spreadsheets/d/1LJhWqbAAB5ZV-LqUZXSQ7kc9PHbg7HpQLIo9Jw1KrwM/edit#gid=856264193' },
            ],
        },
        {
            type: 'button',
            label: 'Блокнот',
            active: false,
        },
    ],
    5: [
        {
            type: 'link',
            href: 'https://www.imzrb.ru/',
            imgSrc: '/brand.svg',
            imgAlt: 'IMZboxlogo',
            width: 50,
            height: 50,
        },
        {
            type: 'button',
            label: 'Мои задачи',
            active: true,
        },
        {
            type: 'button',
            label: 'Блокнот',
            active: false,
        },
    ],
    6: [
        {
            type: 'link',
            href: 'https://www.imzrb.ru/',
            imgSrc: '/brand.svg',
            imgAlt: 'IMZboxlogo',
            width: 50,
            height: 50,
        },
        {
            type: 'button',
            label: 'Мои задачи',
            active: true,
        },
        {
            type: 'button',
            label: 'Блокнот',
            active: false,
        },
    ],
    7: [
        {
            type: 'link',
            href: 'https://www.imzrb.ru/',
            imgSrc: '/brand.svg',
            imgAlt: 'IMZboxlogo',
            width: 50,
            height: 50,
        },
        {
            type: 'button',
            label: 'Мои задачи',
            active: true,
        },
        {
            type: 'button',
            label: 'Блокнот',
            active: false,
        },
    ]

};