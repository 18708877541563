// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-material-form-container p {
    color: var(--primary-text-color);
    margin-bottom: 5px;
}

.new-material-input-form {
    border: 2px solid #F65727;
    border-radius: 8px;
    margin-bottom: 20px;
}

.new-material-input-form:focus {
    border: 2px solid #F65727;
    border-radius: 8px;
    box-shadow: 0 0 0 0;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Forms/NewAssotmentTypeForm/NewAssortmentTypeForm.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".new-material-form-container p {\n    color: var(--primary-text-color);\n    margin-bottom: 5px;\n}\n\n.new-material-input-form {\n    border: 2px solid #F65727;\n    border-radius: 8px;\n    margin-bottom: 20px;\n}\n\n.new-material-input-form:focus {\n    border: 2px solid #F65727;\n    border-radius: 8px;\n    box-shadow: 0 0 0 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
