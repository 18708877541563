import React from 'react';
import { Department } from '../../../entities/department';
import { Accordion, AccordionTab } from 'primereact/accordion';
import DepartmentListItem from '../../ListItems/DepartmentListItem/DepartmentListItem';
import './DepartmentList.css';
import { Button } from '../../../components/Button/Button';
import { UseIcons } from '../../../hooks/UseIcons';

interface DepartmentList {
    departments: Department[]
    setSelectedDepartmentID?: (departmentID: number) => void
    setNpf_visible?: (value: boolean) => void
}
const DepartmentList = (props: DepartmentList) => {
    const icons = UseIcons()
    const accordionTabHeaderTemplate = (header: string, departmentID: number) => {
        return (
            <div className='department-list-header'>
                <label className='department-list-header-title'>{departmentID}. {header}</label>
                {(props.setNpf_visible && props.setSelectedDepartmentID) ? 
                    <Button className='department-list-header-button' buttonType='white-shadowed' onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (props.setNpf_visible !== undefined && props.setSelectedDepartmentID !== undefined) {
                            props?.setSelectedDepartmentID(departmentID)
                            props?.setNpf_visible(true)
                        }
                    }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginRight: "5px"}} src={icons.ADD} alt='avatar' width='30' height='30' />
                            <label>Новый проект</label>
                        </div>
                    </Button> : <></>
                }
            </div>
        );
    };

    return (
        <Accordion>
        {
            props.departments.map((department) => (
                    <AccordionTab key={department.ID}
                                headerTemplate={accordionTabHeaderTemplate(department.name, department.ID)}>
                        <DepartmentListItem ID={department.ID} name={department.name}
                                            projects={department.projects}/>
                    </AccordionTab>
                ))
        }
        </Accordion>
    )
}

export default DepartmentList;