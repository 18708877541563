import React, {useEffect, useState} from "react";
import {ShortStorage} from "../../entities/storage";
import {instance} from "../../api/config";
import { useNavigate} from "react-router-dom";
import { Form } from "react-bootstrap";
import { Dialog } from "primereact/dialog";
import NewStorageForm from "../../widgets/Forms/NewStorageForm/NewStorageForm";
import StorageList from "../../widgets/Lists/StoragesList/StorageList";
import { FieldRoot } from "../../components/Field/Fields";
import { Button } from "../../components/Button/Button";
import './StorageListPage.css'
import { UseIcons } from "../../hooks/UseIcons";
const StorageListPage = () => {

    const icons = UseIcons()
    const navigate = useNavigate();

    const [storageList, setStorageList] = useState<ShortStorage[]>([]);
    const [allStorageList, setAllStorageList] = useState<ShortStorage[]>([]);
    const [isAdd, setIsAdd] = useState(false);

    useEffect(() => {
        const getStorages = async () => {
            const resp = await instance.get("/api/storage/all")
            setStorageList(resp.data)
            setAllStorageList(resp.data)
        }
        getStorages()
    }, []);

    const handleNewStorage = async (e: React.FormEvent) => {
        e.preventDefault()
        setIsAdd(true)
    }


    return (
        <div className='storage-list'>
                <div className='storage-list-header'>
                    <h1 >Склады</h1>
                    <div className='storage-list-search'>
                        <Form.Control 
                            className="form-control storage-list-search-form"
                            placeholder="Поиск по складам"
                            onChange={
                                (e) => {
                                    const newStorages = allStorageList?.filter(storage => storage.name?.toLowerCase().includes(e.target.value.toLowerCase()) || storage.address?.toLowerCase().includes(e.target.value.toLowerCase()))
                                    for (var storage of allStorageList || []) {
                                        if (!newStorages?.includes(storage)) {
                                            var newStorage = structuredClone(storage)
                                            newStorage.details_with_count = newStorage.details_with_count?.filter(d => d.detailType?.name?.toLowerCase().includes(e.target.value.toLowerCase()) || d.detailType?.number?.toLowerCase().includes(e.target.value.toLowerCase())) || null
                                            if (newStorage.details_with_count != null && newStorage.details_with_count?.length > 0) {
                                                newStorages?.push(newStorage)
                                            }
                                    }
                                }
                                    setStorageList(newStorages || [])
                                }
                            }
                        >
                        </Form.Control>
                    </div>
                    
                    <Button type="button" buttonType="white-shadowed" className="storage-list-new-storage-button" onClick={handleNewStorage}>
                        <div className="storage-list-new-storage-button-content">
                            <img style={{ marginRight: "10px"}} src={icons.ADD} width="30" height="30"></img>
                            Новый склад
                        </div>
                    </Button>
                </div>
                <StorageList storages={storageList} />
                <Dialog header="Новый склад" visible={isAdd} className='new-storage' onHide={() => {
                    if (!isAdd) return;
                        setIsAdd(false); }}>
                    <NewStorageForm setIsAdd={setIsAdd}/>
                </Dialog>
        </div>
    )
}

export default StorageListPage;