import React from 'react';
import {Task, TaskType} from "../../../entities/task";
import {MantineProvider, Timeline, Text} from "@mantine/core";
import '@mantine/core/styles.css';

interface ChildTasksInfoProps {
    childTasks: Task[]
}

const ChildTasksInfo = (props: ChildTasksInfoProps) => {
    return (
        <MantineProvider>
            <Timeline active={0} color="#F65727" bulletSize={48} lineWidth={4}>
                {props.childTasks.map((childTask, index) => (
                            <Timeline.Item title={childTask.type}  bullet={index}>
                                <Text c="dimmed" size="sm">Исполнитель: {childTask.responsible.username}</Text>
                            </Timeline.Item>
                ))}
            </Timeline>
        </MantineProvider>
    )
}

export default ChildTasksInfo;