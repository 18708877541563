import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {LongStorage, ShortStorage} from "../entities/storage";
import {instance} from "../api/config";
import StorageComponent from "../components/StorageComponent/StorageComponent";

const StoragePage = () => {

    const { id } = useParams()

    const [storage, setStorage] = useState<ShortStorage>()
    useEffect(() => {
        const getLongStorage = async () => {
            const resp = await instance.get("/api/storage", { params: {
                "ID": id
                }})
            setStorage(resp.data)
        }
        getLongStorage()
    }, []);
    if (storage === undefined || storage === null) {
        return (
            <>Ошибка, обратитесь к администратору</>
        )
    }
    return (
        <div>
            <StorageComponent storage={storage} />
        </div>
    )
}

export default StoragePage;