// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status {
    border-radius: 20px;
    align-items: center;
    padding: 4px;

}
.status-manufacturing {
    background-color: orange;
}

.status-quality {
    background-color: dodgerblue;
}

.status-storage {
    background-color: coral;
}

.status-done {
    background-color: limegreen;
}

/*InProgress       DetailStatus = "Разработка"*/
/*OnCreate         DetailStatus = "На изготовлении"*/
/*OnQualityControl DetailStatus = "На контроле качества"*/
/*OnWayToStorage   DetailStatus = "В пути на склад"*/
/*Finished         DetailStatus = "Выполнено"*/`, "",{"version":3,"sources":["webpack://./src/components/DetailCountList/DetailCountListItem/DetailStatus/DetailStatus.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;;AAEhB;AACA;IACI,wBAAwB;AAC5B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA,+CAA+C;AAC/C,oDAAoD;AACpD,yDAAyD;AACzD,oDAAoD;AACpD,8CAA8C","sourcesContent":[".status {\n    border-radius: 20px;\n    align-items: center;\n    padding: 4px;\n\n}\n.status-manufacturing {\n    background-color: orange;\n}\n\n.status-quality {\n    background-color: dodgerblue;\n}\n\n.status-storage {\n    background-color: coral;\n}\n\n.status-done {\n    background-color: limegreen;\n}\n\n/*InProgress       DetailStatus = \"Разработка\"*/\n/*OnCreate         DetailStatus = \"На изготовлении\"*/\n/*OnQualityControl DetailStatus = \"На контроле качества\"*/\n/*OnWayToStorage   DetailStatus = \"В пути на склад\"*/\n/*Finished         DetailStatus = \"Выполнено\"*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
