import React, {useEffect, useState} from 'react';
import './LoginPage.css'
import {useAuth} from "../../context/AuthProvider";
import Switch from 'react-switch'
import { Button } from '../../components/Button/Button';


const LoginPage = () => {
    const { loginUser } = useAuth()

    const [user, setUser] = useState<string | null>(null)
    const [password, setPassword] = useState<string | null>(null)
    const [checked, setChecked] = useState<boolean>(false)

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (user && password) {
            await loginUser(user, password)
        }
    };

    useEffect(() => {
        document.body.classList.add('login-page');
        return () => {
            document.body.classList.remove('login-page');
        };
    }, []);
    return (
        <div className="login-container">
            <div className="login">
                <div className="login-logo">
                    <a href="https://www.imzrb.ru/">
                        <img src="/brand.svg" alt="IMZboxlogo" width="1800" height="1800" />
                    </a>
                </div>
                <div>
                    <div className="form-signin">
                        <form onSubmit={handleSubmit}>
                            <div className="inputGroup">
                                <div className="form-floating">
                                    <input
                                        type="login"
                                        style={{ marginRight: "0px"}}
                                        className="form-control fill"
                                        id="emailInput"
                                        placeholder=""
                                        required
                                        onChange={e => setUser(e.target.value)}
                                    />
                                    <label htmlFor="emailInput">Логин</label>
                                </div>
                                <div className="form-floating">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="passwordInput"
                                        placeholder=""
                                        required
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                    <label htmlFor="passwordInput">Пароль</label>
                                </div>
                                <div className="login-checkbox">
                                    <Switch uncheckedIcon={false} checkedIcon={false} onColor="#F65727" offColor="#100f12" checked={checked} onChange={() => {setChecked(!checked)}}/>
                                    <p >Запомнить меня</p>
                                </div>
                            </div>
                            <Button className="login-button" type="submit">Войти</Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
