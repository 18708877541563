import {useAuth} from "../context/AuthProvider";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {RoutesMap} from "../routes/AppRoutes";
import React, {useEffect, useState} from "react";

const RequireAuth = () => {
    const { isLoggedIn }= useAuth()
    const location = useLocation()


    return (
        isLoggedIn() ? <Outlet />
            : <Navigate to={RoutesMap.LOGIN} state={{ from: location }} replace />
    )
}

export default RequireAuth