import React, {useEffect, useState} from 'react';
import {useAuth} from "../../context/AuthProvider";
import {instance} from "../../api/config";
import {AddDepartmentRequest, Department} from "../../entities/department";
import {Panel} from "primereact/panel";
import {Accordion, AccordionTab} from "primereact/accordion";
import {Dialog} from "primereact/dialog";
import {getOptionsByUsers, User} from "../../entities/user";
import Form from "react-bootstrap/Form";
import {AddProjectRequest, UserToAdd} from "../../entities/project";
import Select, {MultiValue} from "react-select";
import makeAnimated from "react-select/animated";
import {convertYYYYMMDDToDate} from "../../common/date";
import './ProjectsPage.css'
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/bootstrap4-dark-purple/theme.css';
import DepartmentListItem from '../../widgets/ListItems/DepartmentListItem/DepartmentListItem';
import { FieldRoot } from '../../components/Field/Fields';
import { Button } from '../../components/Button/Button';
import DepartmentList from '../../widgets/Lists/DepartmentList/DepartmentList';
import { UseIcons } from '../../hooks/UseIcons';
import { Input } from '../../components/Input/Input';
import { ZIndexUtils } from 'primereact/utils';

const ProjectsPage = () => {

    const {user} = useAuth()
    const icons = UseIcons()

    const [departments, setDepartments] = useState<Department[] | null>(null);
    const [allDepartments, setAllDepartments] = useState<Department[] | null>(null);
    const [ndf_visible, setNdf_visible] = useState(false);
    const [npf_visible, setNpf_visible] = useState(false);
    const [departmentName, setDepartmentName] = useState<string | null>("")
    const [users, setUsers] = useState<User[] | null>(null);
    const [selectedDepartmentID, setSelectedDepartmentID] = useState<number>(0)
    const [finishDate, setFinishDate] = useState('');
    const [projectName, setProjectName] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<MultiValue<{
        value: number;
        label: string;
    }> | null>(null);

    useEffect(() => {
        const getAllDepartments = async () => {
            const resp = await instance.get("/api/departments/all")
            setDepartments(resp.data)
            setAllDepartments(resp.data)
        }
        const getAllUsers = async () => {
            const resp = await instance.get("/api/users/all")
            setUsers(resp.data)
        }
        getAllDepartments().then()
        getAllUsers().then()
    }, [user]);

    let selOptions;
    if (users !== null) {
        selOptions = getOptionsByUsers(users);
    }

    const createDepartment = async () => {
        const newDepartment: AddDepartmentRequest = {
            name: departmentName,
        };

        try {
            await instance.post('/api/departments', newDepartment);
        } catch (error) {
            console.error('Error creating department:', error);
        }
    };

    const createProject = async (selectedUsers: UserToAdd[]) => {
        const newProject: AddProjectRequest = {
            name: projectName,
            department_id: selectedDepartmentID,
            director_id: 1,
            lead_designer_id: 1,
            finish_date: convertYYYYMMDDToDate(finishDate),
            progress: 0,
            number: "1.1",
            users: selectedUsers,
        };

        try {
            await instance.post('/api/projects', newProject);
        } catch (error) {
            console.error('Error creating project:', error);
        }
    };

    if (user === null) {
        return <div style={{color: "var(--primary-text-color)"}}>Не авторизован</div>;
    }

    const selectStyles = {
        control: (base:any) => ({
            ...base,
            color: 'var(--primary-text-color)',
            border: "2px solid #F65727",
            borderRadius: "8px",
            marginBottom: "20px",
            backgroundColor: 'var(--base-general-color)',
        }),
        multiValue: (base:any) => ({
            ...base,
            backgroundColor: 'var(--base-general-color)',
        }),
        multiValueLabel: (base:any) => ({
            ...base,
            color: 'var(--primary-text-color)',
            backgroundColor: 'var(--base-main-color)',
        }),
        menuList: (base:any) => ({
            ...base,
            backgroundColor: 'var(--base-general-color)',
        }),
        option: (base:any) => ({
            ...base,
            backgroundColor:'var(--base-general-color)',
            "&:hover": {
                backgroundColor: 'var(--base-general-color)',
            },
        })
    }

    return (
        <FieldRoot className="projects-page">
            <div className='projects-header'>
                <h1 style={{fontSize:'30px'}}>Отделы и проекты</h1>
                <div className='projects-search'>
                    <Form.Control 
                        className="form-control projects-search-form"
                        placeholder="Поиск по отделам и проектам"
                        onChange={(e) => {
                            var newDepartments = allDepartments?.filter(dept => dept.name.toLowerCase().includes(e.target.value.toLowerCase()))
                            for (var dept of allDepartments || []) {
                                    if (!newDepartments?.includes(dept)) {
                                        var newDept = structuredClone(dept)
                                        newDept.projects = newDept.projects.filter(p => p.name?.toLowerCase().includes(e.target.value.toLowerCase()))
                                        if (newDept.projects.length > 0) {
                                            newDepartments?.push(newDept)
                                        }
                                }
                            }
                            setDepartments(newDepartments || null)
                        }}
                    >

                    </Form.Control>
                </div>
                <Button buttonType='white-shadowed' className='project-page-new-dept-button' onClick={e => {
                    e.preventDefault();
                    setNdf_visible(true);
                }}>
                    <div className='project-page-new-dept-button-content'>
                        <img style={{ marginRight: "5px"}} src={icons.ADD} width="30" height="30"></img>
                        <label>Новый отдел</label>
                    </div>
                </Button>
            </div>
            {(departments?.length === 0 ) ? 
                <div className='no-project-error'>На данный момент отделов нет.</div> 
                :
                <DepartmentList departments={departments || []} setSelectedDepartmentID={setSelectedDepartmentID} setNpf_visible={setNpf_visible}/>
            }
            <Dialog header="Новый отдел" visible={ndf_visible} className='new-department'  onHide={() => {
                if (!ndf_visible) return;
                setNdf_visible(false);
            }}>
                <div className='new-dapartment-form-container'>
                    <Input
                        label="Название отдела"
                        placeholder="Введите название отдела"
                        onChange={e => setDepartmentName(e.target.value)}
                    />
                    <div>
                        <Button buttonType="white-shadowed" style={{ marginRight: "10px" }} onClick={() => {
                            setSelectedOptions(null);
                            setNdf_visible(false);
                        }}>Отмена</Button>
                        <Button buttonType="white-shadowed" onClick={() => {
                            createDepartment();
                            setNdf_visible(false);
                        }}>Создать отдел
                        </Button>
                    </div>
                </div>
            </Dialog>
            <Dialog header="Новый проект" visible={npf_visible} className='new-project' onHide={() => {
                if (!npf_visible) return;
                setNpf_visible(false);
            }}>
                <div className='new-project-form-container' style={{ marginTop: "5px"}}>
                    <div style={{ marginBottom: "20px"}}>
                    <Input
                        label='Название проекта'
                        placeholder="Введите название проекта"
                        onChange={e => setProjectName(e.target.value)}
                    />
                    </div>
                    <div style={{ marginBottom: "20px"}}>
                    <Input
                                label="Планируемая дата завершения"
                                placeholder='Планируемая дата завершения'
                                type="date"
                                onChange={(e) => setFinishDate(e.target.value)}
                    />
                    </div>
                    <Select
                        closeMenuOnSelect={false}
                        components={makeAnimated()}
                        styles={selectStyles}
                        placeholder="Выберите участников проекта"
                        noOptionsMessage={() => "Пользователей не найдено"}
                        defaultValue={selectedOptions}
                        onChange={setSelectedOptions}
                        isMulti
                        options={selOptions}
                    />
                    <p>Описание</p>
                    <textarea placeholder="Введите описание проекта" name="NewProjectDescription" rows={6}
                            className="form-control new-project-description-textarea new-project-form"/>
                    <div className='new-project-buttons'>
                        <Button buttonType="white-shadowed" style={{ marginRight: "10px"}} onClick={(e) => {
                            e.preventDefault()
                            setSelectedOptions(null);
                            setNpf_visible(false);
                        }}>Отмена</Button>
                        <Button buttonType="white-shadowed" className="project-page-new-proj-button" onClick={(e) => {
                            e.preventDefault()
                            if (selectedOptions && selectedOptions.length > 0) {
                                const selectedUsers: UserToAdd[] = [];
                                selectedOptions.forEach((option) => {
                                    selectedUsers.push({ID: option.value});
                                });
                                createProject(selectedUsers).then();
                            }
                            setNpf_visible(false);
                        }}>Создать проект
                        </Button>
                    </div>
                </div>
            </Dialog>
        </FieldRoot>
    );
};

export default ProjectsPage;