import React from 'react';
import {Project} from "../../../entities/project";
import { ProgressBar } from 'primereact/progressbar';
import {Card} from "primereact/card";
import {Ripple} from "primereact/ripple";
import {Tag} from "primereact/tag";
import {Divider} from "primereact/divider";
import {useNavigate} from "react-router-dom";
import './ProjectItem.css'
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/bootstrap4-dark-purple/theme.css';

interface ProjectProps {
    project: Project
    departmentID: number
    departmentName: string
}

const ProjectComponent: React.FC<ProjectProps> = (props) => {
    const navigate = useNavigate();
    let finish_date;
    if (!props.project.finish_date) {
        finish_date = "";
    } else {
        finish_date = props.project.finish_date;
    }

    return (
        <div className="p-ripple" style={{marginBottom: "10px"}} onClick={e => {
            e.preventDefault();
            navigate(`/projects/${props.project.ID}/${props.departmentName}`);
        }}>
            <Card className="project">
                <div style={{display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between"}}>
                    <div>
                        <Tag style={{fontSize: "medium", borderRadius: "3px"}}
                             value={`${props.departmentID}.${props.project.ID}`}/>
                        <label style={{marginLeft: "10px", fontSize: "large"}}>{props.project.name}</label>
                    </div>

                    <div style={{display: "flex", alignItems: "center", flexDirection: "row"}}>
                        <label>Планируемая дата завершения: {finish_date.slice(0, 10)}</label>
                        <Divider layout="vertical"/>
                        <ProgressBar value={50} style={{minWidth: "200px", marginRight: "5px"}}/>
                    </div>
                </div>
            </Card>
            <Ripple
                pt={{
                    root: {style: {background: 'rgba(255, 193, 6, 0.3)' } }
                }}
            />
        </div>

        // <div className="project" >
        //     <div style={{ width: 50, height: 50 }}>
        //         <CircularProgressbar value={props.project.progress} text={`${props.project.progress}%`} styles={ buildStyles({
        //             textColor: '#F65727',
        //             pathColor: '#F65727',
        //             textSize: '30px'
        //         })}/>
        //     </div>
        //     <label className="project-id">{props.departmentID}.{props.project.ID}</label>
        //     <div className="project-link" onClick={(e) => {
        //         e.preventDefault()
        //         navigate("/projects/" + props.project.ID);
        //     }} >
        //         <label className="project-name">{props.project.name}</label>
        //         <div className="project-info">
        //             <label className="info-label">Дата завершения: {finish_date.slice(0, 10)}</label>
        //             <button type="button" role="button" className="btn btn-primary btn-sm finish-project-button"
        //                     style={{marginRight: 10}}>Завершить
        //             </button>
        //
        //         </div>
        //     </div>
        // </div>
    );
};

export default ProjectComponent;