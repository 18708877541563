import styled from 'styled-components'
import React, { useId, useRef, useState } from 'react'
import './Input.css'
interface Props {
    placeholder: string
    label?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    className?: string
    style?: {}
    type?: string
    defaultValue?: string
}
export const Input = (props: Props) => {
    const input = useRef<HTMLInputElement>(null)
    const [isFocused, setIsFocused] = useState(false)
    const id = useId();
    if (props.type && props.type === 'search') {
        return (
            <>
                <div onClick={() => input?.current?.focus()}>
                    <input 
                        ref={input}
                        id={id}
                        onChange={props.onChange}
                        style={{ }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        defaultValue={props.defaultValue}
                        className={`floating-search form-control ${props.className}`}
                    >
                    </input>
                    <label htmlFor={id} className={`floating-search-label ${(input && (input?.current?.defaultValue && input?.current?.value || input.current?.type == "date")) ? 'active' : ''}`}>{(isFocused === true || (input && input?.current?.value)) ? props.label : props.placeholder }</label>
                </div>
            </> 
        )
    }
    if (props.type && props.type === 'date') {
        return (
            <>
            <div onClick={() => input?.current?.focus()}>
                <input 
                    id={id}
                    onChange={props.onChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    type="date"
                    defaultValue={props.defaultValue}
                    className={`floating-input form-control ${props.className}`}
                >
                </input>
                <label htmlFor={id} className={`floating-label active`}>{(isFocused === true || (input && (input?.current?.value || input?.current?.defaultValue))) ? props.label : props.placeholder }</label>
            </div>
        </>
        )
    }
    return (
        <>
            <div onClick={() => {if (!isFocused && input) { input?.current?.focus()}}}>
            <input 
                    ref={input}
                    id={id}
                    onChange={props.onChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    defaultValue={props.defaultValue}
                    className={`floating-input form-control ${props.className}`}
            >
            </input>
                <label htmlFor={id}  className={`floating-label ${(input.current && input.current.value ) ? 'active' : ''}`}>{(isFocused === true || (input && input.current?.value)) ? props.label : props.placeholder }</label>
            </div>
        </>
    )
}