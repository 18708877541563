import React, {ComponentType, useState} from "react";
import {Operation, OperationType} from "../../../entities/operation";
import './NewOperationsForm.css'
interface NewOperationsFormProps {
    operations: Operation[];
    setOperations: (newOperations: Operation[]) => void;
}
const NewOperationsForm: ComponentType<NewOperationsFormProps> = (props) => {

    const [isChange, setIsChange] = useState(false);

    const onOperationsTypeChange = async (id: number, type: string) => {
        const newOperations = props.operations
        newOperations[id].type = type
        setIsChange(!isChange)
        props.setOperations(newOperations)

    }
    const onOperationsAddressChange = async (id: number, address: string) => {
        const newOperations = props.operations
        newOperations[id].address = address
        setIsChange(!isChange)
        props.setOperations(newOperations)

    }
    const onOperationsNumberChange = async (id: number, number: string) => {
        const newOperations = props.operations
        newOperations[id].number = number
        setIsChange(!isChange)
        props.setOperations(newOperations)

    }
    const handleOperationAdd = async (e: React.FormEvent) => {
        e.preventDefault();
        const newOperation: Operation = {
            ID: null,
            type: "",
            number: "",
            address: "",
            status: false
        };
        props.setOperations([...props.operations, newOperation])
    }

    const handleOperationDelete = async (index: number) => {
        props.setOperations(props.operations.filter((item, i) => i !== index));

    }
    return (
        <>

                <button className="w-100 mb-3 btn btn-lg add-operation-button" type="button" onClick={handleOperationAdd}>Добавить операцию</button>
                {props.operations.map((operation, index) => (
                    <div className="operations-form">
                        <div className=" operations-form mb-2" key={operation.type}>
                            <p style={{color: "white"}}>Тип: </p>
                            <select
                                id={`operation-${operation.type}`}
                                className="form-control"
                                value={operation.type}
                                onChange={(e) => onOperationsTypeChange(index, e.target.value)}
                                required
                            >
                                <option value="" disabled>Выберите тип операции</option>
                                {Object.values(OperationType).map((operationType) => (
                                    <option key={operationType} value={operationType}>{operationType}</option>
                                ))}
                            </select>
                        </div>
                        <div className="operations-form mb-2">
                            <p style={{color: "white"}}>Адрес: </p>
                            <input
                                id="OperationAdress"
                                className="form-control"
                                value={operation.address}
                                onChange={(e) => onOperationsAddressChange(index, e.target.value)}
                                required
                            />
                        </div>
                        <div className="operations-form mb-2">
                            <p style={{color: "white"}}>Номер: </p>
                            <input
                                id="OperationNUmber"
                                className="form-control"
                                value={operation.number}
                                onChange={(e) => onOperationsNumberChange(index, e.target.value)}
                                required
                            />
                        </div>
                        <button className="mb-3 btn btn-lg delete-operation-button" type="button" key={index} onClick={(e) => {
                            e.preventDefault()
                            handleOperationDelete(index)
                        }}>
                            <img src="/delete.png" alt="ChatLogo" width="25" height="25"/>
                        </button>
                        <hr style={{border: "none", borderTop: "5px solid", color: "#F65727"}}/>
                    </div>
                ))}

        </>
    )
};

export default NewOperationsForm;