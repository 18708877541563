import React from 'react';
import { Assortment } from '../../../entities/assortment';
import AssortmentListItem from '../../ListItems/AssortmentListItem/AssortmentListItem';

interface AssortmentListProps {
    assortments: Assortment[]
}
const AssortmentList = (props : AssortmentListProps) => {
    return (
        <div>
            {Object.values(props.assortments).map((assortment) => (
                <AssortmentListItem assortment={assortment} />
            ))}
        </div>
    );
}

export default AssortmentList;