// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-content {
    display: flex;
    height: 120px;
    align-items: center;
    margin-left: 10px;
}

.detail-content-info h1{
    margin-bottom: 0px;
    color: var(--primary-text-color);
}

.detail-content-info hr{
    margin-top: 2px;
    margin-bottom: 4px;
    border-top: 2px solid #F65727;
    border-radius: 8px;
    opacity: 100;
    width: 120px;
}

.detail-content-info h3{
    margin-bottom: 5px;
    color: var(--base-grey-color);
}
.detail-list-item {
    background-color: #212529;
    border-radius: 5px;
    border: 0px solid;
    margin-bottom: 20px;
    height: 250px;
    width: 250px;
    margin-right: 20px; 
    padding: 20px;
}

.detail-list-items-status {
    display: flex;
    align-items:baseline;
}

.detail-list-items-status h3 {
    margin-right: 10px;
}
.detail-name {
    font-weight: 700;
    margin: 10px;
    text-decoration: underline;
}
.detail-info {
    display: inline-block;
    float: right;
    margin-top: 5px;
}

.detail-label {
    font-weight: 500;
    margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/ListItems/DetailsCountListItem/DetailsCountListItem.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,6BAA6B;AACjC;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,0BAA0B;AAC9B;AACA;IACI,qBAAqB;IACrB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".detail-content {\n    display: flex;\n    height: 120px;\n    align-items: center;\n    margin-left: 10px;\n}\n\n.detail-content-info h1{\n    margin-bottom: 0px;\n    color: var(--primary-text-color);\n}\n\n.detail-content-info hr{\n    margin-top: 2px;\n    margin-bottom: 4px;\n    border-top: 2px solid #F65727;\n    border-radius: 8px;\n    opacity: 100;\n    width: 120px;\n}\n\n.detail-content-info h3{\n    margin-bottom: 5px;\n    color: var(--base-grey-color);\n}\n.detail-list-item {\n    background-color: #212529;\n    border-radius: 5px;\n    border: 0px solid;\n    margin-bottom: 20px;\n    height: 250px;\n    width: 250px;\n    margin-right: 20px; \n    padding: 20px;\n}\n\n.detail-list-items-status {\n    display: flex;\n    align-items:baseline;\n}\n\n.detail-list-items-status h3 {\n    margin-right: 10px;\n}\n.detail-name {\n    font-weight: 700;\n    margin: 10px;\n    text-decoration: underline;\n}\n.detail-info {\n    display: inline-block;\n    float: right;\n    margin-top: 5px;\n}\n\n.detail-label {\n    font-weight: 500;\n    margin-right: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
