import React, {useEffect, useState} from "react";
import {instance} from "../../api/config";
import { DetailType } from "../../entities/detail_type";
import DetailTypeListItem from "../../widgets/ListItems/DetailTypeListItem/DetailTypeListItem";
import NewDetailTypeForm from "../../widgets/Forms/NewDetailTypeForm/NewDetailTypeForm";
import DeleteDetailTypeForm from "../../widgets/Forms/DeleteDetailTypeForm/DeleteDetailTypeForm";
import { Form } from "react-bootstrap";
import './DetailTypesPage.css'
import { Dialog } from "primereact/dialog";
import DetailTypesList from "../../widgets/Lists/DetailTypesList/DetailTypesList";
import { Button } from "../../components/Button/Button";
import { UseIcons } from "../../hooks/UseIcons";
const DetailTypesPage = () => {

    const icons = UseIcons()
    const [detailTypes, setDetailTypes] = useState<DetailType[]>([])
    const [allDetailTypes, setAllDetailTypes] = useState<DetailType[]>([])
    const [isAdd, setIsAdd] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const [isDelete, setIsDelete] = useState(false);
    const [deleteDetailType, setDeleteDetailType] = useState<DetailType>()

    const handleNewDetail = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsAdd(true);
        setIsChanged(!isChanged);
    }

    useEffect(() => {
        const getAllDetailTypes = async () => {
            const resp = await instance.get("/api/detailTypes/all")
            setDetailTypes(resp.data)
            setAllDetailTypes(resp.data)
        }
        getAllDetailTypes()
    }, []);
    if (detailTypes === undefined) {
        return (
            <> Ошибка, обратитесь к администратору</>
        )
    }
    return (
        <div className="detail-types-page">
            <div className="detail-types-header">
                <h1 className="detail-types-title">Конструкторская документация</h1>
                <div className="detail-types-search">
                    <Form.Control
                        className="form-control detail-types-search-form"
                        placeholder="Поиск по паспортам деталей"
                        onChange={(e) => {
                            const newDetailType = allDetailTypes.filter(dt => dt.name?.toLowerCase().includes(e.target.value.toLowerCase()) || dt.number?.toLowerCase().includes(e.target.value.toLowerCase()))
                            setDetailTypes(newDetailType)
                        }}
                    >
                 
                    </Form.Control>
                </div>
                <Button
                    className='detail-types-button'
                    buttonType="white-shadowed"
                    type="button"
                    onClick={handleNewDetail}
                >
                    <div className="detail-types-button-content">
                        <img style={{ marginRight: "5px"}}  src={icons.ADD} width="30" height="30"></img>
                        Новая Деталь
                    </div>
                </Button>
            </div>
        
            <DetailTypesList detailTypes={detailTypes} />
            <Dialog header="Новая деталь" visible={isAdd} className="new-detail-type-form" onHide={() => {
                if (!isAdd) { 
                    return; 
                }
                setIsAdd(false); }}>
                <div>
                    <NewDetailTypeForm
                    isChanged={isChanged}
                    setIsChanged={setIsChanged}
                    setIsAdd={setIsAdd}
                    />
                </div>
            </Dialog>
            {(isDelete) ?
                <DeleteDetailTypeForm
                IsChanged={isChanged}
                setIsChanged={setIsChanged}
                setIsDelete={setIsDelete}
                setDeleteDetailType={setDeleteDetailType}
                deleteDetailType={deleteDetailType}
                /> : <div></div>
            }
        </div>
    );
};

export default DetailTypesPage;