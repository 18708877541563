export interface Roles {
    [id: number] : string
}

export const Roles: Roles = {
    0: "Исполнитель",
    1: "Администратор",
    2: "Диспетчер",
    3: "Ведущий конструктор",
    4: "Директор",
}