// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storage-list-item {
    border: 2px solid transparent;
    height: 120px;
    padding: 20px;
    padding-left: 40px;
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
    margin-bottom: 10px !important;
    align-items: center !important;
}

.storage-list-item:hover {
    background: linear-gradient(var(--base-button-color), var(--base-button-color) 0) padding-box,
    linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;
    box-shadow: 0 0 10px var(--all-button-shadow-box-color) !important;
  }
.storage-list-item:active {
    background: linear-gradient(var(--base-button-color-active), var(--base-button-color-active) 0) padding-box,
    linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;
    box-shadow: 0 0 10px var(--all-button-shadow-box-color) !important;
  }

.storage-list-item-header {
    margin-left: 10px;
    margin-right: 10px; 
    height: max-content; 
    align-content: center; 
}

.storage-list-item-header h1 {
    color: var(--primary-text-color);
}

.storage-list-item-header h3 {
    color: var(--base-grey-color);
}

.storage-list-item-detail {
    align-items: center;
    padding: 10px;
    display: flex;
    width: auto;
}

.storage-list-item-detail-progress-bar {
    height: 60px;
    width: 60px;
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/ListItems/StorageListItem/StorageListItem.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,8BAA8B;IAC9B,8BAA8B;IAC9B,8BAA8B;AAClC;;AAEA;IACI;6DACyD;IACzD,kEAAkE;EACpE;AACF;IACI;6DACyD;IACzD,kEAAkE;EACpE;;AAEF;IACI,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".storage-list-item {\n    border: 2px solid transparent;\n    height: 120px;\n    padding: 20px;\n    padding-left: 40px;\n    display: flex;\n    border-radius: 8px;\n    justify-content: space-between;\n    margin-bottom: 10px !important;\n    align-items: center !important;\n}\n\n.storage-list-item:hover {\n    background: linear-gradient(var(--base-button-color), var(--base-button-color) 0) padding-box,\n    linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;\n    box-shadow: 0 0 10px var(--all-button-shadow-box-color) !important;\n  }\n.storage-list-item:active {\n    background: linear-gradient(var(--base-button-color-active), var(--base-button-color-active) 0) padding-box,\n    linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;\n    box-shadow: 0 0 10px var(--all-button-shadow-box-color) !important;\n  }\n\n.storage-list-item-header {\n    margin-left: 10px;\n    margin-right: 10px; \n    height: max-content; \n    align-content: center; \n}\n\n.storage-list-item-header h1 {\n    color: var(--primary-text-color);\n}\n\n.storage-list-item-header h3 {\n    color: var(--base-grey-color);\n}\n\n.storage-list-item-detail {\n    align-items: center;\n    padding: 10px;\n    display: flex;\n    width: auto;\n}\n\n.storage-list-item-detail-progress-bar {\n    height: 60px;\n    width: 60px;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
