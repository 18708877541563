import React from "react";
import Form from "react-bootstrap/Form";
import {TaskType} from "../../entities/task";
import {StorageTypes} from "../../entities/storage";
import {useNavigate} from "react-router-dom";
import {instance} from "../../api/config";
import {toast} from "react-hot-toast";

const NewStorageForm: React.FC = () => {

    const navigate = useNavigate();

    const [name, setName] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [storageType, setStorageType] = React.useState<string>("");

    const handleCreateStorage = async (e: React.FormEvent) => {
        e.preventDefault();
        const resp = await instance.post("/api/storage", {
            "name": name,
            "address": address,
            "storage_type": storageType,
        })
        setName("")
        setAddress("")
        setStorageType("")
        navigate("/storages")
        toast("Склад успешно создан!")
    }
    return (
        <div style={{ width: "30%" }} className="m-2">
            <div className="mb-3">
                <p style={{color: "white"}}>Тип склада</p>
                <Form.Select aria-label="Task Type"
                             id="taskType"
                             className="form-control"
                             value={storageType}
                             onChange={(e) => setStorageType(e.target.value)}
                             required
                >
                    <option value="" disabled>Выберите тип склада</option>
                    {Object.values(StorageTypes).map((storageType) => (
                        <option key={storageType} value={storageType}>{storageType}</option>
                    ))}
                </Form.Select>
            </div>
            <div className="mb-3">
                <p style={{color: "white"}}>Название склада</p>
                <Form.Control aria-label="Finish Date"
                              id="taskFinishDate"
                              className="form-control"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                />
            </div>
            <div className="mb-3">
                <p style={{color: "white"}}>Адрес склада</p>
                <Form.Control aria-label="Finish Date"
                              id="taskFinishDate"
                              className="form-control"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              required
                />
            </div>
            <div style={{ display: "flex", width: "40%" }}>
                <button className="add-task-button w-90 btn btn-lg btn-primary" style={{ marginRight: "10px"}} onClick={handleCreateStorage}>Создать склад</button>
                <button className="add-task-button w-90 btn btn-lg btn-primary" onClick={(e) => {
                    e.preventDefault()
                    navigate("/storages")
                }}>Отмена</button>
            </div>
        </div>
    )
}

export default NewStorageForm;