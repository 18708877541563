import Form from "react-bootstrap/Form";
import React from "react";
import {Operation} from "../../../entities/operation";
import {Detail} from "../../../entities/detail";
import './OperationDetailTypeInfo.css'
interface OperationTaskInfoProps {
    operations: Operation[];
}

const OperationDetailTypeInfo = (props: OperationTaskInfoProps) => {

    return (
        <div className="operations-info-item" style={{ width: "100%" }}>
            <h3 style={{color: "white"}}>Список операций: </h3>
            {props.operations?.map((operation, index) => (
                <div className="operations-container">
                    <div>
                        <h3 style={{color: "white"}}>Тип: {operation.type}</h3>
                        <h3 style={{color: "white"}}>Адрес: {operation.address}</h3>
                        <h3 style={{color: "white"}}>Номер: {operation.number} </h3>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default OperationDetailTypeInfo;