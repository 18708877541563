// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (-webkit-min-device-pixel-ratio:0) {

    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: #F65727;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Task/GeneralTaskInfo/GeneralTaskInfo.css"],"names":[],"mappings":"AAAA;;IAEI;QACI,wBAAwB;QACxB,mBAAmB;IACvB;AACJ","sourcesContent":["@media screen and (-webkit-min-device-pixel-ratio:0) {\n\n    input[type='range']::-webkit-slider-thumb {\n        -webkit-appearance: none;\n        background: #F65727;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
