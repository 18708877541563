import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { User } from '../../entities/user';
import { instance } from '../../api/config';
import { Form } from 'react-bootstrap';
import { Username } from '../../components/Username/Username';
import { Roles } from '../../schemas/Roles';
import { UseIcons } from '../../hooks/UseIcons';
import UsersList from '../../widgets/Lists/UsersList/UsersList';
import { Button } from '../../components/Button/Button';
import RegistrationForm from '../../widgets/Forms/RegistrationForm/RegistrationForm';
import { WorkPlace } from '../../entities/workplaces';
import WorkPlacesList from '../../widgets/Lists/WorkPlacesList/WorkPlacesList';
import NewWorkPlaceForm from '../../widgets/Forms/NewWorkPlaceForm/NewWorkPlaceForm';
import './AdminPage.css'
const AdminPage = () => {

    const icons = UseIcons()

    const [users, setUsers] = useState<User[]>([])
    const [allUsers, setAllUsers] = useState<User[]>([])

    const [workPlaces, setWorkPlaces] = useState<WorkPlace[]>([])
    const [allWorkPlaces, setAllWorkPlaces] = useState<WorkPlace[]>([])
    
    const [isAdd, setIsAdd] = useState(false)
    const [tabSwitch, setTabSwitch] = useState("Пользователи")


    useEffect(() => {
        
        const GetWorkPlaces = async () => {
            const response = await instance.get('/api/workPlaces/all')
            setWorkPlaces(response.data)
            setAllWorkPlaces(response.data)
        }
        const getUsers = async () => {
            const resp = await instance.get("/api/users/all")
            setUsers(resp.data)
            setAllUsers(resp.data)
        }
        GetWorkPlaces()
        getUsers()
    }, [isAdd])

    const hasOperationType = (workplace: WorkPlace, type: string) : boolean => {
        
        let counter: number = 0;
        workplace.operation_types.forEach(operation => {
            if (operation.operation_type.toLowerCase().includes(type)) {
                counter++
            }
        })
        if (counter > 0) {
            return true
        }
        return false

        
        
    }
    return (
        <div className='admin-page'>
            <div className='admin-page-header'>
                <h1 className='admin-page-title'>Администрирование</h1>
                {(tabSwitch == "Пользователи") ? 
                    <div className='admin-page-search'>
                        <Form.Control
                            placeholder='Поиск по пользователям'
                            className='admin-page-search-form'
                            onChange={(e) => {
                                const newUsers = allUsers.filter(user => user.username?.includes(e.target.value) || ((user.name || "")+(user.surname || "")).includes(e.target.value) || Roles[user?.role || 0].includes(e.target.value))
                                setUsers(newUsers)
                            }}
                        >
                        </Form.Control>
                    </div>
                : (tabSwitch == "Рабочие места") ? 
                    <div className='admin-page-search'>
                        <Form.Control
                            className='admin-page-search-form'
                            placeholder='Поиск по рабочим местам'
                            onChange={(e) => {
                                const newWorkplaces = allWorkPlaces?.filter(workplace => workplace.name?.toLowerCase().includes(e.target.value.toLowerCase()) || workplace.address?.toLowerCase().includes(e.target.value.toLowerCase()) || hasOperationType(workplace ,e.target.value.toLowerCase()))
                                setWorkPlaces(newWorkplaces || null)
                            }}
                        >
                        </Form.Control>
                    </div>    
                : <></> }
                <div>
                    <div style={{ display: "flex", marginBottom: "5px"}}>
                        <Button 
                            className={`${(tabSwitch === "Пользователи")? "active focus": ""} admin-page-switch-left`}
                            onClick={(e) => setTabSwitch("Пользователи")}
                            style={{ backgroundColor: tabSwitch === "Пользователи" ? "var(--base-button-color-active)" : "var(--base-button-color)" }}>
                            Пользователи
                        </Button>
                        <Button 
                            className={`${(tabSwitch === "Рабочие места")? "active focus": ""} admin-page-switch-right`} 
                            onClick={(e) => setTabSwitch("Рабочие места")}
                            style={{ backgroundColor: tabSwitch === "Рабочие места" ? "var(--base-button-color-active)" : "var(--base-button-color)" }}>
                            Рабочие места
                        </Button>
                    </div>
                    {(tabSwitch == "Пользователи") ?
                        <Button buttonType="white-shadowed" className='admin-page-button ' onClick={() => {setIsAdd(true)}}>
                            <div className="admin-page-button-content" style={{ display: 'flex', alignContent: 'center'}}>
                                <img style={{ marginRight: "5px"}} src={icons.ADD} width="30" height="30"></img>
                                <div style={{ alignItems: "center"}}>
                                Зарегистрировать пользователя
                                </div>
                            </div>
                        </Button> : 
                    (tabSwitch == "Рабочие места") ? 
                        <Button buttonType="white-shadowed" className='admin-page-button' onClick={() => {setIsAdd(true)}}>
                            <div className="admin-page-button-content" style={{ display: 'flex', alignContent: 'center'}}>
                                <img style={{ marginRight: "5px"}} src={icons.ADD} width="30" height="30"></img>
                                <div style={{ alignItems: "center"}}>
                                Новое рабочее место
                                </div>
                            </div>
                        </Button> 
                        : <></>
                    }
                </div>
            </div>
            {(tabSwitch == "Пользователи") ?
                <UsersList users={users}/> : 
            (tabSwitch == "Рабочие места") ?
                <WorkPlacesList workplaces={workPlaces || []}/> : <></> 
            }
            {(tabSwitch == "Пользователи") ?
                <Dialog header="Новый сотрудник" visible={isAdd} className="admin-page-form" onHide={() => {
                    if (!isAdd) return;
                        setIsAdd(false); }}>
                    <RegistrationForm setIsAdd={setIsAdd}/>
                </Dialog > : 
            (tabSwitch == "Рабочие места") ? 
                <Dialog header="Новое рабочее место" visible={isAdd} className='admin-page-form' onHide={() => {
                    if (!isAdd) return;
                    setIsAdd(false);
                }}>
                    <NewWorkPlaceForm setAddFormActivated={setIsAdd} />
                </Dialog> : <></> 
            }
        </div>
    )
}

export default AdminPage;