import React, {useState} from 'react';
import {NavItem} from "../schemas/NavigationBar";
import {User} from "../entities/user";
import '../css/Tasks.css'
import {Roles} from "../schemas/Roles";
import {useAuth} from "../context/AuthProvider";
import {useNavigate} from "react-router-dom";
interface NavigationBarProps {
    navItems: NavItem[];
    user: User;
    children: React.ReactNode;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ navItems, user, children }) => {
    const {logout, } = useAuth()
    const navigate = useNavigate();
    const handleLogout = async () => {
        logout()
    };
    if (user.role === null ) {
        navigate("/login")
        return (
            <></>
        )
    }
    return (
        <div>
            <ul className="nav nav-pills flex-column flex-sm-row">
                <li className="nav-item" key="0" style={{marginLeft: '10px'}}>
                    <a className="text-sm-center nav-link" href='https://www.imzrb.ru/'>
                        <img src='/brand.svg' alt='IMZboxlogo' width="50" height="50"/>
                    </a>
                </li>
                <li className="nav-item" key="1" style={{marginLeft: '10px'}}>
                    <button
                        className={`nav-link active`}
                        id={`tasks-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#tasks-tab-pane`}
                        type="button"
                        role="tab"
                        aria-controls={`tasks-tab-pane`}
                        aria-selected="true"
                        onClick={(e) => {
                            navigate(`/tasks`)
                        }}
                    >
                        Мои задачи
                    </button>
                </li>
                {navItems.map((item, index) => {
                    if (item.type === 'dropdown') {
                        return (
                            <li className="nav-item dropdown" key={index} style={{marginLeft: '10px'}}>
                                <button
                                    className="nav-link dropdown-toggle active"
                                    id={`${item.label}-tab`}
                                    data-bs-toggle="dropdown"
                                    type="button"
                                    role="tab"
                                    aria-controls={`${item.label}-tab-pane`}
                                >
                                    {item.label}
                                </button>
                                <ul className="dropdown-menu">
                                    {item.items?.map((dropdownItem, dropdownIndex) => (
                                        <li key={dropdownIndex}>
                                            {/*<a className="dropdown-item" href={dropdownItem.href}>*/}
                                            {/*    {dropdownItem.label}*/}
                                            {/*</a>*/}
                                            <button className="add-task-button w-30 mb-2 btn btn-lg btn-primary"
                                                    onClick={() => {
                                                        navigate(dropdownItem.route)
                                                    }}>{dropdownItem.label}
                                            </button>
                                        </li>
                                    ))}
                                    <li>
                                        <hr className="dropdown-divider"/>
                                    </li>
                                </ul>
                            </li>
                        );
                    }
                    return null;
                })}
                <li className="nav-item" key="1" style={{marginLeft: '10px'}}>
                    <button
                        className={`nav-link active`}
                        id={`notebook-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#notebook-tab-pane`}
                        type="button"
                        role="tab"
                        aria-controls={`notebook-tab-pane`}
                        aria-selected="true"
                        onClick={(e) => {
                            navigate(`/notebook`)
                        }}
                    >
                        Блокнот
                    </button>
                </li>
                {(user.role === 1)?
                    <li className="nav-item" key="1" style={{marginLeft: '10px'}}>
                        <button
                        className={`nav-link active`}
                        id={`notebook-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#notebook-tab-pane`}
                        type="button"
                        role="tab"
                        aria-controls={`notebook-tab-pane`}
                        aria-selected="true"
                        onClick={(e) => {
                            navigate(`/details`)
                        }}>
                        Детали
                        </button>
                    </li>
                    : <></>}
                <ul className="navbar-nav me-auto mb-lg-0"></ul>
                <li className="nav-item">
                    <div className="user-box">
                        <form className="d-flex">
                            <label id="user_label">
                                {(user.name && user.surname) ? `${user.name} ${user.surname}` : user.username}
                                <br/>
                                {Roles[user.role]}
                            </label>
                            <img src='/user.png' alt='avatar' width='50' height='50' id="avatar_icon" onClick={(e) => navigate(`/profile`)} />
                            <li className="nav-item" style={{marginLeft: '10px'}}>
                            <button
                                    className='nav-link active'
                                    data-bs-toggle="tab"
                                    data-bs-target={`#Выход-tab-pane`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`Выход-tab-pane`}
                                    aria-selected='true'
                                    onClick={handleLogout}
                                >
                                    Выход
                                </button>
                            </li>
                        </form>

                    </div>
                </li>

            </ul>
            {children}
        </div>
    );
};

export default NavigationBar;