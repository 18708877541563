import React from 'react';
import './DetailStatus.css'
import '../DetailCountListItem.css'
interface DetailStatusProps {
    status: string
}
const DetailStatus = (props: DetailStatusProps) => {
    return (
        <label className="detail-label">
            <div className={`status ${(props.status === "На изготовлении") ? "status-manufacturing" :
                (props.status === "На контроле качества") ? "status-quality" : (props.status === "В пути на склад") ? "status-storage" : (props.status === "Выполнено") ? "status-done" : "status-manufacturing"}`}>
                {props.status}

            </div>
        </label>
    )
}

export default DetailStatus;