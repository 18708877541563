// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-primary {

}
.note-box {
    border: solid 1px #F65727;
    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727;
    width: 400px;
    height: 250px;
}
.noteHeadline {
    color: #F65727;
}
.notepadGroup {
    margin: 15px;
    display: flex;
}

.notepad-button {
    background-color: #F65727;
    color: #212529;

}
.notepad-button:hover {
    background-color: #F65727;
    color: #1d2124 !important;
    border: solid 1px #F65727 !important;
    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727 !important;
}

.notepad-button:active {
    background-color: #212529;
    color: #F65727;

}`, "",{"version":3,"sources":["webpack://./src/css/Notepd.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;IACI,yBAAyB;IACzB,mDAAmD;IACnD,YAAY;IACZ,aAAa;AACjB;AACA;IACI,cAAc;AAClB;AACA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,cAAc;;AAElB;AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,oCAAoC;IACpC,8DAA8D;AAClE;;AAEA;IACI,yBAAyB;IACzB,cAAc;;AAElB","sourcesContent":[".btn-primary {\n\n}\n.note-box {\n    border: solid 1px #F65727;\n    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727;\n    width: 400px;\n    height: 250px;\n}\n.noteHeadline {\n    color: #F65727;\n}\n.notepadGroup {\n    margin: 15px;\n    display: flex;\n}\n\n.notepad-button {\n    background-color: #F65727;\n    color: #212529;\n\n}\n.notepad-button:hover {\n    background-color: #F65727;\n    color: #1d2124 !important;\n    border: solid 1px #F65727 !important;\n    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727 !important;\n}\n\n.notepad-button:active {\n    background-color: #212529;\n    color: #F65727;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
