import React, {useEffect} from 'react';
import {DetailType} from "../../entities/detail_type";
import OperationDetailTypeInfo from "./OperationDetailTypeInfo/OperationDetailTypeInfo";
import {instance} from "../../api/config";
import {NewShortStorage, ShortStorage} from "../../entities/storage";
import GeneralDetailTypeInfo from "./GeneralDetailTypeInfo/GeneralDetailTypeInfo";

interface DetailTypeComponentProps {
    detailType: DetailType
}

const DetailTypeComponent = (props: DetailTypeComponentProps) => {


    return (
        <div style={{ display: 'flex', width: "100%" }}>
            <div style={{ width: "50%" }}>
                <GeneralDetailTypeInfo name={props.detailType?.name || ""} number={props.detailType?.number || ""} storage={props.detailType.route_list.storage}/>
            </div>
            <div style={{ width: "50%" }}>
                <OperationDetailTypeInfo operations={props.detailType.route_list?.operations || []}/>
            </div>
        </div>
    )
}

export default DetailTypeComponent;