// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-list-item {
    margin-bottom: 20px;
}

.task-list-content {
    display: flex;
    border-radius: 8px;
    border: 2px solid transparent;
}

.task-list-content:hover {
    background: linear-gradient(var(--base-button-color), var(--base-button-color) 0) padding-box,
    linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;
    box-shadow: 0 0 10px var(--all-button-shadow-box-color) !important;
  }
.task-list-content:active {
    background: linear-gradient(var(--base-button-color-active), var(--base-button-color-active) 0) padding-box,
    linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;
    box-shadow: 0 0 10px var(--all-button-shadow-box-color) !important;
  }

.task-list-item-progress-bar {
    height: 120px;
    width: 120px;
    padding: 25px;
}

.task-list-item-info {
    height: 120px;
    padding: 15px;
    padding-left: 0;
}

.task-list-item-info h1 {
    margin-bottom: 0px;
    color: var(--primary-text-color);
}

.task-list-item-info h3 {
    margin-bottom: 0px;
    color: var(--base-grey-color);
}

.task-list-item-info hr {
    margin-top: 2px;
    margin-bottom: 4px;
    border-top: 2px solid #F65727;
    border-radius: 8px;
    opacity: 100;
    width: 225px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/ListItems/TaskListItem/TaskListItem.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI;6DACyD;IACzD,kEAAkE;EACpE;AACF;IACI;6DACyD;IACzD,kEAAkE;EACpE;;AAEF;IACI,aAAa;IACb,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".task-list-item {\n    margin-bottom: 20px;\n}\n\n.task-list-content {\n    display: flex;\n    border-radius: 8px;\n    border: 2px solid transparent;\n}\n\n.task-list-content:hover {\n    background: linear-gradient(var(--base-button-color), var(--base-button-color) 0) padding-box,\n    linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;\n    box-shadow: 0 0 10px var(--all-button-shadow-box-color) !important;\n  }\n.task-list-content:active {\n    background: linear-gradient(var(--base-button-color-active), var(--base-button-color-active) 0) padding-box,\n    linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;\n    box-shadow: 0 0 10px var(--all-button-shadow-box-color) !important;\n  }\n\n.task-list-item-progress-bar {\n    height: 120px;\n    width: 120px;\n    padding: 25px;\n}\n\n.task-list-item-info {\n    height: 120px;\n    padding: 15px;\n    padding-left: 0;\n}\n\n.task-list-item-info h1 {\n    margin-bottom: 0px;\n    color: var(--primary-text-color);\n}\n\n.task-list-item-info h3 {\n    margin-bottom: 0px;\n    color: var(--base-grey-color);\n}\n\n.task-list-item-info hr {\n    margin-top: 2px;\n    margin-bottom: 4px;\n    border-top: 2px solid #F65727;\n    border-radius: 8px;\n    opacity: 100;\n    width: 225px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
