import React, { useState } from 'react';
import axios from 'axios';
import {instance} from "../api/config";
import NavigationBar from "../components/NavigationBar";
import {navItems} from "../schemas/NavigationBar";
import {useAuth} from "../context/AuthProvider";
import NewTaskForm from "../components/NewTaskForm/NewTaskForm";

const CreateTask = () => {
    const {user} = useAuth()


    if (user === null) {
        return (<div>Не авторизован</div>)
    }
    return (
        <div>
            <NewTaskForm/>
        </div>
    );
};

export default CreateTask;