import React, {useState} from 'react';
import ProgressBar from "react-bootstrap/ProgressBar";
import {instance} from "../../../api/config";
import {Detail} from "../../../entities/detail";

interface DetailProps {
    ID: number,
    name: string,
    number: string,
}

const DetailListItem: React.FC<DetailProps> = (props: DetailProps) => {

    return (
        <div>
            <div className="task" style={{ width: "100%" }} >
                <label className="task-name" style={{ margin: "0px !important"}}>{props.name}</label>
                <div className="task-info">
                    <label className="info-label">Обозначение: {props.number}</label>
                </div>

            </div>
        </div>
    )

}

export default DetailListItem