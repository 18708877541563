import styled from 'styled-components'

export const DefaultButton = styled.button

export const Button = styled.button<{buttonType?: string}>`
  ${props => (props.buttonType === "white-shadowed") ? 
  `
    background-color: var(--base-general-color);
    border: 02px solid transparent;
    color: var(--primary-text-color);
    box-shadow: 0 0.1em 0.30em  var(--base-grey-color);
    border-radius: 8px;
    cursor: pointer;
    height: 40px;
    font-size: 14px;
    align-items: center;
    &:hover {
      background: linear-gradient(var(--base-general-color), var(--base-general-color) 0) padding-box,
      linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;
      box-shadow: 0 0 10px var(--all-button-shadow-box-color) !important;
    }
    &:active {
      background: linear-gradient(var(--base-button-color-active), var(--base-button-color-active) 0) padding-box,
      linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;
      box-shadow: 0 0 10px var(--all-button-shadow-box-color) !important;
    }
  ` : (props.buttonType === "alert-button") ? 
  `
    background-color: var(--base-button-color-del);
    border: 0px solid;
    box-shadow: 0 0.1em 0.30em  var(--base-grey-color);
    color: var(--base-general-color);
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    cursor: pointer;
    height: 40px;
    font-size: 14px;
    align-items: center;
    
    &:hover {
      box-shadow: 0 0 10px var(--base-button-color-del) !important;
    }
    &:active {
      background-color: #a30000;
      box-shadow: 0 0 10px var(--base-button-color-del) !important;
    }  
  `
    :
  `
    background-color: var(--base-button-color);
    border: 2px solid transparent;
    box-shadow: 0 0 0 0.01em  var(--base-grey-color);
    color: var(--primary-text-color);
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    height: 40px;
    
    &:hover {
      background: linear-gradient(var(--base-button-color), var(--base-button-color) 0) padding-box,
      linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;
      box-shadow: 0 0 10px var(--all-button-shadow-box-color) !important;
    }
    &:active {
      background: linear-gradient(var(--base-button-color-active), var(--base-button-color-active) 0) padding-box,
      linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;
      box-shadow: 0 0 10px var(--all-button-shadow-box-color) !important;
    }
  `
  }
`

;

export const ButtonDropdown = styled.button`
  padding-left: 5%;
  font-size: 14px;
  width: 100%;
  min-height: 40px;
  min-width: 200px;
  box-sizing: border-box;
  text-align: left;
  background: #fcede880;
  border-top: transparent;
  border-bottom: transparent;
  border-left: none;
  border-right: none;
  border: 2px solid transparent;
  overflow: hidden;
  backdrop-filter: blur(2px);
  &:hover {
    background: linear-gradient(#c8c8c8, #c8c8c8 0) padding-box,
    linear-gradient(to right, var(--all-button-shadow-box-color), #f5cdc1) border-box;
    -webkit-mask: linear-gradient(#8080804d 0 0) padding-box, linear-gradient(#808080 0 0);
    mask-composite: exclude;
    }
  &:active{
    background: linear-gradient(var(--base-button-color-active), var(--base-button-color-active) 0) padding-box,
    linear-gradient(to right,#ff3c00cc, #fb8e6de6) border-box;
    /* box-shadow: inset 0 0 5px rgb(246, 87, 39), 0 0 15px var(--all-button-shadow-box-color); */
  }
`

export const TaskButton = styled.button`
  
`