import React, {useState} from "react";
import {UserResponse} from "../../entities/user";
import {Roles} from "../../schemas/Roles";
import ProjectComponent from "../DepartmentsList/ProjectItem/ProjectItem";
import './UserProfile.css'
import Form from "react-bootstrap/Form";
import {instance} from "../../api/config";
import {useAuth} from "../../context/AuthProvider";

interface UserProfileProps {
    user: UserResponse;
}
const UserProfile = (props: UserProfileProps) => {

    const { logout } = useAuth()

    const [isCredsChange, setIsCredsChange] = useState(false);

    const [email, setEmail] = useState(props.user.email);
    const [phone, setPhone] = useState(props.user.phone);
    const [telegram, setTelegram] = useState(props.user.telegram);

    const handleSave = async () => {
        const resp = await instance.patch("/api/users", {
            ID: props.user.ID,
            telegram: telegram,
            phone: phone,
            email: email,
        })

    }


    return (
        <div>

            <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                <div style={{color: "white", display: "flex", width: "70%"}} className="m-3">
                    <div style={{
                        backgroundColor: "#4b4b4e",
                        width: "60%",
                        padding: "1%",
                        borderRadius: "20px",
                        marginRight: "10px"
                    }}>
                        <div>
                            <img src='/user.png' alt='avatar' width='200' height='200' id="avatar_icon"/>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <h3>{(props.user.name && props.user.surname) ? `${props.user.name} ${props.user.surname}` : props.user.username}</h3>
                            <h3>{Roles[props.user.role || 0]}</h3>
                        </div>

                    </div>
                    <div style={{backgroundColor: "#4b4b4e", width: "40%", padding: "1%", borderRadius: "20px"}}>
                        {(isCredsChange) ?
                            <>
                                <button className="w-100 mb-3 btn btn-lg user-creds-button" onClick={(e) => {
                                    setIsCredsChange(false)
                                    handleSave()
                                }}>Сохранить
                                </button>
                                <div style={{display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                                    <h3 style={{marginBottom: "30px", width: "50%"}}>Почта: </h3>
                                    <Form.Control aria-label="User Email"
                                                  id="UserEmail"
                                                  className="form-control"
                                                  style={{width: "%50"}}
                                                  value={email || ""}
                                                  onChange={(e) => setEmail(e.target.value)}
                                                  required
                                    />
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                                    <h3 style={{marginBottom: "30px", width: "50%"}}>Телефон: </h3>
                                    <Form.Control aria-label="User Phone"
                                                  id="UserPhone"
                                                  className="form-control"
                                                  style={{width: "%50"}}
                                                  value={phone || ""}
                                                  onChange={(e) => setPhone(e.target.value)}
                                                  required
                                    />
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                                    <h3 style={{marginBottom: "30px", width: "50%"}}>Телеграм: </h3>
                                    <Form.Control aria-label="User Telegram"
                                                  id="UserTelegram"
                                                  className="form-control"
                                                  style={{width: "%50"}}
                                                  value={telegram || ""}
                                                  onChange={(e) => setTelegram(e.target.value)}
                                                  required
                                    />
                                </div>
                            </> :
                            <>
                                <button className="w-100 mb-3 btn btn-lg user-creds-button" onClick={(e) => {
                                    setIsCredsChange(true)
                                }}>Изменить
                                </button>
                                <h3 style={{marginBottom: "30px"}}>Почта: {email || "Не указано"}</h3>
                                <h3 style={{marginBottom: "30px"}}>Телефон: {phone || "Не указано"}</h3>
                                <h3 style={{marginBottom: "30px"}}>Телеграм: {telegram || "Не указано"}</h3>
                            </>
                        }

                    </div>
                </div>
                <div style={{color: "white", width: "70%", marginBottom: "10px"}}>
                    <h3>Проеты: </h3>
                </div>
                <div style={{color: "white", width: "70%"}}>
                    {Object.values(props.user?.projects || []).map((project, index) => (
                        <ProjectComponent project={project} departmentID={index}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default UserProfile;