import React, {useState} from 'react';
import './Task.css'
import {FullTaskResponse, TaskType} from "../../entities/task";
import {useNavigate} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import OperationDetailTypeInfo from "../DetailType/OperationDetailTypeInfo/OperationDetailTypeInfo";
import {NewDetail} from "../../entities/detail";
import GeneralTaskInfo from "./GeneralTaskInfo/GeneralTaskInfo";
import {Operation} from "../../entities/operation";
import {instance} from "../../api/config";
import axios, {AxiosError} from "axios";
import {toast} from 'react-hot-toast'
import ChildTasksInfo from "./ChildTasksInfo/ChildTasksInfo";
import {MantineProvider} from "@mantine/core";
import {useAuth} from "../../context/AuthProvider";
import {DetailType} from "../../entities/detail_type";
import TaskListItem from "../TaskListItem";

interface TaskProps {
    task: FullTaskResponse
}


const TaskComponent: React.FC<TaskProps> = (props: TaskProps) => {

    const { user } = useAuth()

    const navigate = useNavigate()

    const [progress, setProgress] = useState<number>(props.task.task.progress);
    const [operations, setOperation] = useState<Operation[]>(props.task?.operations || []);
    const [isDelete, setIsDelete] = useState(false);
    const [status, setStatus] = React.useState<boolean>(false);

    const handleSaveButtonClick = async () => {

        const PatchRequestData = {
            "ID": props.task.ID,
            "operations": operations,
            "progress": progress,
        }
        try {
            const resp = instance.patch("/api/tasks", PatchRequestData)

        } catch (err: AxiosError | any) {
            console.error(err)
        }
        toast("Задача успешно сохранена!")
    }

    const handleDeleteButtonClick = async (e: React.FormEvent) => {
        e.preventDefault()
        setIsDelete(true)
    }

    const handleClose = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsDelete(false)
    }

    const handleOPFinish = async (e: React.FormEvent) => {
        e.preventDefault()
        if (props.task.task.progress != 100) {
            toast("Нельзя завершить задачу, пока прогресс не равен 100%")
        }
        const resp = await instance.patch("/api/tasks", {
            ID: props.task.ID,
            status: true
            })

    }

    const handleStorageFinish = async (e: React.FormEvent) => {
        e.preventDefault()
    }

    const handleDelete = async (e: React.FormEvent) => {
        e.preventDefault();
        const resp = instance.delete("/api/tasks", { data: {
            "ID": props.task.ID,
            }})
        navigate("/tasks")
        toast("Задача успешно удалена!")

    }
    return (
        <div style={{ padding: "20px"}}>
            <div className="top-buttons-container">
                <button className="w-100 mb-3 btn btn-lg task-default-button" onClick={() => {
                    navigate('/tasks')

                }}>Назад к задачам
                </button>
                <button className="w-100 mb-3 btn btn-lg task-default-button"
                        onClick={handleSaveButtonClick}>Сохранить
                </button>
                {(user?.role === 1)?
                    <button className="w-10 mb-3 btn btn-lg task-default-button"
                            onClick={handleDeleteButtonClick}>Удалить
                    </button> : <></>
                }
            </div>


            <div className="title-container">
                <h2 style={{color: "white"}}>{props.task.task.name}</h2>
                <h3 style={{color: "white"}}>Дата получения
                    НЗИ: {props.task.task.finish_date.slice(0, 10)}</h3>
                <h3 style={{color: "white"}}>{props.task.detail?.number}</h3>
            </div>
            <GeneralTaskInfo
                project={props.task.task.project}
                responsible={props.task.task.responsible}
                progress={progress}
                setProgress={setProgress}
                desciption={props.task.task.description}
            />
            {/*{ (props.task.task.parent_task)?*/}
            {/*    <div style={{ margin: "20px" }}>*/}
            {/*        <h3 style={{color: "white"}}>Задача предок:</h3>*/}
            {/*        <TaskListItem*/}
            {/*            ID={props.task.task.parent_task.ID}*/}
            {/*            name={props.task.task.parent_task.name}*/}
            {/*            type={props.task.task.parent_task.type}*/}
            {/*            date={props.task.task.parent_task.finish_date}*/}
            {/*            progress={props.task.task.parent_task.progress}*/}
            {/*        />*/}
            {/*    </div> : <></>*/}

            {/*}*/}
            {(props.task.task.type === TaskType.Manufacturing) ?
                <div className="special-info-container">
                    <div className="special-info-item special-info-description">
                        <h3 style={{color: "white"}}>Деталь: {props.task.detail?.name}</h3>
                        <h3 style={{color: "white"}}>Обозначение: {props.task.detail?.number}</h3>
                        <h3 style={{color: "white"}}>Количество: {props.task.quantity}</h3>

                        <h3 style={{color: "white"}}>Материал: </h3>
                        <h3 style={{color: "white"}}>Прокат: </h3>
                        <h3 style={{color: "white"}}>Размеры: </h3>
                        <h3 style={{color: "white"}}>Примечание: </h3>
                        <h3 style={{color: "white"}}>Замечания: </h3>
                    </div>
                    <div className="special-info-item special-info-description" style={{ display: "flex", justifyContent: "center", alignItems: "center"  }}>
                        <ChildTasksInfo childTasks={props.task.task.child_tasks}/>
                    </div>
                </div> : <></>
            }
            {
                (props.task.task.type === TaskType.Operation) ?
                    <div className="special-info-container">
                        <div className="special-info-item special-info-description">
                            <h3 style={{color: "white"}}>Тип операции: {props.task.operation?.type}</h3>
                            <h3 style={{color: "white"}}>Адрес: {props.task.operation?.address}</h3>
                            <h3 style={{color: "white"}}>Номер: {props.task.operation?.address}</h3>
                            <button className="w-50 btn btn-lg details-default-button" onClick={handleOPFinish}>Завершить
                                задачу
                            </button>
                        </div>
                    </div> : <></>
            }
            {
                (props.task.task.type === TaskType.Storing) ?
                    <div className="special-info-container special-info-item" style={{display: "grid", width: "50%"}}>
                        <div>
                            <h3 style={{color: "white"}}>Необходимо положить
                                детали{props.task.task.name.split(":")[1]} на склад</h3>
                        </div>
                        <div>
                            <p style={{color: "white"}}>Чтобы завершить задачу, введите адрес на складе</p>
                            <Form.Control aria-label="Finish Date"
                                          id="taskFinishDate"
                                          className="form-control"
                                          value=""
                                          onChange={(e) => {
                                          }}
                                          required
                            />
                            <button className="mt-3 w-50 btn btn-lg details-default-button" onClick={handleStorageFinish}>Завершить
                                задачу
                            </button>
                        </div>

                    </div> : <></>
            }
            {(isDelete) ?
                <div className="modal display-block">
                    <div className="modal-main">
                        <h2>Удалить задачу?</h2>
                        <div className="new-detail-button-container" style={{display: "flex", width: "50%"}}>
                            <button className="w-50 btn btn-lg details-default-button" onClick={handleClose}>Нет
                            </button>
                            <button className="w-50 btn btn-lg details-default-button" onClick={handleDelete}>Да
                            </button>
                        </div>
                    </div>
                </div>
                : <></>
            }
        </div>

    )
        ;
};

export default TaskComponent;