// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dt-general-info-container {
    background-color: #4b4b4e;
    border-radius: 30px 30px 30px 30px;
    display: flex;
    margin: 10px;
    margin-top: 0 !important;
    padding: 2%;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/DetailType/GeneralDetailTypeInfo/GeneralDetailTypeInfo.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kCAAkC;IAClC,aAAa;IACb,YAAY;IACZ,wBAAwB;IACxB,WAAW;IACX,8BAA8B;AAClC","sourcesContent":[".dt-general-info-container {\n    background-color: #4b4b4e;\n    border-radius: 30px 30px 30px 30px;\n    display: flex;\n    margin: 10px;\n    margin-top: 0 !important;\n    padding: 2%;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
