import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import './NewPurchaseTask.css'
import { MaterialType } from "../../../../entities/material_type";
import { instance } from "../../../../api/config";
import { ShortStorage, StorageTypes } from "../../../../entities/storage";
import Switch from 'react-switch'
import { Input } from "../../../../components/Input/Input";
import { Select } from "../../../../components/Select/Select";
import { Assortment } from "../../../../entities/assortment";
import { AssortmentType } from "../../../../entities/assortment_type";

interface NewPurchaseTaskFormProps {
    assortment: Assortment | undefined;
    setAssortment: (a: Assortment | undefined) => void
    assortmentSizeID: number;
    setAssortmentSizeID: (assortmentSizeID: number) => void
    quantity: number
    setQuantity: (quantity: number) => void
    isInBudget: boolean
    setIsInBudget: (isInBudget: boolean) => void
    predictedPrice: number
    setPredictedPrice: (predictedPrice: number) => void
    supplyProvider: string
    setSupplyProvider: (supplyProvider: string) => void
    supplyWeight: number
    setSupplyWeight: (supplyWeight: number) => void
    supplyTimeAfterPaymentFrom: number
    setSupplyTimeAfterPaymentFrom: (supplyTimeAfterPaymentFrom: number) => void
    supplyTimeAfterPaymentTo: number
    setSupplyTimeAfterPaymentTo: (supplyTimeAfterPaymentTo: number) => void
    predictedSupplyDate: string
    setPredictedSupplyDate: (predictedSupplyDate: string) => void
    plannedSupplyDate: string
    setPlannedSupplyDate: (supplyDate: string) => void
    supplyDate: string
    setSupplyDate: (supplyDate: string) => void
    materialTypeID: number
    setMaterialTypeID: (materialTypeID: number) => void
    storageID: number
    setStorageID: (storageID: number) => void
}

const NewPurchaseTaskForm = (props: NewPurchaseTaskFormProps) => {

    const [assortmentsTypes, setAssortmentTypes] = useState<AssortmentType[]>([])
    const [chosenAType, setChosenAType] = useState<AssortmentType>()
    const [storages, setStorages] = useState<ShortStorage[]>([])

    useEffect(() => {
        const getDetails = async () => {
            const resp = await instance.get("/api/assortmentType/all")
            setAssortmentTypes(resp.data)
        }
        const getStorages = async () => {
            const resp = await instance.get("/api/storage/all")
            setStorages(resp.data)
        }
        getStorages()
        getDetails()
    }, []);

    return (
        <div>
            <div className="sub-page-task-purchase" style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                <div className="manufacturing-form mb-3" style={{ width: "50%", marginRight: "10px"}}>
                    <Select
                                label="Форма"
                                onChange={(e) => {
                                    setChosenAType(assortmentsTypes[parseInt(e.target.value)-1])
                                }}
                                defaultValue="0"
                            >
                        <option value="0" disabled>Выберите форма</option>
                        {Object.values(assortmentsTypes).map((type, index) => (
                            <option key={index+1} value={index+1}>
                                {type.name}, {type.amount_type}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "50%" }}>
                    <Select
                        label="Сортамент"
                        defaultValue="0"
                        onChange={(e) => {
                            props.setAssortment(chosenAType?.assortments[parseInt(e.target.value)-1])
                        }}
                    >
                        <option value="0" disabled>Выберите сортамент</option>
                        {Object.values(chosenAType?.assortments || []).map((assortment, index) => (
                            <option key={index+1} value={index+1}>
                                {assortment.name}, {assortment.gost}
                            </option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="sub-page-task-purchase" style={{ height: "40px", display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                <div style={{ width: "50%", marginRight: "10px"}} >
                    <Select
                                label="Склад"
                                onChange={(e) => {
                                    props.setStorageID(parseInt(e.target.value))
                                }}
                                defaultValue="0"
                            >
                        <option value="0" disabled>Выберите склад</option>
                        {storages.filter(s => s.storage_type == StorageTypes.Materials ).map((storage) => (
                            <option key={storage.ID} value={storage?.ID || 0}>
                                {storage.name}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="manufacturing-form" style={{ width: "25%", marginRight: "10px"}}>
                    <Select 
                                label="Тип материала"
                                onChange={(e) => props.setMaterialTypeID(parseInt(e.target.value))}
                                defaultValue="0"
                    >
                    <option value="0" disabled>Выберите материал</option>
                        {Object.values(props.assortment?.material_types || []).map((material) => (
                            <option key={material.ID} value={material?.ID || 0}>
                                {material.name}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="manufacturing-form" style={{ width: "25%" }}>
                    <Select 
                                label="Типоразмер"
                                defaultValue="0"
                                onChange={(e) => props.setAssortmentSizeID(parseInt(e.target.value))}
                    >
                    <option value="0" disabled>Выберите типоразмер</option>
                        {Object.values(props.assortment?.sizes || []).map((size) => (
                            <option key={size.ID} value={size?.ID || 0}>
                                {size.size}
                            </option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="sub-page-task-purchase" style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                <div className="manufacturing-form-big" style={{ width: '30%'}}>
                    <Input 
                                placeholder="Введите количество" 
                                label="Количество"
                                onChange={(e) => props.setQuantity(parseInt(e.target.value))}
                    />
                </div>
                <div className="manufacturing-form-big" style={{ width: '40%'}}>
                    <Input 
                                placeholder="Запланированная цена, рубли" 
                                label="Запланированная цена, рубли"
                                onChange={(e) => props.setPredictedPrice(parseInt(e.target.value))}
                    />
                </div>
                <div >
                    <Switch uncheckedIcon={false} checkedIcon={false} onColor="#F65727" offColor="#100f12" checked={props.isInBudget} onChange={() => {props.setIsInBudget(!props.isInBudget)}}/>
                </div>
                <div>
                    <p>Входит в бюджет</p>
                </div>
            </div>
            <div className="sub-page-task-purchase" style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                <div className="manufacturing-form" style={{ width: "50%", marginRight: "10px"}}>
                    <Input 
                                placeholder="Введите поставщика" 
                                label="Поставщик"
                                onChange={(e) => props.setSupplyProvider(e.target.value)}
                    />
                </div>
                <div className="manufacturing-form" style={{ width: "50%" }}>
                    <Input 
                                placeholder="Введите вес поставки" 
                                label="Вес поставки"
                                onChange={(e) => props.setSupplyWeight(parseInt(e.target.value))}
                    />
                </div>
            </div>
            <div className="" style={{ marginBottom: "20px"}}>

                <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%", alignItems: "center" }}>
                    <div style={{ marginRight: "10px" }}>
                        <p style={{color: 'var(--primary-text-color)'}}>Срок поставки после оплаты счета, дни</p>
                    </div>
                    <div className="manufacturing-form" style={{ width: "31%", marginRight: "10px"}}>
                        <Input 
                                    placeholder="От" 
                                    label="От"
                                    onChange={(e) => props.setSupplyTimeAfterPaymentFrom(parseInt(e.target.value))}
                        />
                    </div>
                    <div className="manufacturing-form" style={{ width: "31%"}}>
                        <Input 
                                    placeholder="До" 
                                    label="До"
                                    onChange={(e) => props.setSupplyTimeAfterPaymentTo(parseInt(e.target.value))}
                        />
                    </div>
                </div>
            </div>
            <div className="sub-page-task-purchase" style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                <div className="manufacturing-form mb-3" style={{ width: "50%", marginRight: "10px"}}>
                    <Input 
                                    placeholder="Ожидаемая дата поставки" 
                                    label="Ожидаемая дата поставки"
                                    type="date"
                                    onChange={(e) => props.setPredictedSupplyDate(e.target.value)}
                    />
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "50%" }}>
                    <Input 
                                    placeholder="Планируемая дата поставки" 
                                    label="Планируемая дата поставки"
                                    type="date"
                                    onChange={(e) => props.setPlannedSupplyDate(e.target.value)}
                    />
                </div>
            </div>
        </div>
    )
}
export default NewPurchaseTaskForm