import Form from "react-bootstrap/Form";
import NewOperationsForm from "../../DetailList/NewOperationsForm/NewOperationsForm";
import React, {useEffect, useState} from "react";
import {Operation} from "../../../entities/operation";
import {instance} from "../../../api/config";
import {Detail, DetailByID, getDetailByID} from "../../../entities/detail";
import './NewManufacturingForm.css'
import {DetailType, DetailTypeByID, getDetailTypeByID} from "../../../entities/detail_type";
interface NewManufacturingFormProps {
    detailType: DetailType,
    setDetailType: (newDetailType: DetailType) => void;
    quantity: number,
    setQuantity: (newQuantity: number) => void;
}
export const NewManufacturing: React.FC<NewManufacturingFormProps> = (props) => {

    const [detailTypes, setDetailTypes] = useState<DetailTypeByID>({});
    useEffect(() => {
        const getDetails = async () => {
            const resp = await instance.get("/api/detailTypes/all")
            if (resp?.data) {
                setDetailTypes(getDetailTypeByID(resp.data))
            } else {
                setDetailTypes({})
            }
        }
        getDetails()
    }, []);
    return (
        <div>
            <div className="">
                <div className="manufacturing-form mb-3">
                    <p style={{color: "white"}}>Деталь</p>
                    <Form.Select aria-label="Detail"
                                 id="taskDetail"
                                 className="form-control"
                                 value={props.detailType?.ID || ""}
                                 onChange={(e) => {
                                     props.setDetailType(detailTypes[parseInt(e.target.value)])
                                 }}
                                 required
                    >
                        <option value="" disabled>Выберите деталь</option>
                        {Object.values(detailTypes).map((detailType) => (
                            <option key={detailType.ID} value={detailType?.ID || 0}>
                                {detailType.name}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <div className="manufacturing-form mb-3">
                    <p style={{color: "white"}}>Количество</p>
                    <Form.Control aria-label="Количество"
                        id="Quantity"
                        className="form-control"
                        value={props?.quantity || ""}
                        onChange={(e) => props.setQuantity(parseInt(e.target.value))}
                        required
                        placeholder="Введите количество"
                    />
                </div>
            </div>
        </div>
    )
}