import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AssortmentType } from '../../entities/assortment_type';
import { UseIcons } from '../../hooks/UseIcons';
import { Button } from '../../components/Button/Button';
import { instance } from '../../api/config';
import AssortmentTypeList from '../../widgets/Lists/AssortmentTypeList/AssortmentTypeList';
import { Dialog } from 'primereact/dialog';
import NewAssortmentTypeForm from '../../widgets/Forms/NewAssotmentTypeForm/NewAssortmentTypeForm';
import { FieldRoot } from '../../components/Field/Fields';
import NewAssortmentForm from '../../widgets/Forms/NewAssortmentForm/NewAssortmentForm';

const AssortmentPage = () => {

    const icons = UseIcons()

    const [assortmentTypes, setAssortmentTypes] = useState<AssortmentType[]>([])
    const [allAssortmentTypes, setAllAssortmentTypes] = useState<AssortmentType[]>([])
    const [aTypeAdd, setATypeAdd] = useState(false)
    const [aAdd, setAAdd] = useState(false)
    const [selectedAType, setSelectedAType] = useState(0)
    useEffect(() => {
        const getAssortmentTypes = async () => {
            const resp = await instance.get("/api/assortmentType/all")
            setAssortmentTypes(resp.data)
            setAllAssortmentTypes(resp.data)
        }
        getAssortmentTypes()
    }, [])
    return (
        <FieldRoot className="projects-page">
            <div className='projects-header'>
                <h1 style={{fontSize:'30px'}}>Сортамент</h1>
                <div className='projects-search'>
                    <Form.Control 
                        className="form-control projects-search-form"
                        placeholder="Поиск по сортаменту"
                        onChange={(e) => {
                            var newAssortmentTypes = allAssortmentTypes?.filter(a_type => a_type.name.toLowerCase().includes(e.target.value.toLowerCase()))
                            for (var a_type of allAssortmentTypes || []) {
                                    if (!newAssortmentTypes?.includes(a_type)) {
                                        var newAType = structuredClone(a_type)
                                        newAType.assortments = newAType.assortments.filter(a => a.name?.toLowerCase().includes(e.target.value.toLowerCase()))
                                        if (newAType.assortments.length > 0) {
                                            newAssortmentTypes?.push(newAType)
                                        }
                                }
                            }
                            setAssortmentTypes(newAssortmentTypes || null)
                        }}
                    >

                    </Form.Control>
                </div>
                <Button buttonType='white-shadowed' className='project-page-new-dept-button' onClick={e => {
                    e.preventDefault();
                    setATypeAdd(true);
                }}>
                    <div className='project-page-new-dept-button-content'>
                        <img style={{ marginRight: "5px"}} src={icons.ADD} width="30" height="30"></img>
                        <label>Новая форма</label>
                    </div>
                </Button>
            </div>
            <AssortmentTypeList assortment_types={assortmentTypes} setAAdd={setAAdd} setSelectedAssortmentType={setSelectedAType}/>
            <Dialog header="Новая форма" visible={aTypeAdd} className='new-workplace' onHide={() => {
                if (!aTypeAdd) return;
                setATypeAdd(false);
            }}>
                <NewAssortmentTypeForm setIsAdd={setATypeAdd} />
            </Dialog>
            <Dialog header="Новый сортамент" visible={aAdd} className='new-workplace' onHide={() => {
                if (!aAdd) return;
                setAAdd(false);
            }}>
                <NewAssortmentForm assortment_type_id={selectedAType} setAAdd={setAAdd}/>
            </Dialog>
        </FieldRoot>
    );
}

export default AssortmentPage;