// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.operations-container {
    background-color: #212529;
    padding: 2%;
    border-radius: 30px 30px 30px 30px;
    margin: 1%;
    display: flex;
    justify-content: space-between;
}

.operations-info-item {
    background-color: #4b4b4e;
    padding: 1%;
    border-radius: 30px 30px 30px 30px;
    width: 50%;
    marginTop: 1%;
}`, "",{"version":3,"sources":["webpack://./src/components/DetailType/OperationDetailTypeInfo/OperationDetailTypeInfo.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,kCAAkC;IAClC,UAAU;IACV,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,kCAAkC;IAClC,UAAU;IACV,aAAa;AACjB","sourcesContent":[".operations-container {\n    background-color: #212529;\n    padding: 2%;\n    border-radius: 30px 30px 30px 30px;\n    margin: 1%;\n    display: flex;\n    justify-content: space-between;\n}\n\n.operations-info-item {\n    background-color: #4b4b4e;\n    padding: 1%;\n    border-radius: 30px 30px 30px 30px;\n    width: 50%;\n    marginTop: 1%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
