import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import {DetailType, NewDetailType} from "../entities/detail_type";
import {instance} from "../api/config";
import DetailTypeComponent from "../components/DetailType/DetailTypeComponent";

const DetailTypePage = () => {

    const { id } = useParams();

    const [ detailType, setDetailType] = useState<DetailType>(NewDetailType());

    useEffect(() => {
        const getDetailType = async () => {
            const resp = await instance.get("/api/detailTypes", { params: {
                "ID": id
                }});
            setDetailType(resp.data)
        }
        getDetailType()
    }, []);

    return (
        <div style={{ margin: "20px" }}>
            <DetailTypeComponent detailType={detailType} />
        </div>
    )
}

export default DetailTypePage;