import React from 'react';
import './TaskListItem.css'
import { buildStyles} from 'react-circular-progressbar';
import {useNavigate} from "react-router-dom";
import 'react-circular-progressbar/dist/styles.css';
import { FieldMain } from '../../../components/Field/Fields';
import { CircularProgressBar } from '../../../components/ProgressBar/ProgressBar';
interface TaskProps {
    ID: number
    name: string;
    type: string;
    date: string;
    progress: number;
}

const TaskListItem: React.FC<TaskProps> = (props: TaskProps) => {

    const navigate = useNavigate();

    return (
            <FieldMain className='task-list-item'>
                <div className='task-list-content' onClick={e => {
            e.preventDefault();
            navigate(`/tasks/${props.ID}`);
        }}>
                    <div className='task-list-item-progress-bar'>
                            <CircularProgressBar styles={buildStyles({ textColor: '#F65727', pathColor: "#F65727"})} value={props.progress}/>
                    </div>
                    <div className='task-list-item-info'>
                        <h1 >{props.ID}. {props.name}</h1>
                        <hr ></hr>
                        <div><h3>Тип: {props.type}</h3></div>
                        
                        <h3>Дата завершения: {props.date.slice(0,10)}</h3>
                    </div>
                </div>
            </FieldMain>
    );
};

export default TaskListItem;