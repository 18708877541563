// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-department-button {
    background-color: #F65727;
    color: #212529;
    border: solid 1px #F65727;
    border-radius: 3px;
}

.new-department-button:hover {
    background-color: #F65727;
    color: #1d2124;
    border: solid 1px #F65727;
    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727;
}

.new-department-button:active {
    background-color: #212529;
    color: #F65727;
}

.new-project-button {
    background-color: #F65727;
    font-weight: normal;
    color: #212529;
    border: solid 1px #F65727;
    border-radius: 3px;
}

.new-project-button:hover {
    background-color: #F65727;
    color: #1d2124;
    border: solid 1px #F65727;
    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727;
}

.new-project-button:active {
    background-color: #212529;
    color: #F65727;
}

.dialog-button {
    background-color: #F65727;
    font-weight: normal;
    color: #212529;
    border: solid 1px #F65727;
    border-radius: 3px;
    margin-left: 5px;
}

.dialog-button:hover {
    background-color: #F65727;
    color: #1d2124;
    border: solid 1px #F65727;
    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727;
}

.dialog-button:active {
    background-color: #212529;
    color: #F65727;
}

.p-accordion-header-link {
    padding: 0 0 0 10px;
}

`, "",{"version":3,"sources":["webpack://./src/css/ProjectsPage.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,mDAAmD;AACvD;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,mDAAmD;AACvD;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,mDAAmD;AACvD;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".new-department-button {\n    background-color: #F65727;\n    color: #212529;\n    border: solid 1px #F65727;\n    border-radius: 3px;\n}\n\n.new-department-button:hover {\n    background-color: #F65727;\n    color: #1d2124;\n    border: solid 1px #F65727;\n    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727;\n}\n\n.new-department-button:active {\n    background-color: #212529;\n    color: #F65727;\n}\n\n.new-project-button {\n    background-color: #F65727;\n    font-weight: normal;\n    color: #212529;\n    border: solid 1px #F65727;\n    border-radius: 3px;\n}\n\n.new-project-button:hover {\n    background-color: #F65727;\n    color: #1d2124;\n    border: solid 1px #F65727;\n    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727;\n}\n\n.new-project-button:active {\n    background-color: #212529;\n    color: #F65727;\n}\n\n.dialog-button {\n    background-color: #F65727;\n    font-weight: normal;\n    color: #212529;\n    border: solid 1px #F65727;\n    border-radius: 3px;\n    margin-left: 5px;\n}\n\n.dialog-button:hover {\n    background-color: #F65727;\n    color: #1d2124;\n    border: solid 1px #F65727;\n    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727;\n}\n\n.dialog-button:active {\n    background-color: #212529;\n    color: #F65727;\n}\n\n.p-accordion-header-link {\n    padding: 0 0 0 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
