// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project{
    border: 1px solid ghostwhite;
    padding: 0;
}
.project:hover{
    background-color: rgba(255, 255, 255, 0.1);
}

.project-id {
    margin-right: 5px;
    margin-left: 5px;
    font-size: larger;
    color: white;
}

.project-link {
    width: 100%;
    margin-top: 2px;
    margin-right: 2px;
    margin-bottom: 2px;
    background-color: #F65727;
    border-radius: 5px;
}
.project-name {
    margin: 10px;
    color: #212529;
    text-decoration: underline;
}
.project-info {
    display: inline-block;
    float: right;
    margin-right: 5px;
}
.info-label {
    margin-right: 15px;
    color: #212529;
}
.p-card-body {
    padding: 0;
}
.p-card-content {
    padding: 0;
}
.p-divider {
    padding: 0;
}
.finish-project-button {
    background-color: #F65727;
    color: #212529;
    border: solid 1px #F65727;
    border-radius: 3px;
}
.finish-project-button:hover {
    background-color: #F65727;
    color: #1d2124;
    border: solid 1px #F65727;
    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727;
}

.finish-project-button:active {
    background-color: #212529;
    color: #F65727;
}`, "",{"version":3,"sources":["webpack://./src/components/DepartmentsList/ProjectItem/ProjectItem.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,UAAU;AACd;AACA;IACI,0CAA0C;AAC9C;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,cAAc;IACd,0BAA0B;AAC9B;AACA;IACI,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,mDAAmD;AACvD;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".project{\n    border: 1px solid ghostwhite;\n    padding: 0;\n}\n.project:hover{\n    background-color: rgba(255, 255, 255, 0.1);\n}\n\n.project-id {\n    margin-right: 5px;\n    margin-left: 5px;\n    font-size: larger;\n    color: white;\n}\n\n.project-link {\n    width: 100%;\n    margin-top: 2px;\n    margin-right: 2px;\n    margin-bottom: 2px;\n    background-color: #F65727;\n    border-radius: 5px;\n}\n.project-name {\n    margin: 10px;\n    color: #212529;\n    text-decoration: underline;\n}\n.project-info {\n    display: inline-block;\n    float: right;\n    margin-right: 5px;\n}\n.info-label {\n    margin-right: 15px;\n    color: #212529;\n}\n.p-card-body {\n    padding: 0;\n}\n.p-card-content {\n    padding: 0;\n}\n.p-divider {\n    padding: 0;\n}\n.finish-project-button {\n    background-color: #F65727;\n    color: #212529;\n    border: solid 1px #F65727;\n    border-radius: 3px;\n}\n.finish-project-button:hover {\n    background-color: #F65727;\n    color: #1d2124;\n    border: solid 1px #F65727;\n    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727;\n}\n\n.finish-project-button:active {\n    background-color: #212529;\n    color: #F65727;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
