import {createContext, RefObject, useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import axios, {AxiosError} from "axios";
import {instance} from "../api/config";
import {User} from "../entities/user";
import {toast} from "react-hot-toast";

type UserContextType = {
    user: User | null;
    token: string | null;
    registerUser: (email: string, username: string, password: string, role: number) => Promise<void>;
    loginUser: (username: string, password: string) => Promise<void>;
    logout: () => void;
    isLoggedIn: () => boolean;
};

type Props = { children: React.ReactNode };

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: Props) => {
    const navigate = useNavigate();
    const [token, setToken] = useState<string | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const [isReady, setIsReady] = useState(false);
    const userRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
    const errRef : RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);


    useEffect(() => {
        if (userRef && userRef.current) {
            userRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setErrorMsg('')
    }, [user]);

    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        const user = localStorage.getItem("user");
        const token = localStorage.getItem("token");
        if (user && token) {
            setUser(JSON.parse(user));
            setToken(token);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        }
        setIsReady(true);
    }, []);

    const registerUser = async (
        email: string,
        username: string,
        password: string,
        role: number,
    ) => {
        const res = await  instance.post("/api/register", {
            username: username,
            password: password,
        })
        if (res) {
            console.log("Register Success!");
            navigate("/tasks");
        }
    };

    const loginUser = async (username: string, password: string) => {
        try {
            const loginRes = await instance.post("/api/login", {
                username: username,
                password: password,
            })
            if (loginRes) {
                localStorage.setItem("token", loginRes?.data.token);
                const validateRes = await instance.get("/api/users/current", {})
                if (validateRes) {
                const userObj: User = validateRes.data
                localStorage.setItem("user", JSON.stringify(userObj));
                setToken(loginRes?.data.token!);
                setUser(userObj!);
                toast("Успешный логин!")
                navigate("/tasks");
                }
            }
        } catch (err: AxiosError | any) {
            if (!err?.response) {
                setErrorMsg("No server response")
            } else if (err?.response?.status === 400) {
                setErrorMsg("Missing username or password")
                toast("Отсутствует имя пользователя или пароль")
            } else if (err?.response?.status === 401) {
                setErrorMsg("Invalid username or password")
                toast("Неверное имя пользователя или пароль")
            } else {
                setErrorMsg("Login failed.")
            }
            errRef.current?.focus()
        }
    };

    const isLoggedIn = () => {
        return !!user;
    };

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setUser(null);
        setToken("");
        navigate("/login");
    };

    return (
        <UserContext.Provider
            value={{ loginUser, user, token, logout, isLoggedIn, registerUser }}
        >
            {isReady ? children : null}
        </UserContext.Provider>
    );
};

export const useAuth = () => React.useContext(UserContext);