import React from "react";
import Form from "react-bootstrap/Form";

interface NewPurchaseTaskFormProps {
    material: string
    setMaterial: (material: string) => void
    gost: string
    setGost: (gost: string) => void
    size: string
    setSize: (size: string) => void
    quantity: number
    setQuantity: (quantity: number) => void
    isInBudget: boolean
    setIsInBudget: (isInBudget: boolean) => void
    predictedPrice: number
    setPredictedPrice: (predictedPrice: number) => void
    supplyProvider: string
    setSupplyProvider: (supplyProvider: string) => void
    supplyWeight: number
    setSupplyWeight: (supplyWeight: number) => void
    supplyTimeAfterPaymentFrom: number
    setSupplyTimeAfterPaymentFrom: (supplyTimeAfterPaymentFrom: number) => void
    supplyTimeAfterPaymentTo: number
    setSupplyTimeAfterPaymentTo: (supplyTimeAfterPaymentTo: number) => void
    predictedSupplyDate: string
    setPredictedSupplyDate: (predictedSupplyDate: string) => void
    plannedSupplyDate: string
    setPlannedSupplyDate: (supplyDate: string) => void
    supplyDate: string
    setSupplyDate: (supplyDate: string) => void
}
const NewPurchaseTaskForm = (props: NewPurchaseTaskFormProps) => {
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <div className="manufacturing-form mb-3" style={{ width: "49%"}}>
                    <p style={{color: "white"}}>Материал</p>
                    <Form.Control aria-label="Material"
                                  id="taskMaterial"
                                  className="form-control"
                                  value={props.material || ""}
                                  onChange={(e) => {
                                      props.setMaterial(e.target.value)
                                  }}
                                  required
                                  placeholder="Введите материал"
                    ></Form.Control>
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "49%"}}>
                    <p style={{color: "white"}}>Гост</p>
                    <Form.Control aria-label="Gost"
                                  id="taskGost"
                                  className="form-control"
                                  value={props.gost || ""}
                                  onChange={(e) => {
                                      props.setGost(e.target.value)
                                  }}
                                  required
                                  placeholder="Введите ГОСТ"
                    ></Form.Control>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <div className="manufacturing-form mb-3" style={{ width: "49%"}} >
                    <p style={{color: "white"}}>Количество</p>
                    <Form.Control aria-label="Quantity"
                                  id="Quantity"
                                  className="form-control"
                                  value={props?.quantity || ""}
                                  onChange={(e) => props.setQuantity(parseInt(e.target.value))}
                                  required
                                  placeholder="Введите количество"
                    />
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "49%"}}>
                    <p style={{color: "white"}}>Размер</p>
                    <Form.Control aria-label="size"
                                  id="size"
                                  className="form-control"
                                  value={props?.size}
                                  onChange={(e) => props.setSize(e.target.value)}
                                  required
                                  placeholder="Введите размер"
                    />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="manufacturing-form mb-3" style={{ width: "69%"}}>
                    <p style={{color: "white"}}>Запланированная цена, рубли</p>
                    <Form.Control aria-label="predictedPrice"
                                  id="predictedPrice"
                                  className="form-control"
                                  value={(props?.predictedPrice)? `${props?.predictedPrice} pуб.`: `0 pуб.`}
                                  onChange={(e) => props.setPredictedPrice(parseInt(e.target.value.slice(0, e.target.value.length - 5)))}
                                  required
                    />
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "29%"}}>
                    <p style={{color: "white"}}>Входит в бюджет</p>
                    <Form.Check aria-label="isInBudget"
                                  id="isInBudget"
                                  className="form-control"
                                  value={""}
                        // onChange={(e) => props.setQuantity(parseInt(e.target.value))}
                                  required
                    />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="manufacturing-form mb-3" style={{ width: "49%"}}>
                    <p style={{color: "white"}}>Поставщик</p>
                    <Form.Control aria-label="supplyProvider"
                                  id="supplyProvider"
                                  className="form-control"
                                  value={props?.supplyProvider || ""}
                                  onChange={(e) => props.setSupplyProvider(e.target.value)}
                                  required
                                  placeholder="Введите поставщика"
                    />
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "49%"}}>
                    <p style={{color: "white"}}>Вес партии, кг</p>
                    <Form.Control aria-label="supplyWeight"
                                  id="supplyWeight"
                                  className="form-control"
                                  value={props?.supplyWeight || ""}
                                  onChange={(e) => props.setSupplyWeight(parseInt(e.target.value))}
                                  required
                                  placeholder="Введите вес поставки"
                    />
                </div>
            </div>
            <div className="manufacturing-form mb-3">
                <p style={{color: "white"}}>Срок поставки после оплаты счета, дни</p>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{width: "49%"}}>
                        <Form.Control aria-label="supplyTimeAfterPaymentFrom"
                                      id="supplyTimeAfterPaymentFrom"
                                      className="form-control"
                                      value={props?.supplyTimeAfterPaymentFrom || ""}
                                      onChange={(e) => props.setSupplyTimeAfterPaymentFrom(parseInt(e.target.value))}
                                      required
                                      placeholder="От"
                        />
                    </div>
                    <div style={{width: "49%"}}>
                        <Form.Control aria-label="supplyWeight"
                                      id="supplyWeight"
                                      className="form-control"
                                      value={props?.supplyTimeAfterPaymentTo || ""}
                                      onChange={(e) => props.setSupplyTimeAfterPaymentTo(parseInt(e.target.value))}
                                      required
                                      placeholder="До"
                        />
                    </div>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between" }}>
                <div className="manufacturing-form mb-3" style={{ width: "49%"}}>
                    <p style={{color: "white"}}>Ожидаемая дата поставки</p>
                    <Form.Control aria-label="predictedSupplyDate"
                                  id="predictedSupplyDate"
                                  className="form-control"
                                  value={props?.predictedSupplyDate || ""}
                                  onChange={(e) => props.setPredictedSupplyDate(e.target.value)}
                                  type="date"
                                  required
                    />
                </div>
                <div className="manufacturing-form mb-3" style={{ width: "49%"}}>
                    <p style={{color: "white"}}>Планируемая дата поставки</p>
                    <Form.Control aria-label="plannedSupplyDate"
                                  id="plannedSupplyDate"
                                  className="form-control"
                                  value={props?.plannedSupplyDate || ""}
                                  onChange={(e) => props.setPlannedSupplyDate(e.target.value)}
                                  type="date"
                                  required
                    />
                </div>
            </div>
        </div>
    )
}
export default NewPurchaseTaskForm