import React, {useEffect, useState} from 'react';
import './Notebook.css'
import {NewNotebook, notebook} from "../../entities/notebook";
import {instance} from "../../api/config";
import {useAuth} from "../../context/AuthProvider";
import {getProjectByID} from "../../entities/project";
import { Form } from 'react-bootstrap';
import { Button } from '../../components/Button/Button';
import { FieldGeneral, FieldMain } from '../../components/Field/Fields';
import { UseIcons } from '../../hooks/UseIcons';
import { Input } from '../../components/Input/Input';

const Notebook: React.FC = () => {

    const icons = UseIcons()
    const { user } = useAuth()
    const [notebookList, setNotebookList] = useState<notebook[]>([])
    const [allNotebookList, setAllNotebookList] = useState<notebook[]>([])
    const [notebook, setNotebook] = useState<notebook>(NewNotebook())
    const [isChanged, setIsChanged] = useState<boolean>(false)
    const onNameChange = (name:string) => {
        const newNotebook = notebook
        newNotebook.title = name
        setNotebook(newNotebook)
        setIsChanged(!isChanged)
    }
    const onTextChange = (text:string) => {
        const newNotebook = notebook
        newNotebook.text = text
        setNotebook(newNotebook)
        setIsChanged(!isChanged)
    }

    const handleNotepadAdd = async (e:React.FormEvent) => {
        if (!notebook.text || !notebook.title) {
            return
        }
        const newNotebook = notebook
        newNotebook.user_id = user?.ID || 0
        const resp = await instance.post("/api/notebooks", newNotebook)
        const resp_all = await instance.get("/api/users/current")
        setNotebookList(resp_all.data.notebooks)
        setNotebook(NewNotebook())
    }

    useEffect(() => {

        const refreshNotebooks  = async () => {
           const resp = await instance.get("/api/users/current")
            setNotebookList(resp.data.notebooks)
            setAllNotebookList(resp.data.notebooks)
        }
        refreshNotebooks()
    }, []);

    const handleNotepadDelete = (index: number) => {
        const resp = instance.delete("/api/notebooks", { data : {"ID": index}})
        setNotebookList(notebookList.filter((notebook) => notebook.ID != index))
    }

    const concatTitle = (title: string) => {
        return (title.length > 29)? title.slice(0, 29) + "..." : title
    }
    const concatText = (text: string) => {
        return (text.length > 240)? text.slice(0, 240) + "..." : text
    }
    return (
        <div className='notebook-page'>
            <div className='note-header'>
                <h1 className='note-title'>Блокнот</h1>
                <div className='note-search'>
                    <Form.Control
                        className="form-control note-search-form"
                        placeholder='Поиск по блокноту'
                        onChange={(e) => {
                            const newNotebooks = allNotebookList.filter(notebook => 
                                notebook.title?.toLowerCase().includes(e.target.value.toLowerCase()) ||
                                notebook.text?.toLowerCase().includes(e.target.value.toLowerCase())
                            )
                            setNotebookList(newNotebooks)
                        }}
                    />
                </div>
            </div>
            <div className="notepadGroup">
                {notebookList.map((item, index) => (
                    <FieldMain className="note" key={index}>
                        <div className='note-text'>
                            <h2>{concatTitle(item.title || "")} </h2>
                            <hr></hr>
                            <p>{concatText(item.text || "")}</p>
                        </div>
                        <Button className='note-delete-button' buttonType='white-shadowed' type="button" key={index}
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleNotepadDelete(item.ID || 0)
                                }}>
                                <img src={icons.DELETE} alt="ChatLogo" width="30" height="30"/>
                        </Button>
                    </FieldMain>
                ))}
                <FieldMain className="note-box">
                    <h2>Новая запись</h2>
                    <hr></hr>
                    <div style={{ marginBottom: "20px"}}>
                        <Input 
                            placeholder="Введите заголовок" 
                            label="Заголовок"
                            onChange={(e) => {
                                onNameChange(e.target.value)
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: "20px"}}>
                        <Input 
                            placeholder="Введите запись" 
                            label="Запись"
                            onChange={(e) => onTextChange(e.target.value)}
                        />
                    </div>
                    <Button buttonType='white-shadowed' className='note-add-button'
                            onClick={handleNotepadAdd}>Добавить заметку
                    </Button>
                </FieldMain>
                
            </div>
        </div>
    );
};

export default Notebook;