import styled from "styled-components";
export const FieldRoot = styled.div`
    box-shadow: 0 0.1em 0.30em  var(--base-grey-color);
    background-color: var(--base-root-color);
    border-radius: 8px;
`

export const FieldMain = styled(FieldRoot)`
    background-color: var(--base-main-color);
    border-radius: 8px;

`

export const FieldGeneral = styled(FieldRoot)`
    background-color: var(--base-general-color);
`