// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manufacturing-form{

}

.manufacturing-form:last-child {
    margin-right: 0;
}
.manufacturing-form p {
    margin-right: 10px;
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/NewTaskForm/NewManufacturingForm/NewManufacturingForm.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".manufacturing-form{\n\n}\n\n.manufacturing-form:last-child {\n    margin-right: 0;\n}\n.manufacturing-form p {\n    margin-right: 10px;\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
