import React from 'react';
import '../css/Tasks.css'
import ProgressBar from 'react-bootstrap/ProgressBar';
import {useNavigate} from "react-router-dom";

interface TaskProps {
    ID: number
    name: string;
    type: string;
    date: string;
    progress: number;
}


const TaskListItem: React.FC<TaskProps> = (props: TaskProps) => {

    const navigate = useNavigate()

    const handleTaskClick = (id: number) => {
        navigate(`/tasks/${id}`)
    }
    return (
        <div className="task" onClick={(e) => {
            e.preventDefault();
            handleTaskClick(props.ID)
        }}>
            <a href="#">
                <label className="task-name">{props.name}</label>
                <div className="task-info">
                    <label className="info-label">Тип: {props.type}</label>
                    <label className="info-label">Дата получения НЗ: {props.date.slice(0,10)}</label>
                    <label className="info-label"><ProgressBar animated variant="warning" className="task-progressbar" now={props.progress} label={`${props.progress}%`}/></label>
                    <a href="#"><img src="/chat.png" alt="ChatLogo" width="25" height="25"/></a>
                </div>
            </a>
        </div>
    );
};

export default TaskListItem;