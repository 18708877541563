import React, { useEffect, useState } from 'react';
import { FieldMain } from '../../../components/Field/Fields';
import { getOptionsByUsers, User } from '../../../entities/user';
import { WorkPlace } from '../../../entities/workplaces';
import './WorkPlacesListItem.css'
import { Dialog } from 'primereact/dialog';
import { Tag } from '../../../components/Tag/Tag';
import { Button } from '../../../components/Button/Button';
import makeAnimated from "react-select/animated";
import Form from 'react-bootstrap/esm/Form';
import Select, { MultiValue } from 'react-select';
import { instance } from '../../../api/config';
import { UseIcons } from '../../../hooks/UseIcons';
import { Username } from '../../../components/Username/Username';

interface WorkPlacesListItemProps {
    workplace: WorkPlace
}

const WorkPlacesListItem = (props: WorkPlacesListItemProps) => {

    const icons = UseIcons()
    const [isOpen, setIsOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [users, setUsers] = useState<User[]>([])

    const [usersSelectedOptions, setUsersSelectedOptions] = useState<MultiValue<{
        value: number;
        label: string;
    }> | null>(null);
    
    

    useEffect(() => {
        setUsersSelectedOptions(getOptionsByUsers(props.workplace.users))
    }, [])

    useEffect(() => {
        const getUsers = async () => {
            const response = await instance.get('/api/users/all')
            setUsers(response.data)
        }
        getUsers()
    }, [isEdit])

    let usersSelOptions;
    if (users !== null) {
        usersSelOptions = getOptionsByUsers(users);
    }

    const selectStyles = {
        control: (base:any) => ({
            ...base,
            color: 'var(--primary-text-color)',
            border: "2px solid #F65727",
            borderRadius: "8px",
            marginBottom: "20px",
            backgroundColor: 'var(--base-general-color)',
        }),
        multiValue: (base:any) => ({
            ...base,
            backgroundColor: 'var(--base-general-color)',
        }),
        multiValueLabel: (base:any) => ({
            ...base,
            color: 'var(--primary-text-color)',
            backgroundColor: 'var(--base-main-color)',
        }),
        menuList: (base:any) => ({
            ...base,
            backgroundColor: 'var(--base-general-color)',
        }),
        option: (base:any) => ({
            ...base,
            backgroundColor:'var(--base-general-color)',
            "&:hover": {
                backgroundColor: 'var(--base-general-color)',
            },
        })
    }

    return (
        <>
            <FieldMain onClick={(e) => {e.preventDefault(); setIsOpen(true);}} className='workplaces-list-item'>
                <h1>{props.workplace.name}</h1>
                <hr></hr>
                <h3>Aдрес: <b>{props.workplace.address}</b></h3>
                <h3>Номер: <b>{props.workplace.number}</b></h3>
                <h3>Работники: <b>{props.workplace.users.length}</b></h3>
                <div className='workplaces-list-item-operations' >
                    <h3>Операции:{'\u00A0'}</h3>
                    {props.workplace.operation_types.map((type) => {
                        return <Tag>{type.operation_type}</Tag>
                    })}
                </div>
                <h3 className='workplaces-list-item-current-user'>Текущий работник: <b>{ (props.workplace.current_user.ID === 0 ) ? "Не назначен" : Username(props.workplace.current_user)}</b></h3>
                
            </FieldMain>
            <Dialog header={<div className='workplaces-dialog-header'>
                                {`Рабочее место: ${props.workplace.name}`}
                                {(!isEdit) ? 
                                    <img onClick={(e) => {e.preventDefault(); setIsEdit(true)}} style={{ marginRight: "5px"}} src={icons.GEAR} height="20" width="20"></img>
                                    : 
                                    <img onClick={(e) => {e.preventDefault(); setIsEdit(false)}} style={{ marginRight: "5px"}} src={icons.GEAR_ACTIVE} height="20" width="20"></img>
                                }
                            </div>} visible={isOpen} className='workplaces-dialog' onHide={() => {
                if (!isOpen) return;
                setIsOpen(false);
            }}>
                    <div className='workplaces-dialog-body'>
                        <h2>Aдрес: <b>{props.workplace.address}</b></h2>
                        <h2>Номер: <b>{props.workplace.number}</b></h2>
                        <div className='workplaces-dialog-operations'>
                            <h2>Операции: </h2>
                            {props.workplace.operation_types.map((type) => {
                                return <><Tag className='workplaces-dialog-operation'>{type.operation_type}</Tag></>
                            })}
                        </div>

                        {
                            (isEdit) ? 
                                <div className='workplaces-dialog-form'>
                                    <h2 className='workplaces-dialog-form-cuser-select-label'>Текущий работник:</h2>
                                    <Form.Select
                                        className='form-control workplaces-dialog-form-cuser-select'
                                        
                                        defaultValue={Username(props.workplace.current_user) || "Не назначен"}
                                    >
                                        {Object.values(props.workplace.users).map((user, index) => (
                                            <option key={index} value={`${user.ID}`}>{Username(user)}</option>
                                        ))}
                                    </Form.Select>
                                    <h2 className='workplaces-dialog-form-users-select-label'>Работники: </h2>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={makeAnimated()}
                                        styles={selectStyles}
                                        placeholder="Выберите пользователей"
                                        noOptionsMessage={() => "Пользователей не найдено"}
                                        defaultValue={usersSelectedOptions}
                                        onChange={setUsersSelectedOptions}
                                        isMulti
                                        options={usersSelOptions}
                                    />
                                    <Button buttonType='white-shadowed' className='workplaces-dialog-form-button' >
                                        <div className='workplaces-dialog-form-button-content'>
                                            <img style={{ marginRight: "10px"}} src={icons.SAVE} width="30" height="30"></img>
                                            Сохранить
                                        </div>
                                    </Button>
                                </div> : 
                                <>
                                    <div>
                                        <h2 className='workplaces-dialog-cuser'>Текущий работник: <b>{(props.workplace.current_user.ID === 0 ) ? "Не назначен" : Username(props.workplace.current_user)}</b></h2>
                                    </div>
                                    <div className='workplaces-dialog-users'>
                                        <h2>Работники: </h2>
                                    
                                        {props.workplace.users.map((user) => {
                                            return <Tag style={{ marginRight: "5px"}}>{Username(user)}</Tag>
                                        })}
                                    </div>
                                </>
                        }
                    </div>
                
            </Dialog>
        </>
    )
}

export default WorkPlacesListItem;