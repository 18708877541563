import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from '../../../components/Button/Button';
import { instance } from '../../../api/config';
import './NewAssortmentTypeForm.css'
import { Input } from '../../../components/Input/Input';
import { Select } from '../../../components/Select/Select';
import { AmountTypes } from '../../../entities/assortment_type';

interface NewAssortmentTypeFormProps {
    setIsAdd: (value: boolean) => void;
}
const NewAssortmentTypeForm = (props: NewAssortmentTypeFormProps) => {

    const [name, setName] = useState("")
    const [amountType, setAmountType] = useState("")

    const handleClose = async (e: React.FormEvent) => {
        e.preventDefault();
        props.setIsAdd(false);
    }
    const handleSave = async (e: React.FormEvent) => {
        e.preventDefault();
        const resp = await instance.post("/api/assortmentType", {
            "name": name,
            "amount_type": amountType,
        })
        props.setIsAdd(false);
    }

    return (
        <div>
            <div className='' style={{ marginBottom: "20px", marginTop: "5px"}}>
                <Input
                    label="Название формы"
                    placeholder="Введите название формы"
                    onChange={(e) => {setName(e.target.value)}}
                />
            </div>
            <div className='' >
                <Select
                    label="Единица измерения"
                    defaultValue="0"
                    onChange={(e) => setAmountType(e.target.value)}
                            >
                        <option value="0" disabled>Выберите единицу измерения</option>
                        {Object.values(AmountTypes).map((amountType, index) => (
                            <option key={index} value={amountType}>{amountType}</option>
                        ))}
                </Select>
            </div>
            <div className="new-detail-button-container" style={{display: "flex", width: "50%"}}>
                <Button buttonType='white-shadowed' className="w-100" onClick={handleClose}>Закрыть
                </Button>
                <Button buttonType='white-shadowed' className="w-100" style={{ marginLeft: "10px"}} onClick={handleSave}>Сохранить
                </Button>
            </div>
        </div>
    )
}

export default NewAssortmentTypeForm