// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-group {
    display: inline-block;
    float: right;
    box-shadow: 0 0.1em 0.30em  var(--base-grey-color);
}

.btn-secondary {
    background-color: transparent;
    color: #F65727;
    border: solid 1px #F65727;
}

.focus:focus{
    box-shadow: none;
    background-color: var(--base-button-color-active);
}`, "",{"version":3,"sources":["webpack://./src/widgets/DayMonthYear/DayMonthYear.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;IACZ,kDAAkD;AACtD;;AAEA;IACI,6BAA6B;IAC7B,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,iDAAiD;AACrD","sourcesContent":[".btn-group {\n    display: inline-block;\n    float: right;\n    box-shadow: 0 0.1em 0.30em  var(--base-grey-color);\n}\n\n.btn-secondary {\n    background-color: transparent;\n    color: #F65727;\n    border: solid 1px #F65727;\n}\n\n.focus:focus{\n    box-shadow: none;\n    background-color: var(--base-button-color-active);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
