// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floating-select {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0.5rem 0.5rem ;
    background-color: white;
    border: 2px solid #F65727;
    border-radius: 0.5rem;
    color: var(--primary-text-color);
    margin-bottom: -25px;
  }
  
.floating-select:focus {
    outline: none;
    border-color: var(--primary-text-color);
}
  
.floating-label {
    position: relative;
    width: auto;
    left: 1%;
    bottom: 7px;
    background-color: transparent;  
    color: #6b7280;
    transform-origin: 0 0;
    transform: translateY(0%) scale(1);
    transition: transform 0.3s, color 0.3s;
    pointer-events: none;
    z-index: 10;
}

.floating-select:focus {
    width: 100%;
    box-shadow: 0 0 0 0;
    border-color: #F65727;
}
  
.floating-select:focus ~ .floating-label,
.floating-label.active {
    transform: translateY(-65%) scale(0.75) translateX(25px);
    color: #F65727;
    border-radius: 8px;
    box-shadow: 0 0.1em 0.30em  #757575;
    background-color: white;
}
  
.floating-select:focus ~ .floating-label {
    color: #F65727;
    box-shadow: 0 0.1em 0.30em  #757575;
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Select/Select.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,uBAAuB;IACvB,yBAAyB;IACzB,qBAAqB;IACrB,gCAAgC;IAChC,oBAAoB;EACtB;;AAEF;IACI,aAAa;IACb,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,WAAW;IACX,6BAA6B;IAC7B,cAAc;IACd,qBAAqB;IACrB,kCAAkC;IAClC,sCAAsC;IACtC,oBAAoB;IACpB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;;IAEI,wDAAwD;IACxD,cAAc;IACd,kBAAkB;IAClB,mCAAmC;IACnC,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,mCAAmC;IACnC,kBAAkB;AACtB","sourcesContent":[".floating-select {\n    display: block;\n    width: 100%;\n    height: 40px;\n    padding: 0.5rem 0.5rem ;\n    background-color: white;\n    border: 2px solid #F65727;\n    border-radius: 0.5rem;\n    color: var(--primary-text-color);\n    margin-bottom: -25px;\n  }\n  \n.floating-select:focus {\n    outline: none;\n    border-color: var(--primary-text-color);\n}\n  \n.floating-label {\n    position: relative;\n    width: auto;\n    left: 1%;\n    bottom: 7px;\n    background-color: transparent;  \n    color: #6b7280;\n    transform-origin: 0 0;\n    transform: translateY(0%) scale(1);\n    transition: transform 0.3s, color 0.3s;\n    pointer-events: none;\n    z-index: 10;\n}\n\n.floating-select:focus {\n    width: 100%;\n    box-shadow: 0 0 0 0;\n    border-color: #F65727;\n}\n  \n.floating-select:focus ~ .floating-label,\n.floating-label.active {\n    transform: translateY(-65%) scale(0.75) translateX(25px);\n    color: #F65727;\n    border-radius: 8px;\n    box-shadow: 0 0.1em 0.30em  #757575;\n    background-color: white;\n}\n  \n.floating-select:focus ~ .floating-label {\n    color: #F65727;\n    box-shadow: 0 0.1em 0.30em  #757575;\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
