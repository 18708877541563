import Form from "react-bootstrap/Form";
import React, {useEffect, useState} from "react";
import {instance} from "../../../../api/config";
import './NewManufacturingForm.css'
import {DetailType, DetailTypeByID, getDetailTypeByID} from "../../../../entities/detail_type";
import { WorkPlace } from "../../../../entities/workplaces";
import { OperationWorkplace } from "../../../../entities/task";
import Switch from 'react-switch'
import { Input } from "../../../../components/Input/Input";
import { Select } from "../../../../components/Select/Select";
interface NewManufacturingFormProps {
    detailType: DetailType,
    setDetailType: (newDetailType: DetailType) => void;
    quantity: number,
    setQuantity: (newQuantity: number) => void;
    operationWorkplaces: OperationWorkplace[]
    setOperationWorkplaces: (newOperationWorkplaces: OperationWorkplace[]) => void;
    hasQualityControl: boolean
    setHasQualityControl: (newHasQualityControl: boolean) => void;

}
export const NewManufacturing: React.FC<NewManufacturingFormProps> = (props) => {

    const [detailTypes, setDetailTypes] = useState<DetailTypeByID>({});
    const [workplaces, setWorkplaces] = useState<WorkPlace[]>([]);
    useEffect(() => {
        const getDetails = async () => {
            const resp = await instance.get("/api/detailTypes/all")
            if (resp?.data) {
                setDetailTypes(getDetailTypeByID(resp.data))
            } else {
                setDetailTypes({})
            }
        }
        const getWorkplaces = async () => {
            const resp = await instance.get("/api/workPlaces/all")
            setWorkplaces(resp.data)
        }
        getDetails()
        getWorkplaces()
    }, []);
    return (
        <div>
            <div style={{ display: "flex", marginBottom: "20px"}}>
                <div style={{ width:"60%", marginRight: "10px"}} className="manufacturing-form mb-3">
                    <Select
                                label="Деталь"
                                onChange={(e) => {
                                    props.setDetailType(detailTypes[parseInt(e.target.value)])
                                }}
                                defaultValue="0"
                            >
                        <option value="0" disabled>Выберите деталь</option>
                        {Object.values(detailTypes).map((detailType) => (
                            <option key={detailType.ID} value={detailType?.ID || 0}>
                                {detailType.name}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="manufacturing-form mb-3">
                    <Input 
                        placeholder="Введите количество" 
                        label="Количество"
                        onChange={(e) => props.setQuantity(parseInt(e.target.value))}
                    />
                </div>
            </div>
            <div style={{ display: "flex", width:"100%", alignItems: "center"}} className="manufacturing-form mb-3">
                    <div style={{ width:"60%", marginRight: "10px"}}>
                    <Form.Control
                        id="Quantity"
                        className="form-control add-mf-task-input-form"
                        required
                        disabled={(props.detailType.ID) ? false : true}
                        placeholder="Материал"
                    />
                    </div>
                    <div style={{ marginRight: "10px", marginBottom: "7px"}}>
                        <Switch uncheckedIcon={false} checkedIcon={false} onColor="#F65727" offColor="#100f12" checked={props.hasQualityControl} onChange={() => {props.setHasQualityControl(!props.hasQualityControl)}}/>
                    </div>
                    <h4>Контроль качества</h4>
            </div>
            <div style={{ marginBottom: "15px" }}>
                <h1>Заполните рабочие места для операций</h1>
            </div>
            <div style={{ display: "flex"}}>
                {
                    props.detailType.route_list.operations.map((operation) => (
                        <div style={{ width: "18%", marginRight: "2%"}}>
                            <Select
                                label={operation.type}
                                onChange={(e) => { 
                                    const newOW = props.operationWorkplaces.filter(ow => ow.operation_id != operation.ID)
                                    newOW.push({
                                        operation_id: operation.ID,
                                        work_place_id: parseInt(e.target.value)
                                    })
                                    props.setOperationWorkplaces(newOW)
                                }}
                                defaultValue="0"
                            >
                                <option value="0" disabled>{operation.type}</option>
                                {workplaces.map((workplace) => {
                                    if (workplace.operation_types.filter(op => op.operation_type === operation.type).length)
                                    return (
                                    <option key={workplace.ID} value={workplace?.ID || 0}>
                                        ({workplace.address}) {workplace.name}
                                    </option>
                                    )
                                })}
                            </Select>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}