import React, { useState } from 'react';
import { Assortment } from '../../../entities/assortment';
import { Card } from 'primereact/card';
import { toTitleCase } from '../../../common/strings';
import { Dialog } from 'primereact/dialog';
import { Select } from '../../../components/Select/Select';
import { Tag } from '../../../components/Tag/Tag';
import './AssortmentListItem.css'
import { UseIcons } from '../../../hooks/UseIcons';
import { Input } from '../../../components/Input/Input';
import { Button } from '../../../components/Button/Button';
import { instance } from '../../../api/config';
interface AssortmentListItemProps {
    assortment: Assortment
}
const AssortmentListItem = (props: AssortmentListItemProps) => {

    const icons = UseIcons()

    const [isShow, setIsShow] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    const [assortmentSize, setAssortmentSize] = useState("")
    const [assortmentMaterialType, setAssortmentMaterialType] = useState("")
    const handleSizeAdd = async () => {
        const resp = await instance.post('/api/assortmentSize', {
            "assortment_id": props.assortment.ID,
            "size": assortmentSize,
        })
    }

    const handleMaterialAdd = async () => {
        const resp = await instance.post('/api/materialTypes', {
            "assortment_id": props.assortment.ID,
            "name": assortmentMaterialType,
        })
    }
    return (
        <>
            <Card className="project" onClick={e => {
                e.preventDefault();
                setIsShow(true)
            }}>
                <div className='project-content' style={{ alignItems: "center"}}>
                    <h3 style={{ marginLeft: "20px", marginBottom: "0px"}}>{toTitleCase(props.assortment.name)}</h3>
                    <h3 style={{ marginRight: "20px", marginBottom: "0px"}}>{props.assortment.gost}</h3>
                </div>
            </Card>
            <Dialog header={<div className='workplaces-dialog-header' style={{ alignItems: "flex-start"}}>
                                {`Сортамент: ${toTitleCase(props.assortment.name)}`}
                                {(!isEdit) ? 
                                    <img onClick={(e) => {e.preventDefault(); setIsEdit(true)}} style={{ marginRight: "5px", marginTop: "5px"}} src={icons.GEAR} height="20" width="20"></img>
                                    : 
                                    <img onClick={(e) => {e.preventDefault(); setIsEdit(false)}} style={{ marginRight: "5px", marginTop: "5px"}} src={icons.GEAR_ACTIVE} height="20" width="20"></img>
                                }
                            </div>} className='assortment-list-item-dialog' visible={isShow} onHide={() => {
                if (!isShow) return;
                setIsShow(false);
            }}>
                <div style={{ marginTop: "5px"}}>
                    {
                        (isEdit) ? 
                        <div style={{ display: "flex", marginBottom: "20px"}}>
                            <div className='assortment-list-item-dialog-size-input'>
                                <Input
                                    label="Материал"
                                    placeholder='Введите материал'
                                    onChange={(e) => setAssortmentMaterialType(e.target.value)}
                                />
                            </div>
                                <Button buttonType='white-shadowed' style={{ width: "30%"}} onClick={handleMaterialAdd}>
                                    <div className='project-page-new-dept-button-content'>
                                        <img style={{ marginRight: "5px"}} src={icons.ADD} width="30" height="30"></img>
                                        <label>Добвить</label>
                                    </div>
                                </Button>
                        </div>
                        : <></> }
                        <Select 
                            label="Список материалов"
                            defaultValue="0"
                        >
                                <option value="0" disabled>Список материалов</option>
                                {Object.values(props.assortment.material_types).map((matetialType, index) => (
                                    <option key={index+1} value={index+1}>{matetialType.name}</option>
                                ))}
                        </Select>
                    <h1 style={{ marginTop: "20px"}}>Типоразмеры:</h1>
                    {
                        (isEdit) ? 
                        <div style={{ display: "flex", marginBottom: "20px"}}>
                            <div className='assortment-list-item-dialog-size-input'>
                                <Input
                                    label="Типоразмер"
                                    placeholder='Введите типоразмер'
                                    onChange={(e) => setAssortmentSize(e.target.value)}
                                />
                            </div>
                                <Button buttonType='white-shadowed' style={{ width: "30%"}} onClick={handleSizeAdd}>
                                    <div className='project-page-new-dept-button-content'>
                                        <img style={{ marginRight: "5px"}} src={icons.ADD} width="30" height="30"></img>
                                        <label>Добвить</label>
                                    </div>
                                </Button>
                        </div>: <></>
                    }
                    <div style={{ display: "flex" }}>
                    {
                        props.assortment.sizes.map((size) => (
                            <Tag style={{ marginRight: "5px"}}>{size.size}</Tag>
                        ))
                    }
                    </div>
                    <h3 style={{ color: "var(--base-grey-color)" }}>ГОСТ: {props.assortment.gost}</h3>
                </div>
            </Dialog>
        </>
    )   
}

export default AssortmentListItem;