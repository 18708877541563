import styled from 'styled-components'
import React, { ReactNode, useId } from 'react'
import './Select.css'
import { Form } from 'react-bootstrap'
interface Props {
    label?: string
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
    className?: string
    style?: {}
    children: ReactNode
    defaultValue?: string
    disabled?: boolean
}
export const Select = (props: Props) => {
    const id = useId()
    const select = document.getElementById(id) as HTMLSelectElement
    return (
        <>
            <Form.Select
                style={(select && select.value === "0") ? { padding: "0 0.5rem" } : {}}
                id={id}
                onChange={props.onChange}
                defaultValue={props.defaultValue}
                className={`floating-select ${props.className} `}
                disabled={props.disabled}
            >
                {props.children}
            </Form.Select>
            <label className='floating-label active'>{(select && !(select.value === "0")) ? props.label : ""}</label>
        </>
    )
}