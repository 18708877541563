import React from 'react';
import { AssortmentType } from '../../../entities/assortment_type';
import { Accordion, AccordionTab } from 'primereact/accordion';
import AssortmentTypeListItem from '../../ListItems/AssortmentTypeListItem/AssortmentTypeListItem';
import { Button } from '../../../components/Button/Button';
import { UseIcons } from '../../../hooks/UseIcons';

interface AssortmentTypeListProps {
    assortment_types: AssortmentType[]
    setAAdd?: (a: boolean) => void
    setSelectedAssortmentType?: (a : number) => void
}

const AssortmentTypeList = (props: AssortmentTypeListProps) => {

    const icons = UseIcons()

    const accordionTabHeaderTemplate = (header: string, aTypeID: number) => {
        return (
            <div className='department-list-header'>
                <label className='department-list-header-title'>{aTypeID}.{header}</label>
                {(props.setAAdd && props.setSelectedAssortmentType) ? 
                    <Button className='department-list-header-button' buttonType='white-shadowed' onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (props.setAAdd !== undefined && props.setSelectedAssortmentType !== undefined) {
                            props?.setSelectedAssortmentType(aTypeID)
                            props?.setAAdd(true)
                        }
                    }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ marginRight: "5px"}} src={icons.ADD} alt='avatar' width='30' height='30' />
                            <label>Новый сортамент</label>
                        </div>
                    </Button> : <></>
                }
            </div>
        );
    };

    return (
        <div>
            <Accordion>
                {
                props.assortment_types.map((type) => (
                        <AccordionTab key={type.ID}
                                    headerTemplate={accordionTabHeaderTemplate(type.name, type.ID)}>
                            <AssortmentTypeListItem assortment_type={type} />
                        </AccordionTab>
                    ))
                }
            </Accordion>
        </div>
    );
}

export default AssortmentTypeList;