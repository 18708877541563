import React from 'react';
import {ShortStorage} from "../../../entities/storage";
import './GeneralDetailTypeInfo.css'

interface GeneralDetailTypeInfoProps {
    name: string,
    number: string,
    storage: ShortStorage
}

const GeneralDetailTypeInfo = (props: GeneralDetailTypeInfoProps) => {
    return (
        <div>
            <div className="dt-general-info-container" style={{ flexDirection: "column", color: "white" }}>
                <h3>Название: {props.name}</h3>
                <h3>Обозначение: {props.number}</h3>
            </div>
            <div className="dt-general-info-container" style={{ flexDirection: "column", color: "white" }}>
                <h3>Склад: {props.storage.name}</h3>
                <h3>Адрес: {props.storage.address}</h3>
            </div>
        </div>
    )
}

export default GeneralDetailTypeInfo;