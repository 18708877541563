import React from 'react';
import {List, MantineProvider} from "@mantine/core";
import {DetailCount} from "../../entities/detail";
import DetailCountListItem from "./DetailCountListItem/DetailCountListItem";

interface DetailCountListProps {
    details_with_count: DetailCount[]
}
const DetailCountList = (props: DetailCountListProps) => {
    return (
        <MantineProvider>
            <List listStyleType="none" className="department-list">
                {props.details_with_count?.map(detail => (
                    (detail.ID)? <DetailCountListItem detail={detail}/> : <></>
                ))}
            </List>
        </MantineProvider>
    )
}

export default DetailCountList