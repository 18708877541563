import React, {ComponentType, useState} from "react";
import {Operation, OperationType} from "../../../entities/operation";
import './NewOperationForm.css'
import { Button } from "../../../components/Button/Button";
import { UseIcons } from "../../../hooks/UseIcons";
interface NewOperationsFormProps {
    operations: Operation[];
    setOperations: (newOperations: Operation[]) => void;
}
const NewOperationsForm: ComponentType<NewOperationsFormProps> = (props) => {

    const icons = UseIcons()
    const [isChange, setIsChange] = useState(false);

    const onOperationsTypeChange = async (id: number, type: string) => {
        const newOperations = props.operations
        newOperations[id].type = type
        setIsChange(!isChange)
        props.setOperations(newOperations)

    }
    const onOperationsAddressChange = async (id: number, address: string) => {
        const newOperations = props.operations
        newOperations[id].address = address
        setIsChange(!isChange)
        props.setOperations(newOperations)

    }
    const onOperationsNumberChange = async (id: number, number: string) => {
        const newOperations = props.operations
        newOperations[id].number = number
        setIsChange(!isChange)
        props.setOperations(newOperations)

    }
    const handleOperationAdd = async (e: React.FormEvent) => {
        e.preventDefault();
        const newOperation: Operation = {
            ID: null,
            type: "",
            number: "",
            address: "",
            status: false
        };
        props.setOperations([...props.operations, newOperation])
    }

    const handleOperationDelete = async (index: number) => {
        props.setOperations(props.operations.filter((item, i) => i !== index));

    }
    return (
        <>

                <Button buttonType="white-shadowed" className="w-100" type="button" onClick={handleOperationAdd}>Добавить операцию</Button>
                {props.operations.map((operation, index) => (
                    <div className="operations-form">
                        <div className="operations-form mb-2" key={operation.type}>
                            <p>Тип: </p>
                            <select
                                id={`operation-${operation.type}`}
                                className="form-control"
                                value={operation.type}
                                onChange={(e) => onOperationsTypeChange(index, e.target.value)}
                                required
                            >
                                <option value="" disabled>Выберите тип операции</option>
                                {Object.values(OperationType).map((operationType) => (
                                    <option key={operationType} value={operationType}>{operationType}</option>
                                ))}
                            </select>
                        </div>
                        <div className="operations-form mb-2">
                            <p>Номер: </p>
                            <input
                                id="OperationNUmber"
                                className="form-control"
                                value={operation.number}
                                onChange={(e) => onOperationsNumberChange(index, e.target.value)}
                                required
                            />
                        </div>
                        <Button buttonType="white-shadowed" className="delete-operation-button" type="button" key={index} onClick={(e) => {
                            e.preventDefault()
                            handleOperationDelete(index)
                        }}>
                            <img src={icons.DELETE} alt="ChatLogo" width="30" height="30"/>
                        </Button>
                    </div>
                ))}
        </>
    )
};

export default NewOperationsForm;