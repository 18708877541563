import React, { FormEvent, useState } from 'react';
import { Input } from '../../../components/Input/Input';
import { Roles } from '../../../schemas/Roles';
import { Select } from '../../../components/Select/Select';
import { Button } from '../../../components/Button/Button';
import './RegistrationForm.css'
import { instance } from '../../../api/config';
import { User } from '../../../entities/user';


interface RegistrationFormProps {
    setIsAdd: (value: boolean) => void;
    user?: User;
}
const RegistrationForm = (props: RegistrationFormProps) => {

    const [login, setLogin] = useState(props?.user?.username || "")
    const [password, setPassword] = useState("")
    const [role, setRole] = useState(props?.user?.role || 0)
    const [name, setName] = useState(props?.user?.name || "")
    const [surname, setSurname] = useState(props?.user?.surname || "")
    const [email, setEmail] = useState(props?.user?.email || "")
    const [phone, setPhone] = useState(props?.user?.phone || "")
    const [telegram, setTelegram] = useState(props?.user?.telegram || "")

    const handleRegistration = async () => {
        const resp = await instance.post("/api/register", {
            "username": login,
            "password": password,
            "role": role,
            "name": name,
            "surname": surname,
            "email": email,
            "phone": phone,
            "telegram": telegram,
        })
        if (resp.status === 200) {
            alert("Регистрация прошла успешно!")
        } else {
            alert("Ошибка регистрации!")
        }
        props.setIsAdd(false)
    }
    const handleUpdate = async () => {
        const resp = await instance.patch(`/api/users`, {
            "ID": props?.user?.ID,
            "username": login,
            "role": role,
            "name": name,
            "surname": surname,
            "email": email,
            "phone": phone,
            "telegram": telegram,
        })
        if (resp.status === 200) {
            alert("Изменения сохранены!")
        } else {
            alert("Ошибка сохранения изменений!")
        }
        props.setIsAdd(false)
    }
    return (
        <div style={{ marginTop: "10px"}}>
            <div className='registration-input-form'>
                <Input
                    defaultValue={login}
                    placeholder="Введите логин пользователя" 
                    label="Логин"
                    onChange={(e) => setLogin(e.target.value)}
                />
            </div>
            <div className='registration-input-form'>
                <Select
                    label="Должность"
                    onChange={(e) => setRole(parseInt(e.target.value)-1)}
                    defaultValue={`${role}`}
                >
                    <option value="0" disabled>Выберите должность</option>
                    {Object.keys(Roles).map((id) => (
                        <option key={id} value={`${parseInt(id)+1}`}>{Roles[parseInt(id)]}</option>
                    ))}
                </Select>
            </div>
            <div className='registration-input-form'>
                <Input
                    defaultValue={name}
                    placeholder="Введите Имя" 
                    label="Имя"
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className='registration-input-form'>
                <Input 
                    defaultValue={surname}
                    placeholder="Введите Фамилию" 
                    label="Фамилия"
                    onChange={(e) => setSurname(e.target.value)}
                />
            </div>
            <div className='registration-input-form'>
                <Input 
                    defaultValue={email}
                    placeholder="Введите Почту" 
                    label="Почта"
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className='registration-input-form'>
                <Input 
                    defaultValue={phone}
                    placeholder="Введите Телефон" 
                    label="Телефон"
                    onChange={(e) => setPhone(e.target.value)}
                />
            </div>
            <div className='registration-input-form'>
                <Input 
                    defaultValue={telegram}
                    placeholder="Введите Телеграм" 
                    label="Телеграм"
                    onChange={(e) => setTelegram(e.target.value)}
                />
            </div> 
            <div className='registration-input-form'>
                <Input 
                    defaultValue={password}
                    placeholder="Введите пароль" 
                    label="Пароль"
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            {(props?.user) ? 
                <Button style={{width: "100%"}} onClick={handleUpdate} buttonType="white-shadowed">
                    Сохранить
                </Button> 
            : 
                <Button style={{width: "100%"}} onClick={handleRegistration} buttonType="white-shadowed">
                    Зарегистрировать
                </Button>
            }
        </div>
    )
}

export default RegistrationForm