import React from 'react';
import GeneralStorageInfo from "./GeneralStorageInfo/GeneralStorageInfo";
import {LongStorage, ShortStorage} from "../../entities/storage";
import StorageDetailsList from "./StorageDetailsList/StorageDetailsList";

interface StorageComponentProps {
    storage: ShortStorage
}

const StorageComponent = (props: StorageComponentProps) => {

    return (
        <div>
            <div style={{ marginTop: "1rem" }}>
                <GeneralStorageInfo name={props.storage?.name || ""} address={props.storage?.address || ""} />
            </div>
            <div>
                <StorageDetailsList details_with_count={props.storage?.details_with_count || []}/>
            </div>
        </div>
    )
}

export default StorageComponent;