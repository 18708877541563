import {NewRouteList, RouteList} from "./route_list";

export interface DetailType {
    ID: number | null;
    name: string | null;
    number: string | null;
    route_list: RouteList;
}

export const NewDetailType = () : DetailType => {
    return {
        ID: null,
        name: null,
        number: null,
        route_list: NewRouteList(),
    }
}

export interface DetailTypeByID {
    [ID: number]: DetailType
}

export function getDetailTypeByID(detailTypes: DetailType[]): DetailTypeByID{
    const detailTypeByID: DetailTypeByID = {};

    detailTypes.forEach(detailType => {
        if (detailType.ID) {
            detailTypeByID[detailType.ID] = detailType;
        }
    });

    return detailTypeByID;
}