import React, {useEffect, useState} from 'react';
import NavigationBar from "../components/NavigationBar";
import TasksList from "../components/TasksList";
import {navItems} from "../schemas/NavigationBar";
import '../css/Tasks.css'
import {useAuth} from "../context/AuthProvider";
import {instance} from "../api/config";
import {Task} from "../entities/task";
import {Route, Routes} from "react-router-dom";
import LoginPage from "./LoginPage";
import RequireAuth from "../components/RequireAuth";
import NewTaskPage from "./NewTaskPage";
import {RoutesMap} from "../routes/AppRoutes";

const TasksPage = () => {
    const { user }= useAuth()

    const [tasks, setTasks] = useState<Task[] | null>(null);

    useEffect(() => {
        const getUserTasks = async () => {
            const resp = await instance.get("/api/users/current")
            setTasks(resp.data.tasks)
        }
        getUserTasks()
    }, []);

    if (user === null) {
        return <div>Не авторизован</div>;
    }

    return (
        <TasksList tasks={tasks} />
    );
};

export default TasksPage;