import React, {useEffect, useState} from "react";
import TaskListItem from "../TaskListItem";
import DetailListItem from "./DetailListItem/DetailListItem";
import {Detail, DetailByID, NewDetail} from "../../entities/detail";
import './DetailList.css'
import {instance} from "../../api/config";
import {ShortStorage} from "../../entities/storage";
import Form from "react-bootstrap/Form";
import NewOperationsForm from "./NewOperationsForm/NewOperationsForm";
import {Operation} from "../../entities/operation";
import {DetailType, NewDetailType} from "../../entities/detail_type";
import {useNavigate} from "react-router-dom";
import {StorageTypes} from "../../entities/storage";
interface DetailListProps {
    details: Detail[]
    setDetails: (newDetails: Detail[]) => void;
}

const DetailList: React.FC<DetailListProps> = (props: DetailListProps) => {

    const navigate = useNavigate()

    const [isAdd, setIsAdd] = React.useState(false);
    const [detailType, setDetailType] = React.useState<DetailType>(NewDetailType);
    const [isChanged, setIsChanged] = React.useState(false);

    const [isDelete, setIsDelete] = useState(false);
    const [deleteDetailType, setDeleteDetailType] = useState<DetailType>()
    const [storageList, setStorageList] = useState<ShortStorage[]>([])
    const [storage, setStorage] = React.useState<ShortStorage>()
    const [operations, setOperations] = useState<Operation[]>([]);
    useEffect(() => {
        const getStorages = async () => {
            const resp = await instance.get("/api/storage/all")
            setStorageList(resp.data.filter((item: ShortStorage) => item.storage_type === StorageTypes.Details))
        }
        getStorages()
    }, []);

    const handleNewDetail = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsAdd(true);
        setDetailType(NewDetailType());
    }

    const handleClose = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsAdd(false);
    }

    const onDetailNameChange = (name: string) => {
        const newDetailType = detailType;
        newDetailType.name = name;
        setDetailType(newDetailType);
        setIsChanged(!isChanged)
    }

    const onDetailNumberChange = (number: string) => {
        const newDetailType = detailType;
        newDetailType.number = number;
        setDetailType(newDetailType);
        setIsChanged(!isChanged)
    }

    const handleSave = async (e: React.FormEvent) => {
        const newDetailRequestData = {
            name: detailType.name,
            number: detailType.number,
            route_list: {
                number: detailType.number,
                operations: operations,
                storage_id: storage?.ID,
            }
        }
        await instance.post("/api/detailTypes", newDetailRequestData);
        setIsAdd(false)
        const respAllDetails = await instance.get("/api/detailTypes/all")

        props.setDetails(respAllDetails.data)
        setIsChanged(!isChanged)
        setDetailType(NewDetailType());
        setStorage(undefined)
        setOperations([])
    }

    const handleDeleteClose = async (e: React.FormEvent) => {
        e.preventDefault()
        setIsDelete(false);
    }

    const handleDelete = async (ID: number) =>  {
        setIsDelete(false);
        const resp = instance.delete("/api/detailTypes", { data: {
                "ID": ID,
            }})
        setIsChanged(true)
    }
    return (
    <div>
        <div style={{margin: "10px"}}>
            <button
                className='mb-3 nav-link active task-box'
                data-bs-toggle="tab"
                data-bs-target={`#Новая-деталь-tab-pane`}
                type="button"
                role="tab"
                aria-controls={`Новая-деталь-tab-pane`}
                aria-selected='true'
                onClick={handleNewDetail}
            >
                Новая деталь
            </button>
            {props.details.map((detail: Detail) => (
                <div style={{ display: "flex", justifyContent: "space-between"}}>
                <div className="task-box" key={detail.ID || 0} style={{ width: "100%"}}>
                    <div onClick={(e) => {
                        e.preventDefault()
                        navigate("/details/" + detail.ID)
                    }}>
                        <DetailListItem ID={detail.ID || 0} name={detail.name || ""} number={detail.number || ""}/>
                    </div>
                </div>
                    <button className="mb-2 btn btn-lg delete-operation-button" type="button" key={detail.ID} onClick={(e) => {
                        e.preventDefault()
                        setIsDelete(true)
                        setDeleteDetailType(detailType)
                         }}>
                        <img src="/delete.png" alt="ChatLogo" width="25" height="25"/>
                    </button>
                </div>
            ))}
        </div>
        {(isAdd) ?
            <div className="modal display-block">
                <div className="modal-main">
                    <h2>Новая деталь</h2>
                    <div className="new-detail-form-container" style={{width: "50%"}}>
                        <p>Название детали</p>
                        <input
                            id="DetailName"
                            className="form-control"
                            value={detailType.name || ""}
                            onChange={(e) => onDetailNameChange(e.target.value)}
                            required
                        />
                        <p>Обозначение детали</p>
                        <input
                            id="DetailNumber"
                            className="form-control"
                            value={detailType.number || ""}
                            onChange={(e) => onDetailNumberChange(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <p style={{color: "white"}}>Тип склада</p>
                        <Form.Select aria-label="Task Type"
                                     id="taskType"
                                     className="form-control"
                                     value={storage?.name || ""}
                                     onChange={(e) => setStorage(storageList[parseInt(e.target.value)])}
                                     required
                        >
                            <option value="" disabled>Выберите склад</option>
                            {Object.values(storageList).map((storage, index) => (
                                <option key={index} value={index}>{storage.name}</option>
                            ))}
                        </Form.Select>
                    </div>
                    <div>
                        <NewOperationsForm operations={operations} setOperations={setOperations}/>
                    </div>
                    <div className="new-detail-button-container" style={{display: "flex", width: "50%"}}>
                        <button className="w-50 btn btn-lg details-default-button" onClick={handleClose}>Закрыть
                        </button>
                        <button className="w-50 btn btn-lg details-default-button" onClick={handleSave}>Сохранить
                        </button>
                    </div>

                </div>
            </div>
            : <></>}
        {(isDelete) ?
            <div className="modal display-block">
                <div className="modal-main">
                    <h2>Удалить деталь "{deleteDetailType?.name}" ?</h2>
                    <div className="new-detail-button-container" style={{display: "flex", width: "50%"}}>
                        <button className="w-50 btn btn-lg details-default-button" onClick={handleDeleteClose}>Нет
                        </button>
                        <button className="w-50 btn btn-lg details-default-button" onClick={async (e) => {
                            e.preventDefault()
                            setIsChanged(!isChanged)
                            setIsDelete(false)
                                handleDelete(deleteDetailType?.ID || 0)
                                setDeleteDetailType(NewDetailType())
                                props.setDetails(props.details.filter((detail) => detail.ID !== deleteDetailType?.ID))
                            }}>Да</button>
                        </div>
                    </div>
                </div>
                : <></>
            }
    </div>
    )
}

export default DetailList