import React from 'react';
import UserProfile from "../components/UserProfile/UserProfile";
import {useAuth} from "../context/AuthProvider";
import {NewUser} from "../entities/user";

const UserProfilePage = () => {

    const {user} = useAuth()
    return (
        <UserProfile user={user || NewUser()}/>
    )
}

export default UserProfilePage;