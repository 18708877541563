import React from 'react';
import DetailCountList from "../DetailCountList";
import {DetailCount} from "../../../entities/detail";
import './DetailCountListItem.css'
import DetailStatus from "./DetailStatus/DetailStatus";

interface DetailCountListProps {
    detail: DetailCount
}
const DetailCountListItem = (props: DetailCountListProps) => {
    return (
        <div className={(props.detail.status === "Выполнено") ? "detail" : "detail-in-progress"}>
            <label className="detail-name">{props.detail.detailType?.name} : {props.detail.detailType?.number}</label>
            <div className="detail-info">
                <DetailStatus status={props.detail.status} />
                <label className="detail-label">Количество: {props.detail.count}</label>
            </div>
        </div>
    )
}

export default DetailCountListItem;