import React from 'react';
import {ShortStorage} from "../../../entities/storage";
import ProgressBar from "react-bootstrap/ProgressBar";
import {List, MantineProvider} from "@mantine/core";
import './StorageListItem.css'
import DetailCountList from "../../DetailCountList/DetailCountList";
interface StorageListItemProps {
    storage: ShortStorage
}
const StorageListItem = (props: StorageListItemProps) => {
    return (
        <div className="department">
            <div className="department-header">
                <label className="department-name-label">{props.storage.name} : {props.storage.address}</label>
            </div>
                <DetailCountList details_with_count={props.storage.details_with_count || []} />
        </div>
    )
}

export default StorageListItem;