import React from "react";
import {List, MantineProvider} from "@mantine/core";
import {DetailCount} from "../../../entities/detail";
import DetailCountList from "../../DetailCountList/DetailCountList";

interface StorageDetailsListProps {
    details_with_count: DetailCount[]
}
const StorageDetailsList = (props: StorageDetailsListProps) => {
    return (
        <DetailCountList details_with_count={props.details_with_count} />
    )
}

export default StorageDetailsList;