import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {FullTaskResponse} from "../entities/task";
import {instance} from "../api/config";
import TaskComponent from "../components/Task/Task";
import DetailList from "../components/DetailList/DetailList";
import {Detail} from "../entities/detail";

const DetailTypesPage = () => {

    const navigate = useNavigate();
    const [details, setDetails] = useState<Detail[]>([])
    useEffect(() => {
        const getAllDetails = async () => {
            const resp = await instance.get("/api/detailTypes/all")
            setDetails(resp.data)
        }
        getAllDetails()
    }, []);
    if (details === undefined) {
        return (
            <> Ошибка, обратитесь к администратору</>
        )
    }
    return (
        <>
            <DetailList details={details} setDetails={setDetails}></DetailList>
        </>
    );
};

export default DetailTypesPage;