import React from "react";
import Form from "react-bootstrap/Form";
import {Project} from "../../../entities/project";
import {User} from "../../../entities/user";
import "./GeneralTaskInfo.css"
interface GeneralTaskInfoProps {
    project: Project,
    responsible: User,
    progress: number,
    setProgress: React.Dispatch<React.SetStateAction<number>>
    desciption: string
}
const GeneralTaskInfo: React.FC<GeneralTaskInfoProps> = (props: GeneralTaskInfoProps) => {

    return (
        <div>
            <div className="general-info-container">
                <div className="sub-general-container">
                    <h4 style={{color: "white"}}>Проект</h4>
                    <h3 style={{color: "white"}}> {props.project.name}</h3>
                </div>
                <div className="sub-general-container">
                    <h4 style={{color: "white"}}>Ответственное лицо</h4>
                    <h3 style={{color: "white"}}>{(props.responsible.name && props.responsible.surname )? `${props.responsible.name} ${props.responsible.surname}` : props.responsible.username}</h3>
                </div>
                <div className="sub-general-container">
                    <h4 style={{color: "white"}}>Прогресс</h4>
                    <h3 style={{color: "white"}}> {props.progress}%</h3>
                    <Form.Range className="progress-range" value={props.progress} onChange={(e) => props.setProgress(parseInt(e.target.value))}/>
                </div>
            </div>
            <div style={{ marginTop: "10px", marginLeft: "25px", marginRight: "25px" }}>
                <div className="sub-general-description ">
                    <h4 style={{color: "white"}}>Описание</h4>
                    <h3 style={{color: "white"}}> {props.desciption}</h3>
                </div>
            </div>
        </div>
    )
};

export default GeneralTaskInfo;