// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

}

.details-default-button:hover {
    background-color: #F65727;
    color: #1d2124 !important;
    border: solid 1px #F65727 !important;
    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727 !important;
}

.details-default-button:active {
    background-color: #212529;
    color: #F65727;

}

.details-default-button{
    background-color: #F65727;
    color: #212529;
    width: auto;
    margin-right: 10px;
}

.modal-main {
    background: #212529;
    width: auto;
    height: auto;
    padding: 40px;
    border-radius: 10px;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.new-detail-form-container {
    width: 100% !important;
}

.new-detail-form-container p {
    margin-top: 5px;
    margin-bottom: 0;
}

.new-detail-form-container input {
    margin-top: 0;
    margin-bottom: 10px;
}

.new-detail-button-container {
    width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/DetailList/DetailList.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,oCAAoC;;AAExC;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,oCAAoC;IACpC,8DAA8D;AAClE;;AAEA;IACI,yBAAyB;IACzB,cAAc;;AAElB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".modal {\n    display: flex !important;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    width: 100%;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n\n}\n\n.details-default-button:hover {\n    background-color: #F65727;\n    color: #1d2124 !important;\n    border: solid 1px #F65727 !important;\n    box-shadow: inset 0 0 5px #F65727, 0 0 10px #F65727 !important;\n}\n\n.details-default-button:active {\n    background-color: #212529;\n    color: #F65727;\n\n}\n\n.details-default-button{\n    background-color: #F65727;\n    color: #212529;\n    width: auto;\n    margin-right: 10px;\n}\n\n.modal-main {\n    background: #212529;\n    width: auto;\n    height: auto;\n    padding: 40px;\n    border-radius: 10px;\n}\n\n.display-block {\n    display: block;\n}\n\n.display-none {\n    display: none;\n}\n\n.new-detail-form-container {\n    width: 100% !important;\n}\n\n.new-detail-form-container p {\n    margin-top: 5px;\n    margin-bottom: 0;\n}\n\n.new-detail-form-container input {\n    margin-top: 0;\n    margin-bottom: 10px;\n}\n\n.new-detail-button-container {\n    width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
