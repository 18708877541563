import React, {useEffect, useState} from 'react';
import {useAuth} from "../../context/AuthProvider";
import {instance} from "../../api/config";
import {Task} from "../../entities/task";
import {useNavigate, useParams} from "react-router-dom";
import {NewProject, Project} from "../../entities/project";
import {Panel} from "primereact/panel";
import { Form } from 'react-bootstrap';
import {Tag} from "primereact/tag";
import {Tree} from "primereact/tree";
import TaskListItem from '../../widgets/ListItems/TaskListItem/TaskListItem';
import { Button } from '../../components/Button/Button';
import './ProjectTasksPage.css'
import { FieldGeneral, FieldMain, FieldRoot } from '../../components/Field/Fields';
import { UseIcons } from '../../hooks/UseIcons';
import { Username } from '../../components/Username/Username';

type Params = {
    id: string;
    departmentName: string;
};

const ProjectTasksPage = () => {

    const icons = UseIcons()
    const navigate = useNavigate();

    const {user} = useAuth()
    const {id, departmentName} = useParams<Params>()
    const [project, setProject] = useState<Project>(NewProject())
    const [tasks, setTasks] = useState<Task[]>([])
    const [allTasks, setAllTasks] = useState<Task[]>([])

    useEffect(() => {
        const getUserTasks = async () => {
            const resp = await instance.get("/api/projects", {
                params: {
                    ID: id
                }
            })
            setProject(resp.data)
            setTasks(resp.data.tasks)
            setAllTasks(resp.data.tasks)
        }
        getUserTasks()
    }, [id]);

    interface treeListNodesMap {
        key: number,
        taskName: string,
        taskType: string,
        finishDate: string,
        taskProgress: number,
        children?: treeListNodesMap[]
    }

    const transformObject = (nestedObjects: Task[]): treeListNodesMap[] => {
        let objects: treeListNodesMap[] = [];
        nestedObjects.forEach(object => {

                if (object.child_tasks && object.child_tasks.length) {
                    objects.push({
                        key: object.ID,
                        taskName: object.name,
                        taskType: object.type,
                        finishDate: object.finish_date,
                        taskProgress: object.progress,
                        children: transformObject(object.child_tasks)
                    })
                } else {
                objects.push({
                    key: object.ID,
                    taskName: object.name,
                    taskType: object.type,
                    finishDate: object.finish_date,
                    taskProgress: object.progress
                });}
        })
        
        return objects;
    };
    

    if (user === null) {
        return <div>Не авторизован</div>;
    }

    const handleNewTask = async () => {
        navigate(`/tasks/new/0/0`)
    }

    const handleTaskClick = (id: number) => {
        navigate(`/tasks/${id}`)
    }

    const panelHeaderTemplate = (options: any) => {
        const className = `${options.className} justify-content-space-between`;


        return (
            <FieldGeneral className='search-field'>
                <div className='task-header'>
                    <div className='task-header-search'>
                        <Form.Control
                            className="project-header-search-form"
                            placeholder='Поиск по план графику'
                            onChange={(e) => {
                                const newTasks = allTasks?.filter(task => task.name.toLowerCase().includes(e.target.value.toLowerCase()) || task.ID === parseInt(e.target.value))
                                setTasks(newTasks || null)
                            }}
                        >

                        </Form.Control>
                    </div>
                </div>
            </FieldGeneral>
        );
    };

    const nodeTemplate = (node: any) => {
        return <span className='content-tree'>
            <TaskListItem
                ID={node.key}
                name={node.taskName}
                type={node.taskType}
                date={node.finishDate}
                progress={node.taskProgress}
                
            />
                <Button className='subtask-header-button' buttonType='white-shadowed' onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    }}>
                    <div style={{ alignItems: "center"}}>
                        <img style={{ marginRight: "5px"}} src={icons.ADD} width="30" height="30"></img>
                        <label>Новая подзадача</label>
                    </div>
                </Button>
            </span>;
    }

    return (
        <FieldRoot className='project-header-info-field'>
            <FieldMain className='project-header-info-field-main'>
                <div className='project-header-info'>
                    <div className='project-header-info-depart'>
                        <label style={{fontSize:'x-large'}}>Отдел: </label>
                        &nbsp;
                        <h1>{project.department?.name}</h1>
                    </div>
                    <div className='project-header-info-proj'>
                        <label>Проект:</label>
                        <Tag className='project-header-info-tag'
                            value={project.name}/>
                    </div>
                    <div className='project-header-info-team'>
                        <h2>Команда проекта:</h2>
                        {project.users?.map(user => (
                            <h2>&nbsp;{Username(user)}</h2>
                        ))}
                    </div>
                </div>
                <div className='project-header-buttons' >
                    <Button style={{marginBottom: "10px"}} className="new-department-button">Завершить проект</Button>
                    <Button buttonType="white-shadowed" className="new-department-button-second" onClick={handleNewTask}>Новая задача</Button>
                </div>           
            </FieldMain>
            <Panel className='p-panel-content' headerTemplate={panelHeaderTemplate}>
                <FieldGeneral className='tree-style'>
                    <Tree value={transformObject(tasks.filter(obj => !obj.parent_task_id))} nodeTemplate={nodeTemplate} className='peace-of-tree' contentClassName='content-tree-field'/>
                </FieldGeneral>
            </Panel>
        </FieldRoot>
      
    );
};

export default ProjectTasksPage;