import React from 'react';
import { AssortmentType } from '../../../entities/assortment_type';
import AssortmentList from '../../Lists/AssortmentList/AssortmentList';

interface AssortmentTypeListItemProps {
    assortment_type: AssortmentType
}
const AssortmentTypeListItem = (props: AssortmentTypeListItemProps) => {
    if (props.assortment_type.assortments === null || props.assortment_type.assortments.length === 0) {
        return (
            <div className='department-list-item-error-text'>Сортамента данного типа нет.</div>
        );
    } else {
    return (
        <div>
            <AssortmentList assortments={props.assortment_type.assortments} />
        </div>
    );
    }
}

export default AssortmentTypeListItem;