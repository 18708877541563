import React from 'react'

interface GeneralStorageInfoProps {
    name: string
    address: string
}

const GeneralStorageInfo = (props: GeneralStorageInfoProps) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", color: "white" }}>
            <h1>{props.name}</h1>
            <h3>{props.address}</h3>
        </div>
    )
}

export default GeneralStorageInfo;