import React, {useState} from 'react';

import {useAuth} from "../context/AuthProvider";


const LoginPage = () => {
    const { loginUser } = useAuth()

    const [user, setUser] = useState<string | null>(null)
    const [password, setPassword] = useState<string | null>(null)
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (user && password) {
            await loginUser(user, password)
        }
    };

    return (
        <main className="form-signin">
        <form onSubmit={handleSubmit}>
            <div className="logo">
                <a href="https://www.imzrb.ru/">
                    <img src="/brand.svg" alt="IMZboxlogo" width="220" height="220" />
                </a>
            </div>
            <div className="inputGroup">
                <div className="form-floating">
                    <input
                        type="login"
                        className="form-control"
                        id="emailInput"
                        placeholder="Email address"
                        required
                        onChange={e => setUser(e.target.value)}
                    />
                    <label htmlFor="emailInput">Логин</label>
                </div>
                <div className="form-floating">
                    <input
                        type="password"
                        className="form-control"
                        id="passwordInput"
                        placeholder="Password"
                        required
                        onChange={e => setPassword(e.target.value)}
                    />
                    <label htmlFor="passwordInput">Пароль</label>
                </div>
                <label className="login-label">Забыли пароль?</label>
                <div className="login-checkbox">
                    <input type="checkbox" id="saveLoginCheckbox" />
                    <label htmlFor="saveLoginCheckbox">Запомнить меня</label>
                </div>
            </div>
            <button className="w-100 btn btn-lg btn-primary" type="submit">Войти</button>
        </form>
        </main>
    );
};

export default LoginPage;
