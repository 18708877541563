import {Task} from "./task";
import {User, UserByID} from "./user";

export interface ProjectResponse {
    ID: number | null,
    name: string | null,
    number: string | null,
    finish_date: string | null,
    department_id: number | null,
    director_id: number | null,
    lead_designer_id: number | null,
    progress: number,
    tasks: Task[] | undefined,
    users: User[] | null
}

export type Project = ProjectResponse;

export interface ProjectCreateResponse {
    "created": boolean,
    "error": string
}

export interface UserToAdd {
    "ID": number,
}

export interface AddProjectRequest {
    "name": string,
    "department_id": number,
    "director_id": number,
    "lead_designer_id": number,
    "finish_date": string,
    "progress":number,
    "number": string,
    "users": UserToAdd[]
}

export interface ProjectUpdateResponse {
    "updated": boolean,
    "error": string
}

export interface ProjectDeleteResponse {
    "deleted": boolean,
    "error": string
}

export const NewProject = () => {
    return {
        ID: null,
        name: null,
        number: null,
        finish_date: null,
        department_id: null,
        director_id: null,
        lead_designer_id: null,
        progress: 0,
        tasks: undefined,
        users: null
    }
};

export interface ProjectByID {
    [ID: string]: Project;
}


export function getProjectByID(projects: Project[]): ProjectByID{
    const projectsByID: ProjectByID = {};


    // Group tasks by project name
    projects.forEach(project => {
        if (project.ID) {
            projectsByID[project.ID] = project;
        }
    });

    return projectsByID;
}