import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { MultiValue } from 'react-select';
import makeAnimated from "react-select/animated";
import Select from 'react-select';
import { AddWorkplaceRequest, WorkplaceOperationType } from '../../../entities/workplaces';
import { instance } from '../../../api/config';
import { UserToAdd } from '../../../entities/project';
import { getOptionsByUsers, User } from '../../../entities/user';
import { Button } from '../../../components/Button/Button';
import { OperationTypes } from '../../../entities/operation';
import './NewWorkPlaceForm.css'
import { UseIcons } from '../../../hooks/UseIcons';
import { Input } from '../../../components/Input/Input';

interface NewWorkPlaceFormProps {
    setAddFormActivated: (value: boolean) => void;
}

const NewWorkPlaceForm = (props: NewWorkPlaceFormProps) => { 

    const icons = UseIcons()
    const [users, setUsers] = useState<User[]>([])
    const [usersSelectedOptions, setUsersSelectedOptions] = useState<MultiValue<{
        value: number;
        label: string;
    }> | null>(null);
    const [typesSelectedOptions, setTypesSelectedOptions] = useState<MultiValue<{
        value: number;
        label: string;
    }> | null>(null);
    const [newWorkPlaceName, setNewWorkPlaceName] = useState<string>()
    const [newWorkPlaceType, setNewWorkPlaceType] = useState<string>()
    const [newWorkPlaceNumber, setNewWorkPlaceNumber] = useState<string>()
    const [newWorkPlaceAdress, setNewWorkPlaceAdress] = useState<string>()
    
    useEffect(() => {
        const GetAllUsers = async () => {
            const response = await instance.get('/api/users/all')
            setUsers(response.data)
        }
        GetAllUsers()
    }, [])

    const createWorkplace = async (selectedUsers: UserToAdd[], selectedTypes: WorkplaceOperationType[]) => {
        const newProject: AddWorkplaceRequest = {
            name: newWorkPlaceName || "",
            operation_types: selectedTypes,
            address: newWorkPlaceAdress || "",
            number: newWorkPlaceNumber || "",
            users: selectedUsers,
        };

        try {
            await instance.post('/api/workPlaces', newProject);
        } catch (error) {
            console.error('Error creating workplace:', error);
        }
    };

    let usersSelOptions;
    if (users !== null) {
        usersSelOptions = getOptionsByUsers(users);
    }

    const selectStyles = {
        control: (base:any) => ({
            ...base,
            color: 'var(--primary-text-color)',
            border: "2px solid #F65727",
            borderRadius: "8px",
            marginBottom: "20px",
            backgroundColor: 'var(--base-general-color)',
        }),
        multiValue: (base:any) => ({
            ...base,
            backgroundColor: 'var(--base-general-color)',
        }),
        multiValueLabel: (base:any) => ({
            ...base,
            color: 'var(--primary-text-color)',
            backgroundColor: 'var(--base-main-color)',
        }),
        menuList: (base:any) => ({
            ...base,
            backgroundColor: 'var(--base-general-color)',
        }),
        option: (base:any) => ({
            ...base,
            backgroundColor:'var(--base-general-color)',
            "&:hover": {
                backgroundColor: 'var(--base-general-color)',
            },
        })
    }
    
    return (
        <div>
                <div style={{ marginBottom: "20px", marginTop: "5px"}}>
                    <Input
                        label='Название рабочего места'
                        placeholder='Введите название рабочего места'
                        onChange={(e) => setNewWorkPlaceName(e.target.value)}
                    />
                </div>
                <div style={{ marginBottom: "20px"}}>
                    <Input
                        label='Aдрес рабочего места'
                        placeholder='Введите адрес рабочего места'
                        onChange={(e) => setNewWorkPlaceAdress(e.target.value)}
                    />
                </div>
                <div style={{ marginBottom: "20px"}}>
                    <Input
                        label='Номер рабочего места'
                        placeholder='Введите номер рабочего места'
                        onChange={(e) => setNewWorkPlaceNumber(e.target.value)}
                    />
                </div>
                <Select
                    closeMenuOnSelect={false}
                    components={makeAnimated()}
                    styles={selectStyles}
                    placeholder="Выберите типы операций"
                    noOptionsMessage={() => "Пользователей не найдено"}
                    defaultValue={typesSelectedOptions}
                    onChange={setTypesSelectedOptions}
                    isMulti
                    options={OperationTypes}
                />
                <Select
                    closeMenuOnSelect={false}
                    components={makeAnimated()}
                    styles={selectStyles}
                    
                    placeholder="Выберите пользователей"
                    noOptionsMessage={() => "Пользователей не найдено"}
                    defaultValue={usersSelectedOptions}
                    onChange={setUsersSelectedOptions}
                    isMulti
                    options={usersSelOptions}
                />
                <Button className='new-workplace-form-button' buttonType='white-shadowed' 
                onClick={(e) => {
                    e.preventDefault();
                    if (usersSelectedOptions && usersSelectedOptions.length > 0) {
                        const selectedUsers: UserToAdd[] = [];
                        const selectedTypes: WorkplaceOperationType[] = [];
                        usersSelectedOptions.forEach((option) => {
                            selectedUsers.push({ID: option.value});
                        });
                        typesSelectedOptions?.forEach((option) => {
                            selectedTypes.push({operation_type: option.label});
                        });
                    createWorkplace(selectedUsers, selectedTypes)
                    props.setAddFormActivated(false);
                }}}
                >
                <div className='new-workplace-form-button-content'>
                    <img style={{ marginRight: "10px"}} src={icons.SAVE} width="30" height="30"></img>
                    Сохранить
                </div>                        
                </Button>
        </div>
    );
}

export default NewWorkPlaceForm;