import React from 'react';
import { User } from '../../../entities/user';
import UsersListItem from '../../ListItems/UsersListItem/UsersListItem';

interface UsersListProps {
    users: User[];
}
const UsersList = (props: UsersListProps) => {
    return (
        <div className='material-types-list' style={{ display: "flex", flexWrap: "wrap" }}>
            {props.users.map((user: User) => (
                <UsersListItem
                    user={user}
                />
            ))}
        </div>
    )
}

export default UsersList;