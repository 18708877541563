import React, { useEffect, useRef, useState } from 'react';
import {useAuth} from "../../context/AuthProvider";
import { FieldGeneral, FieldMain } from '../../components/Field/Fields';
import { Roles } from '../../schemas/Roles';
import { Form } from 'react-bootstrap';
import { instance } from '../../api/config';
import './UserProfilePage.css'
import { Button } from '../../components/Button/Button';
import { useTheme } from '../../hooks/UseTheme';
import DepartmentList from '../../widgets/Lists/DepartmentList/DepartmentList';
import { User } from '../../entities/user';
import ProjectsList from '../../widgets/Lists/ProjectsList/ProjectsList';
import { S3_URL } from '../../entities/file';
import toast from 'react-hot-toast';
import { Department } from '../../entities/department';
import { UseIcons } from '../../hooks/UseIcons';
import { Username } from '../../components/Username/Username';
import { Input } from '../../components/Input/Input';

const UserProfilePage = () => {

    const icons = UseIcons()
    const {logout} = useAuth()

    const [isCredsChange, setIsCredsChange] = useState(false);

    const [user, setUser] = useState<User>();
    const [email, setEmail] = useState(user?.email);
    const [phone, setPhone] = useState(user?.phone);
    const [telegram, setTelegram] = useState(user?.telegram);
    const { theme, setTheme } = useTheme()
    const [avatar, setAvatar] = useState<string>("");
    const [departments, setDepartments] = useState<Department[]>([])
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const getUser = async () => {
            const resp = await instance.get("/api/users/current")
            setUser(resp.data)
            setEmail(resp.data?.email)
            setPhone(resp.data?.phone)
            setTelegram(resp.data?.telegram)
            setAvatar((resp.data?.avatar?.path) ? `${S3_URL}${resp.data?.avatar?.path}` :  "")
        }
        const getDepartments = async () => {
            await getUser()
            const resp = await instance.get("/api/departments/all")
            setDepartments(resp.data)
        }
        getDepartments()
    }, [])

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const _URL = window.URL || window.webkitURL;
            const img = new Image();
            const objectUrl = _URL.createObjectURL(file);
            let isChecked = false;
            img.onload = () => {
                const { width, height } = img;
            
                if (width > 512 || height > 512) {
                    toast("Размер изображения не больше 512x512");
                    return
                }
                isChecked = true

                _URL.revokeObjectURL(objectUrl);
            };
            
            img.onerror = () => {
                toast("Не удалось загрузить изображение. Проверьте файл.");
                _URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
            if (!isChecked) {
                return
            }
            const loadAvatar = async () => {
                const data = new FormData()
                data.append('image', file);
                data.append('metadata',`{ "user_id": ${user?.ID}}`)
                await instance.post('/api/users/upload', data)
            }
            await loadAvatar();
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatar(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
      };

    let filterUserDepartments = (departments: Department[], user?: User) => {
        if (!user) {
            return departments
        }
        const newDepartments = departments
        newDepartments.map((department, index) => {
            const newProjects = department.projects.filter((project) => !!project.users?.filter((project_user) => project_user.ID == user?.ID).length)
            department.projects = newProjects
        })
        console.log(newDepartments)
        const oneMoreDepartments: Department[] = []
        newDepartments.forEach(department => {
            if (department.projects.length > 0) {
                oneMoreDepartments.push(department)
            }
        })
        console.log(oneMoreDepartments)
        return oneMoreDepartments
    }

    const handleSave = async () => {
        const resp = await instance.patch("/api/users", {
            ID: user?.ID,
            telegram: telegram,
            phone: phone,
            email: email,
        })

    }
    
    if (user === undefined) {
        return (<></>)
    }
    const handleLightThemeClick = () => {
        setTheme('light')
    }
    const handleDarkThemeClick = () => {
        setTheme('dark')
    }

    const newDepartments = filterUserDepartments(departments, user)
    return (
        <div className="profile">
            <div className="profile-content">
                <FieldMain className="profile-user">
                    <div className='profile-user-image'>
                        <img src={(avatar) ? avatar : icons.USER} style={{ objectFit: "cover", borderRadius: "8px"}}  alt='avatar' width='100%' height='100%' id="avatar_icon">
                        </img>
                    </div>
                    <Button className='user-load-avatar-button' buttonType='white-shadowed'>
                        <label htmlFor="avatar-upload">
                            Загрузить аватар
                        </label>
                        <input
                                ref={fileInputRef}
                                id="avatar-upload"
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: "none"}}
                                className="hidden"
                        />
                    </Button>
                    <FieldGeneral className='profile-user-info'>
                        <div>
                            <div className='profile-user-info-title'>
                                <h5>{Username(user)}</h5>
                                <h4 style={{ marginBottom: "20px"}}>{Roles[user?.role || 0]}</h4>
                                <h4>Мой профиль: </h4>
                                <hr></hr>
                            </div>
                        
                            <div className='form-info-user'>
                                <div className="profile-user-creds-form">
                                    <Input
                                                    label="Почта"
                                                    placeholder='Введите почту'
                                                    onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="profile-user-creds-form">
                                    <Input
                                                    label="Телефон"
                                                    placeholder='Введите телефон'
                                                    onChange={(e) => setPhone(e.target.value)}

                                    />
                                </div>
                                <div className="profile-user-creds-form">
                                    <Input
                                                    label="Телеграм"
                                                    placeholder='Введите телеграм'
                                                    onChange={(e) => setTelegram(e.target.value)}
                                                    
                                    />
                                </div>
                                <Button className='profile-user-save-button' onClick={(e) => {
                                    setIsCredsChange(false)
                                    handleSave()
                                }}>Сохранить
                                </Button>
                            </div> 
                            </div>
                            <Button
                                    buttonType='alert-button'
                                    className='profile-user-exit-button'
                                    onClick={(e) => (logout())}
                                >
                                    Выход
                            </Button>
                            <div className='profile-user-theme-buttons-group'>
                                <Button  className={`profile-user-theme-light-button ${ ((theme == 'light')) ? 'active' : ''}`} onClick={handleLightThemeClick}>
                                    Light
                                </Button>
                                <Button className={`profile-user-theme-dark-button ${ ((theme == 'dark')) ? 'active' : ''}`} onClick={handleDarkThemeClick}>
                                    Dark
                                </Button>
                            </div>
                    </FieldGeneral>
                </FieldMain>
                {(newDepartments.length) ?
                <FieldMain className="profile-projects">
                    <h2 className='profile-projects-title'>Департаменты и проекты: </h2>
                    <DepartmentList departments={newDepartments} />
                </FieldMain> : <></>
                }
            </div>
            
        </div>
    )
}
export default UserProfilePage;