import React, {useEffect, useState} from 'react';
import {useAuth} from "../context/AuthProvider";
import {instance} from "../api/config";
import {AddDepartmentRequest, Department} from "../entities/department";
import ProjectsListComponent from "../components/DepartmentsList/ProjectsListItem";
import {Panel} from "primereact/panel";
import {Accordion, AccordionTab} from "primereact/accordion";
import {Dialog} from "primereact/dialog";
import {getOptionsByUsers, User} from "../entities/user";
import Form from "react-bootstrap/Form";
import {AddProjectRequest, UserToAdd} from "../entities/project";
import Select, {MultiValue} from "react-select";
import makeAnimated from "react-select/animated";
import {convertYYYYMMDDToDate} from "../common/date";

import '../css/ProjectsPage.css'
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/bootstrap4-dark-purple/theme.css';

const ProjectsPage = () => {
    const {user} = useAuth()
    const [departments, setDepartments] = useState<Department[] | null>(null);
    const [ndf_visible, setNdf_visible] = useState(false);
    const [npf_visible, setNpf_visible] = useState(false);
    const [departmentName, setDepartmentName] = useState<string | null>("")
    const [users, setUsers] = useState<User[] | null>(null);
    const [selectedDepartmentID, setSelectedDepartmentID] = useState<number>(0)
    const [finishDate, setFinishDate] = useState('');
    const [projectName, setProjectName] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<MultiValue<{
        value: number;
        label: string;
    }> | null>(null);

    const ndfFooterContent = (
        <div>
            <button className="dialog-button" onClick={() => setNdf_visible(false)}>Отмена</button>
            <button className="dialog-button" onClick={() => {
                createDepartment().then(() => {
                    window.location.reload()
                    setNdf_visible(false);
                })
            }}>Создать отдел
            </button>
        </div>
    );

    const npfFooterContent = (
        <div>
            <button className="dialog-button" onClick={() => {
                setSelectedOptions(null);
                setNpf_visible(false);
            }}>Отмена</button>
            <button className="dialog-button" onClick={() => {
                if (selectedOptions && selectedOptions.length > 0) {
                    const selectedUsers: UserToAdd[] = [];
                    selectedOptions.forEach((option) => {
                        selectedUsers.push({ID: option.value});
                    });
                    createProject(selectedUsers).then();
                }
                window.location.reload();
                setNpf_visible(false);
            }}>Создать проект
            </button>
        </div>
    );

    useEffect(() => {
        const getAllDepartments = async () => {
            const resp = await instance.get("/api/departments/all")
            setDepartments(resp.data)
        }
        const getAllUsers = async () => {
            const resp = await instance.get("/api/users/all")
            setUsers(resp.data)
        }
        getAllDepartments().then()
        getAllUsers().then()
    }, []);

    let selOptions;
    if (users !== null) {
        selOptions = getOptionsByUsers(users);
    }

    const createDepartment = async () => {
        const newDepartment: AddDepartmentRequest = {
            name: departmentName,
        };

        try {
            await instance.post('/api/departments', newDepartment);
        } catch (error) {
            console.error('Error creating department:', error);
        }
    };

    const createProject = async (selectedUsers: UserToAdd[]) => {
        const newProject: AddProjectRequest = {
            name: projectName,
            department_id: selectedDepartmentID,
            director_id: 1,
            lead_designer_id: 1,
            finish_date: convertYYYYMMDDToDate(finishDate),
            progress: 0,
            number: "1.1",
            users: selectedUsers,
        };

        try {
            await instance.post('/api/projects', newProject);
        } catch (error) {
            console.error('Error creating project:', error);
        }
    };

    const panelHeaderTemplate = (options: any) => {
        const className = `${options.className} justify-content-space-between`;

        return (
            <div className={className} style={{paddingLeft: 10, paddingRight: 10, paddingTop: 3, paddingBottom: 3}}>
                <label style={{fontSize: "x-large"}}>Отделы и проекты</label>
                <button className="new-department-button" onClick={e => {
                    e.preventDefault();
                    setNdf_visible(true);
                }}>Новый отдел
                </button>
            </div>
        );
    };

    const accordionTabHeaderTemplate = (header: string, departmentID: number) => {
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: '100%'
            }}>
                <label style={{fontSize: "large"}}>{header}</label>
                <div>
                    <button className="new-project-button" onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        setSelectedDepartmentID(departmentID)
                        setNpf_visible(true)
                        // navigate(`/projects/new/${departmentID}`)
                    }}>Новый проект
                    </button>
                </div>
            </div>
        );
    };

    let departments_list;
    if (user === null) {
        return <div style={{color: "white"}}>Не авторизован</div>;
    }

    if (departments === null || departments.length === 0) {
        departments_list =
            <div style={{color: "white", textAlign: "center", marginBottom: "5px"}}>На данный момент отделов нет.</div>
    } else {
        departments_list =
            <Accordion multiple>
                {departments.map((department) => (
                    <AccordionTab key={department.ID}
                                  headerTemplate={accordionTabHeaderTemplate(department.name, department.ID)}>
                        <ProjectsListComponent ID={department.ID} name={department.name}
                                               projects={department.projects}/>
                    </AccordionTab>
                ))}
            </Accordion>
    }

    const selectStyles = {
        control: (base:any) => ({
            ...base,
            backgroundColor: '#212529',
        }),
        multiValue: (base:any) => ({
            ...base,
            backgroundColor: '#353c40',
        }),
        multiValueLabel: (base:any) => ({
            ...base,
            color: 'white',
        }),
        menuList: (base:any) => ({
            ...base,
            backgroundColor: '#212529',
        }),
        option: (base:any) => ({
            ...base,
            backgroundColor:'#212529',
            "&:hover": {
                backgroundColor: '#353c40',
            },
        })
    }

    return (
        <Panel headerTemplate={panelHeaderTemplate} style={{padding: '20px'}}>
            {departments_list}
            <Dialog header="Новый отдел" visible={ndf_visible} style={{width: '30vw'}} onHide={() => {
                if (!ndf_visible) return;
                setNdf_visible(false);
            }} footer={ndfFooterContent}>
                <p style={{color: "white", marginBottom: "5px"}}>Название отдела</p>
                <input
                    id="DepartmentName"
                    className="form-control bg-dark text-white"
                    placeholder="Введите название отдела"
                    onChange={e => setDepartmentName(e.target.value)}
                    required
                />
            </Dialog>
            <Dialog header="Новый проект" visible={npf_visible} style={{width: '30vw'}} onHide={() => {
                if (!npf_visible) return;
                setNpf_visible(false);
            }} footer={npfFooterContent}>
                <p style={{color: "white", marginBottom: 0}}>Название проекта</p>
                <input
                    id="DetailName"
                    className="form-control bg-dark text-white"
                    placeholder="Введите название проекта"
                    onChange={e => setProjectName(e.target.value)}
                    required
                />
                <p style={{color: "white", marginBottom: 0}}>Команда проекта</p>
                <Select
                    closeMenuOnSelect={false}
                    components={makeAnimated()}
                    styles={selectStyles}
                    placeholder="Выберите участников проекта"
                    noOptionsMessage={() => "Пользователей не найдено"}
                    defaultValue={selectedOptions}
                    onChange={setSelectedOptions}
                    isMulti
                    options={selOptions}
                />
                <p style={{color: "white", marginBottom: 0}}>Планируемая дата завершения</p>
                <Form.Control aria-label="Finish Date"
                              id="taskFinishDate"
                              className="form-control bg-dark text-white"
                              type="date"
                              value={finishDate}
                              onChange={(e) => setFinishDate(e.target.value)}
                              required
                />
                <p style={{color: "white", marginBottom: 0}}>Описание</p>
                <textarea placeholder="Введите описание проекта" name="NewProjectDescription" rows={6}
                          style={{resize: "none", width: "100%"}}
                          className="form-control bg-dark text-white"/>
            </Dialog>
        </Panel>
    );
};

export default ProjectsPage;