import {Task, TasksByProject} from './task';
import {notebook} from "./notebook";
import {Project} from "./project";
import { WorkPlace } from './workplaces';
import { eFile } from './file';
import { Username } from '../components/Username/Username';

export interface UserResponse {
    ID: number | null;
    username: string | null;
    role: number | null;
    name: string | null;
    surname: string | null;
    email: string | null;
    phone: string | null;
    image_url: string | null;
    telegram: string | null;
    tasks: Task[] | null;
    executor_tasks: Task[] | null;
    notebooks: notebook[] | null;
    projects: Project[] | null;
    workplaces: WorkPlace[] | null;
    current_work_place_id: number | null;
    avatar: eFile | null;
}

export interface UserByID {
    [ID: string]: User;
}

export interface UserOption {
    value: number;
    label: string;
}

export type User = UserResponse ;

export interface UserUpdateResponse {
    updated: boolean,
    error: string
}

export interface UserDeleteResponse {
    deleted: boolean,
    error: string
}

export const NewUser = () => {
    return {
        ID: null,
        username: null,
        role: null,
        name: null,
        surname: null,
        email: null,
        phone: null,
        image_url: null,
        telegram: null,
        tasks: null,
        notebooks: null,
        projects: null,
        workplaces: null,
        current_work_place_id: null,
        avatar: null,
        executor_tasks: null
    }
}

export function getUserByID(users: User[]): UserByID{
    const UserByID: UserByID = {};


    // Group tasks by project name
    users.forEach(user => {
        if (user.ID) {
            UserByID[user.ID] = user;
        }
    });

    return UserByID;
}

export function getOptionsByUsers(users: User[]): UserOption[] {
    const UserOptions: UserOption[] = [];

    users.forEach(user => {
        if (user.ID && user.username) {
            UserOptions.push({value: user.ID, label: Username(user)});
        }
    })

    return UserOptions;
}