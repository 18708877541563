import React from 'react';
import '../css/Notepd.css'

import NotebookComponent from "../components/NotebookComponent";

const NotebookPage = () => {

    return (
        <div>
            <NotebookComponent />
        </div>
    );
};

export default NotebookPage;