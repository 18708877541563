import React, {useEffect, useState} from 'react';
import TasksList from "../components/TasksList";
import '../css/Tasks.css'
import {useAuth} from "../context/AuthProvider";
import {instance} from "../api/config";
import {Task} from "../entities/task";
import {useNavigate, useParams} from "react-router-dom";
import TaskListItem from "../components/TaskListItem";
import {NewProject, Project} from "../entities/project";
import {TreeTable} from 'primereact/treetable';
import {Column} from 'primereact/column';

import UsersListItem from "../components/UsersList/UsersListItem/UsersListItem";
import {TreeNode} from "primereact/treenode";
import {Panel} from "primereact/panel";
import {Tag} from "primereact/tag";
import {Tree} from "primereact/tree";
import {Card} from "primereact/card";
import {Divider} from "primereact/divider";
import {ProgressBar} from "primereact/progressbar";
import {Ripple} from "primereact/ripple";

type Params = {
    id: string;
    departmentName: string;
};

const ProjectTasksPage = () => {

    const navigate = useNavigate();

    const {user} = useAuth()
    const {id, departmentName} = useParams<Params>()
    const [project, setProject] = useState<Project>(NewProject())
    const [tasks, setTasks] = useState<Task[] | null>(null);
    const [nodes, setNodes] = useState<TreeNode[]>([]);

    useEffect(() => {
        const getUserTasks = async () => {
            const resp = await instance.get("/api/projects", {
                params: {
                    ID: id
                }
            })
            setProject(resp.data)
        }
        // NodeService.getTreeTableNodes().then((data) => setNodes(data));
        getUserTasks()
    }, []);

    interface treeListNodesMap {
        key: number,
        taskName: string,
        taskType: string,
        finishDate: string,
        taskProgress: number,
        children?: treeListNodesMap[]
    }

    const transformObject = (nestedObjects: Task[]): treeListNodesMap[] => {
        let objects: treeListNodesMap[] = [];
        nestedObjects.forEach(object => {
            (object.child_tasks && object.child_tasks.length) ?
                objects.push({
                    key: object.ID,
                    taskName: object.name,
                    taskType: object.type,
                    finishDate: object.finish_date,
                    taskProgress: object.progress,
                    children: transformObject(object.child_tasks)
                }) :
                objects.push({
                    key: object.ID,
                    taskName: object.name,
                    taskType: object.type,
                    finishDate: object.finish_date,
                    taskProgress: object.progress
                });
        })
        return objects;
    };

    if (user === null) {
        return <div>Не авторизован</div>;
    }

    const handleNewTask = async () => {
        navigate("/tasks/new")
    }

const handleTaskClick = (id: number) => {
        navigate(`/tasks/${id}`)
    }

    const panelHeaderTemplate = (options: any) => {
        const className = `${options.className} justify-content-space-between`;

        return (
            <div className={className} style={{paddingLeft: 10, paddingRight: 10, paddingTop: 3, paddingBottom: 3}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    <div>
                        <label style={{fontSize: "large"}}>Отдел:</label>
                        <Tag style={{
                            fontSize: "smaller",
                            borderRadius: "10px",
                            marginLeft: "5px",
                            padding: "0 6px 0 6px"
                        }}
                             value={departmentName}/>
                    </div>
                    <div>
                        <label style={{fontSize: "large"}}>Проект:</label>
                        <Tag style={{
                            fontSize: "smaller",
                            borderRadius: "10px",
                            marginLeft: "5px",
                            padding: "0 6px 0 6px"
                        }}
                             value={project.name}/>
                    </div>
                    <div style={{display: "flex", alignItems: "center", flexDirection: "row"}}>
                        <label style={{fontSize: "large"}}>Команда проекта:</label>
                        {project.users?.map(user => (
                            <Tag key={user.ID} style={{
                                fontSize: "smaller",
                                borderRadius: "10px",
                                marginLeft: "5px",
                                padding: "0 6px 0 6px"
                            }}
                                 value={user.username}/>
                        ))}
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <button className="new-department-button" style={{marginBottom: "10px"}}>Завершить проект</button>
                    <button className="new-department-button" onClick={handleNewTask}>Новая задача</button>
                </div>
            </div>
        );
    };

    const nodeTemplate = (node: any, options: any) => {
        let nodeContent =
            <div className="p-ripple" onClick={e => {
                e.preventDefault();
                handleTaskClick(node.key)
            }}>
                <Card className="project">
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <div>
                            <Tag style={{fontSize: "medium", borderRadius: "3px"}}
                                 value={node.key}/>
                            <label style={{marginLeft: "10px", fontSize: "large"}}>{node.taskName}</label>
                        </div>

                        <div style={{display: "flex", alignItems: "center", flexDirection: "row"}}>
                            <label>Тип задачи: {node.taskType}</label>
                            <Divider layout="vertical"/>
                            <label>Планируемая дата завершения: {node.finishDate.slice(0, 10)}</label>
                            <Divider layout="vertical"/>
                            <ProgressBar value={node.taskProgress} style={{minWidth: "200px", marginRight: "5px"}}/>
                        </div>
                    </div>
                </Card>
                <Ripple
                    pt={{
                        root: {style: {background: 'rgba(255, 193, 6, 0.3)'}}
                    }}
                />
            </div>

        return <span className={options.className}>{nodeContent}</span>;
    }

    return (
        <Panel headerTemplate={panelHeaderTemplate} style={{padding: '20px'}}>
            <Tree value={transformObject(project.tasks || [])} nodeTemplate={nodeTemplate} style={{padding: 0}}/>
        </Panel>

        // <div>
        //     <div className="tab-content" id="myTabContent">
        //         <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
        //              style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}>
        //             <div className="tasks-body" style={{width: '50%'}}>
        //                 <div className="btn-group btn-group-sm">
        //                     <button className="day-button btn btn-secondary">День</button>
        //                     <button className="week-button btn btn-secondary">Неделя</button>
        //                     <button className="month-button btn btn-secondary">Месяц</button>
        //                 </div>
        //                 <div className="task-box" key={project.name}>
        //                     <label className="header">{project.name}</label>
        //                     {project.tasks?.map(task => (
        //                         <TaskListItem
        //                             ID={task.ID}
        //                             key={task.ID}
        //                             type={task.type}
        //                             name={task.name}
        //                             // detail={task.detail}
        //                             // quantity={task.quantity}
        //                             date={task.finish_date}
        //                             progress={task.progress}
        //                         />
        //                     ))}
        //                 </div>
        //             </div>
        //             <div className='m-2' style={{width: '50%'}}>
        //                 <div className="task-box" key='Users'>
        //                     <label className="header">Команда проекта</label>
        //                     {project.users?.map(user => (
        //                         <UsersListItem
        //                             name={user.name}
        //                             surname={user.surname}
        //                             username={user.username || ""}
        //                             role={user.role || 0}
        //                         />
        //                     ))}
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
};

export default ProjectTasksPage;