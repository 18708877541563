import React, {useEffect, useState} from 'react';
import '../css/Notepd.css'
import {NewNotebook, Notebook} from "../entities/notebook";
import {instance} from "../api/config";
import {useAuth} from "../context/AuthProvider";
import {getProjectByID} from "../entities/project";

const NotebookComponent: React.FC = () => {
    const { user } = useAuth()
    const [notebookList, setNotebookList] = useState<Notebook[]>([])
    const [notebook, setNotebook] = useState<Notebook>(NewNotebook())
    const [isChanged, setIsChanged] = useState<boolean>(false)
    const onNameChange = (name:string) => {
        const newNotebook = notebook
        newNotebook.title = name
        setNotebook(newNotebook)
        setIsChanged(!isChanged)
    }
    const onTextChange = (text:string) => {
        const newNotebook = notebook
        newNotebook.text = text
        setNotebook(newNotebook)
        setIsChanged(!isChanged)
    }

    const handleNotepadAdd = async (e:React.FormEvent) => {
        if (!notebook.text || !notebook.title) {
            return
        }
        const newNotebook = notebook
        newNotebook.user_id = user?.ID || 0
        const resp = await instance.post("/api/notebooks", newNotebook)
        const resp_all = await instance.get("/api/users/current")
        setNotebookList(resp_all.data.notebooks)
        setNotebook(NewNotebook())
    }

    useEffect(() => {

        const refreshNotebooks  = async () => {
           const resp = await instance.get("/api/users/current")
            setNotebookList(resp.data.notebooks)
        }
        refreshNotebooks()
    }, []);

    const handleNotepadDelete = (index: number) => {
        const resp = instance.delete("/api/notebooks", { data : {"ID": index}})
        setNotebookList(notebookList.filter((notebook) => notebook.ID != index))
    }

    const concatTitle = (title: string) => {
        return (title.length > 29)? title.slice(0, 29) + "..." : title
    }
    const concatText = (text: string) => {
        return (text.length > 200)? text.slice(0, 200) + "..." : text
    }
    return (
        <div>
            <div className="notepadGroup">
                <div style={{display: "flex", marginLeft: "0px"}}>
                    {notebookList.map((item, index) => (
                        <div className="note-box" key={index} style={{marginRight: "10px"}}>
                            <div style={{margin: "10px", wordBreak: "break-all"}}>
                                <h5 className="noteHeadline" style={{color: "white"}}>{concatTitle(item.title || "")} </h5>
                                <p className="noteHeadline" style={{color: "white"}}>{concatText(item.text || "")}</p>
                            </div>
                            <button className="mb-3 btn btn-lg delete-operation-button" type="button" key={index}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleNotepadDelete(item.ID || 0)
                                    }}>
                                <img src="/delete.png" alt="ChatLogo" width="25" height="25"/>
                            </button>
                        </div>
                    ))}
                </div>
                <div className="note-box">
                    <div className="m-2 form-floating" style={{marginTop: "20px"}}>
                        <input
                            type="headline"
                            className="form-control"
                            id="headlineInput"
                            value={notebook.title || ""}
                            onChange={(e) => onNameChange(e.target.value)}
                            required
                        />
                        <label htmlFor="headlineInput">Заголовок</label>

                    </div>
                    <div className="m-2 form-floating" style={{marginTop: "20px"}}>
                        <input
                            type="noteInline"
                            className="form-control"
                            id="noteInlineInput"
                            required
                            value={notebook.text || ""}
                            onChange={(e) => onTextChange(e.target.value)}
                        />
                        <label htmlFor="noteInlineInput">Введите запись</label>
                    </div>
                    <button className="w-80 m-2 btn btn-lg notepad-button" style={{marginTop: "20px"}}
                            onClick={handleNotepadAdd}>Добавить заметку
                    </button>
                </div>

            </div>
        </div>
    );
};

export default NotebookComponent;