import React, { useEffect, useState } from 'react';
import {ShortStorage, StorageTypes} from "../../../entities/storage";
import './StorageListItem.css'
import DetailsCountListItem from '../DetailsCountListItem/DetailsCountListItem';
import DetailsCountList from '../../Lists/DetailsCountList/DetailsCountList';
import { FieldGeneral, FieldMain } from '../../../components/Field/Fields';
import { CircularProgressBar } from '../../../components/ProgressBar/ProgressBar';
import { buildStyles } from 'react-circular-progressbar';
import { instance } from '../../../api/config';
import { FullTaskResponse } from '../../../entities/task';
import { MaterialType } from '../../../entities/material_type';
import { Tag } from '../../../components/Tag/Tag';
import { MaterialStatus } from '../../../entities/material';
interface StorageListItemProps {
    storage: ShortStorage
}
const StorageListItem = (props: StorageListItemProps) => {
    const detail = props.storage.details_with_count?.at(0)
    const material = props.storage.materials?.at(0)
    const [task, setTask] = useState<FullTaskResponse>()

    useEffect(() => {
        const getMFTask = async () => {
            if (detail?.manufacturing_task_id) {
            const resp = await instance.get("/api/tasks", {
                params: {"ID": detail?.manufacturing_task_id}
            })
            setTask(resp.data)
        }
        }
        getMFTask()
    }, [])
    const detailProgress = task?.task.progress

    const getCProgressValue = (status: MaterialStatus) : number => {
        if (status == MaterialStatus.Ordered) {
            return 0
        }
        if (status == MaterialStatus.Delivered) {
            return 50
        }
        return 100
    }
    return (
        <FieldMain className="storage-list-item" >
            <div className='storage-list-item-header'>
                <h1>{props.storage.name}</h1>
                <h3>Адрес: {props.storage.address}</h3>
            </div>
            { (detail?.ID) ?
            <FieldGeneral className='storage-list-item-detail' style={{ height:"100px", width: "360px"}}>
                <div className='storage-list-item-detail-progress-bar'>
                    <CircularProgressBar value={detailProgress} styles={buildStyles({ textColor: '#F65727', pathColor: "#F65727"})}></CircularProgressBar>
                </div>
                <div>
                    <h3>{detail?.detailType?.name}</h3>
                    <h4>Обозначение: {detail?.detailType?.number}</h4>
                    <h4>Количество: {detail?.count}</h4>
                </div>
            </FieldGeneral> : <></>
            }
            { (props.storage.storage_type == StorageTypes.Materials) ? 
            <FieldGeneral className='storage-list-item-detail' style={{ alignContent: "center", height:"100px", width: "360px"}}>
                <div className='storage-list-item-detail-progress-bar'>
                    <CircularProgressBar value={getCProgressValue(material?.status || MaterialStatus.Ordered)} styles={buildStyles({ textColor: '#F65727', pathColor: "#F65727"})}></CircularProgressBar>
                </div>
                <div>
                    <h3>{material?.assortment?.name}</h3>
                    <h4>ГОСТ: {material?.assortment?.gost}</h4>
                    <div className='detail-list-items-status'>
                        <h4 style={{ marginRight: "5px"}}>Статус:</h4>
                        <Tag style={{ marginBottom: "0px"}}>{material?.status}</Tag>
                    </div>
                </div>
            </FieldGeneral> : <></>
            }
        </FieldMain>
    )
}

export default StorageListItem;